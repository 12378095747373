import React from 'react';
import { Avatar } from "@mui/material";

// Utility function to generate color from name
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const GroupChip = ({ group, tasks, estimatedHours, onClick, isActive }) => {
  const formatHours = (hours, groupActions) => {
    // If any action has 0 estimated hours, show the '+' format
    const hasZeroEstimatedHours = groupActions?.some(action => 
      action.estimated_hours === 0 || !action.estimated_hours
    );
    
    if (hasZeroEstimatedHours) {
      return `~${hours.toFixed(2)}+`;
    }
    
    if (hours === 0) return "0.00";
    return hours.toFixed(2);
  };

  return (
    <div className={`group-chip ${isActive ? "active" : ""}`} onClick={onClick}>
      <Avatar
        style={{
          backgroundColor: `${getColor(group.name)}`,
          width: "30px",
          height: "30px",
          fontSize: "15px",
        }}
      >
        {group.name.charAt(0).toUpperCase()}
      </Avatar>
      <div>
        <p>
          {group.name} (#{tasks} {formatHours(estimatedHours, group.actions)}hrs)
        </p>
      </div>
    </div>
  );
};

export default GroupChip;
