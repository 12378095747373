import React, { useState, useEffect } from "react";
import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Select as MSelect,
  MenuItem,
} from "@mui/material";
import { Update, Delete } from "@mui/icons-material";
import Select, { components } from "react-select";
import axios from "axios";

const DeleteDialog = (props) => {
  const [replacementLocation, setReplacementLocation] = useState("");

  const handleLocationDelete = async (e) => {
    e.preventDefault();
    props.setError("");
    props.setSuccess("");

    try {
      const response = await axios.put(
        `https://maliarakis-server.rhodesislandpass.com/location-option/deleteLocationOption/${props.selectedLocation}`,
        {
          replacementId: replacementLocation,
        },
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      props.setLocations(
        props.locations.filter((location) => location._id !== props.selectedLocation)
      );

      props.setSuccess("Τοποθεσία διαγράφηκε επιτυχώς.");
      props.onClose();
    } catch (error) {
      console.log(error);
      props.setError("Αποτυχία διαγραφής Τοποθεσίας. Παρακαλώ δοκιμάστε ξανά.");
      props.onClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Διαγραγή Τοποθεσίας"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Διάλεξε την τοποθεσία που θέλεις να αντικαταστήσεις την τρέχουσα.
        </DialogContentText>

        <MSelect
          value={replacementLocation}
          onChange={(e) => setReplacementLocation(e.target.value)}
          fullWidth
          sx={{
            width: "100%",
          }}
        >
          {props.locations
            .filter((location) => location._id !== props.selectedLocation)
            .map((location) => (
              <MenuItem key={location._id} value={location._id}>
                {location.name}
              </MenuItem>
            ))}
        </MSelect>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>ΑΚΥΡΩΣΗ</Button>
        <Button
          onClick={handleLocationDelete}
          autoFocus
          disabled={replacementLocation === ""}
        >
          ΔΙΑΓΡΑΦΗ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateLocationOption = () => {
  const [locationName, setLocationName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          "https://maliarakis-server.rhodesislandpass.com/location-option/getLocationOptions",
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setLocations(response.data.locationOptions);
      } catch (error) {
        setError("Failed to fetch locations. Please try again.");
      }
    };
    fetchLocations();
  }, []);

  const handleLocationSelect = (selectedOption) => {
    setSelectedLocation(selectedOption.value);
    setLocationName(selectedOption.label);
  };

  const handleUpdateLocation = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (locationName === "") {
      return setError("Το όνομα της Τοποθεσίας είναι υποχρεωτικό.");
    }

    try {
      const response = await axios.put(
        `https://maliarakis-server.rhodesislandpass.com/location-option/updateLocationOption/${selectedLocation}`,
        {
          name: locationName,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setLocations(
        [...locations].map((location) => {
          if (location._id === selectedLocation) {
            return {
              ...location,
              name: locationName,
            };
          }
          return location;
        })
      );

      setSuccess("Επιτυχής ενημέρωση Τοποθεσίας.");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Τοποθεσία με αυτό το όνομα υπάρχει ήδη.");
      } else {
        setError("Αποτυχία ενημέρωσης Τοποθεσίας. Παρακαλώ δοκιμάστε ξανά.");
      }
    }
  };

  return (
    <div className="update-location-option-wrapper">
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <h4
        style={{
          marginBottom: "10px",
          fontWeight: "600",
        }}
      >
        Επεξεργασία Τοποθεσίας
      </h4>
      <Select
        value={selectedLocation ? {
          value: selectedLocation,
          label: locations.find(loc => loc._id === selectedLocation)?.name || ""
        } : null}
        onChange={(selectedOption) => handleLocationSelect(selectedOption)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "var(--secondary-color)",
            primary25: "var(--tertiary-color)",
          },
        })}
        options={locations.map(location => ({
          value: location._id,
          label: location.name
        }))}
        styles={{
          menu: (base) => ({
            ...base,
            zIndex: 1000,
          }),
        }}
        placeholder="Επιλέξτε τοποθεσία..."
      />

      {selectedLocation && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <form
            onSubmit={handleUpdateLocation}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <TextField
              variant="outlined"
              label="Όνομα Τοποθεσίας"
              placeholder="π.χ. Ρόδος"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                inputProps: { style: { height: "56px", padding: "5px" } },
              }}
            />
            <button
              className="add-ticket-button__button"
              type="submit"
              style={{
                backgroundColor: "var(--primary-color)",
                color: "var(--primary-text-color)",
                fontSize: "15px",
                fontWeight: "600",
                width: "auto",
                padding: "10px 20px",
              }}
            >
              <Update />
              <span>ΕΝΗΜΕΡΩΣΗ</span>
            </button>
          </form>
          <button
            className="add-ticket-button__button"
            onClick={() => setDeleteDialogOpen(true)}
            style={{
              backgroundColor: "#d32f2f",
              color: "var(--primary-text-color)",
              fontSize: "15px",
              fontWeight: "600",
              width: "auto",
              padding: "10px 20px",
            }}
          >
            <Delete />
            <span>ΔΙΑΓΡΑΦΗ</span>
          </button>
          <DeleteDialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            locations={locations}
            setLocations={setLocations}
            selectedLocation={selectedLocation}
            setError={setError}
            setSuccess={setSuccess}
            user={user}
          />
        </div>
      )}
    </div>
  );
};

export default UpdateLocationOption;