import React, { useState, useRef } from "react";
import { Alert } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import axios from "axios";
import apiUrl from "../api";
import { PopUpWindow } from "..";
import "./ImportPropertiesButton.css";

const ImportPropertiesButton = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [results, setResults] = useState(null);
  const fileInputRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );
  
  const user = JSON.parse(localStorage.getItem("user"));

  const handleImportClick = () => {
    setPopupOpen(true);
    setUploadError("");
    setUploadSuccess("");
    setResults(null);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check if file is CSV
    if (file.type !== "text/csv" && !file.name.endsWith('.csv')) {
      setUploadError("Please upload a CSV file");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setIsUploading(true);
    setUploadError("");
    setUploadSuccess("");

    try {
      const response = await axios.post(
        `${apiUrl}/property/import-csv`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setUploadSuccess("Properties imported successfully!");
      setResults({
        created: response.data.created || 0,
        updated: response.data.updated || 0,
        failed: response.data.failed || 0,
        total: response.data.total || 0
      });
    } catch (error) {
      console.error("Error importing properties:", error);
      setUploadError(
        error.response?.data?.message || "Failed to import properties"
      );
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleDownloadTemplate = () => {
    window.open(`${apiUrl}/property/download-csv-template`, '_blank');
  };

  return (
    <>
      <div className="add-ticket-button"
        style={{
          display: JSON.parse(localStorage.getItem("user")).permissions.canAddProperty? "flex" : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: "var(--primary-color)",
            color: "var(--primary-text-color)",
            fontSize: "15px",
            fontWeight: "600",
            width: buttonWidth,
          }}
          onClick={handleImportClick}
        >
          <CloudUpload />
          <span>Εισαγωγή Καταλυμάτων</span>
        </button>
      </div>

      {popupOpen && (
        <PopUpWindow
          onClose={() => {
            setPopupOpen(false);
            setUploadError("");  // Fixed: Changed from setError to setUploadError
            setUploadSuccess(""); // Fixed: Changed from setSuccess to setUploadSuccess
          }}
          title="Εισαγωγή Καταλυμάτων από CSV"
        >
          <div className="import-properties-container">
            <div className="import-instructions">
              <h4>Οδηγίες:</h4>
              <p>
                1. Ανεβάστε ένα αρχείο CSV με διαχωριστικό το ελληνικό ερωτηματικό (;) που περιέχει:
                <br />
                - Στήλη "Property": Το όνομα του καταλύματος
                <br />
                - Στήλη "ID": Το Loggia ID του καταλύματος (προαιρετικό)
              </p>
              <p>
                2. Αν το Loggia ID υπάρχει ήδη, το κατάλυμα θα ενημερωθεί.
                <br />
                3. Αν δεν υπάρχει, θα δημιουργηθεί νέο κατάλυμα.
                <br />
                4. <a href="#" onClick={handleDownloadTemplate}>Κατεβάστε πρότυπο CSV</a>
              </p>
            </div>

            <div className="file-upload-area">
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                ref={fileInputRef}
                className="file-input"
                id="csv-upload"
              />
              <label htmlFor="csv-upload" className="file-upload-label">
                <CloudUpload />
                <span>{isUploading ? "Γίνεται μεταφόρτωση..." : "Επιλέξτε αρχείο CSV"}</span>
              </label>
            </div>

            {uploadError && <Alert severity="error">{uploadError}</Alert>}
            {uploadSuccess && <Alert severity="success">{uploadSuccess}</Alert>}

            {results && (
              <div className="import-results">
                <h4>Αποτελέσματα Εισαγωγής:</h4>
                <ul>
                  <li>Συνολικά αρχεία: <b>{results.total}</b></li>
                  <li>Νέα καταλύματα: <b>{results.created}</b></li>
                  <li>Ενημερωμένα καταλύματα: <b>{results.updated}</b></li>
                  <li>Αποτυχημένες εγγραφές: <b>{results.failed}</b></li>
                </ul>
              </div>
            )}
          </div>
        </PopUpWindow>
      )}
    </>
  );
};

export default ImportPropertiesButton;
