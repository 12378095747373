import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  IconButton,
  TextField,
  TableSortLabel,
  Button,
  Pagination,
  Typography,
  InputAdornment,
} from "@mui/material";
import { Edit, Delete, Search, Clear } from "@mui/icons-material";
import { EditPropertyForm, DeleteProperty, PopUpWindow } from "../";
import io from "socket.io-client";

const socket = io("https://maliarakis-server.rhodesislandpass.com/");

const formatDateWithTime = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const PropertiesTable = () => {
  const [properties, setProperties] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProperties, setTotalProperties] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sortField, setSortField] = useState("order");
  const [sortOrder, setSortOrder] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const [editPopUpOpen, setEditPopUpOpen] = useState(false);
  const [editProperty, setEditProperty] = useState(null);
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [deleteProperty, setDeleteProperty] = useState(null);
  const [actionOptions, setActionOptions] = useState([]);

  useEffect(() => {
    fetchActionOptions();
  }, []);

  useEffect(() => {
    fetchProperties(search, currentPage, sortField, sortOrder);
  }, [currentPage, sortField, sortOrder]);

  useEffect(() => {

    const handlePropertyUpdated = ( property) => {
      console.log("propertyUpdated", property);
     setProperties((prevProperties) =>
        prevProperties.map((prevProperty) =>
          prevProperty._id === property._id ? property : prevProperty
        )
      );
    }

    

    const handlePropertyDeleted = (propertyId) => {
      setProperties((prevProperties) =>
        prevProperties.filter((property) => property._id !== propertyId)
      );
    };


    socket.on("propertyUpdated", handlePropertyUpdated);
    socket.on("propertyDeleted", handlePropertyDeleted);

    return () => {
      socket.off("propertyUpdated");
      socket.off("propertyDeleted");
    };
  }, []);

  const fetchProperties = async (search, page, sortField, sortOrder) => {
    try {
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/property/getPropertiesWithPagination?search=${search}&page=${page}&sortField=${sortField}&sortOrder=${
          sortOrder === 1 ? "asc" : "desc"
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.properties);
      setTotalProperties(response.data.totalItems);
      setPageCount(Math.ceil(response.data.totalItems / itemsPerPage));
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  const fetchActionOptions = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/action-option/getActionOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setActionOptions(response.data.actionOptions);
    } catch (error) {
      console.error("Failed to fetch action options: ", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1); // Reset to first page when search is submitted
    fetchProperties(search, 1, sortField, sortOrder);
  };

  const handleClearSearch = () => {
    setSearch("");
    setCurrentPage(1); // Reset to first page when search is cleared
    fetchProperties("", 1, sortField, sortOrder);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder * -1);
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  return (
    <>
      {editPopUpOpen && (
        <PopUpWindow
          onClose={() => {
            setEditPopUpOpen(false);
            setEditProperty(null);
          }}
        >
          <EditPropertyForm property={editProperty} actionOptions={actionOptions}/>
        </PopUpWindow>
      )}

      {deletePopUpOpen && (
        <PopUpWindow
          onClose={() => {
            setDeletePopUpOpen(false);
            setDeleteProperty(null);
          }}
        >
          <DeleteProperty property={deleteProperty} />
        </PopUpWindow>
      )}

      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <form
            onSubmit={handleSearchSubmit}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "95%",
              padding: "10px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Αναζήτηση Καταλύματος"
              value={search}
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: search && (
                  <InputAdornment position="end">
                    <Tooltip title="Clear search">
                      <IconButton
                        onClick={handleClearSearch}
                        aria-label="clear search"
                      >
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                width: "95%",
                padding: "10px",
              }}
            />
            <Button
              type="submit"
              variant="contained"
              style={{
                marginLeft: "10px",
                padding: "10px",
                width: "20%",
                backgroundColor: "var(--primary-color)",
                color: "var(--primary-text-color)",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              ΑΝΑΖΗΤΗΣΗ
            </Button>
          </form>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {(user.permissions.canEditProperty ||
                  user.permissions.canDeleteProperty) && (
                  <TableCell></TableCell>
                )}
                <TableCell>
                  <TableSortLabel
                    active={sortField === "name"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("name")}
                  >
                    Όνομα
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "order"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("order")}
                  >
                    Order
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "persons"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("persons")}
                  >
                    Άτομα
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "checkin"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("checkin")}
                  >
                    Ώρα Check In
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "checkout"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("checkout")}
                  >
                    Ώρα Check Out
                  </TableSortLabel>
                </TableCell>
                {actionOptions.map((actionOption) => (
                  <React.Fragment key={actionOption._id}>
                    <TableCell>
                      Τιμή "{actionOption.name}"
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortField === `${actionOption._id}_hours`}
                        direction={sortOrder === 1 ? "asc" : "desc"}
                        onClick={() => handleSort(`${actionOption._id}_hours`)}
                      >
                        Ώρες "{actionOption.name}"
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortField === `${actionOption._id}_visits`}
                        direction={sortOrder === 1 ? "asc" : "desc"}
                        onClick={() => handleSort(`${actionOption._id}_visits`)}
                      >
                        Επισκέψεις "{actionOption.name}"
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortField === `${actionOption._id}_avgTime`}
                        direction={sortOrder === 1 ? "asc" : "desc"}
                        onClick={() => handleSort(`${actionOption._id}_avgTime`)}
                      >
                        Μέσος Χρόνος "{actionOption.name}"
                      </TableSortLabel>
                    </TableCell>
                  </React.Fragment>
                ))}

                <TableCell>
                  <TableSortLabel
                    active={sortField === "hours"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("hours")}
                  >
                    Συνολικές Ώρες
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "visits"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("visits")}
                  >
                    Επισκέψεις
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "performance"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("performance")}
                  >
                    Δείκτης Απόδοσης
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortField === "euro_no_vat"}
                    direction={sortOrder === 1 ? "asc" : "desc"}
                    onClick={() => handleSort("euro_no_vat")}
                  >
                    € Χωρίς ΦΠΑ
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.map((property) => (
                <TableRow key={property.id}>
                  {(user.permissions.canEditProperty ||
                    user.permissions.canDeleteProperty) && (
                    <TableCell>
                      {user.permissions.canEditProperty && (
                        <Tooltip title="Edit" color="secondary">
                          <IconButton
                            onClick={() => {
                              setEditProperty(property);
                              setEditPopUpOpen(true);
                            }}
                          >
                            <Edit sx={{ color: "var(--primary-color)" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user.permissions.canDeleteProperty && (
                        <Tooltip title="Delete" color="primary">
                          <IconButton
                            onClick={() => {
                              setDeleteProperty(property);
                              setDeletePopUpOpen(true);
                            }}
                          >
                            <Delete sx={{ color: "var(--primary-color)" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                  <TableCell>{property.name}</TableCell>
                  <TableCell>{property.order}</TableCell>
                  <TableCell>{property.persons}</TableCell>
                  <TableCell>
                    {property.checkin ? property.checkin : "-"}
                  </TableCell>
                  <TableCell>
                    {property.checkout ? property.checkout : "-"}
                  </TableCell>

                  {actionOptions.map((actionOption) => (
                    <React.Fragment key={actionOption._id}>
                      <TableCell>
                        {property.actions_prices.find(
                          (actionPrice) => actionPrice._id === actionOption._id
                        )?.price || "-"}€
                      </TableCell>
                      <TableCell>
                        {property.actions_analytics.find(
                          (analytics) => analytics._id === actionOption._id
                        )?.hours || "-"}
                      </TableCell>
                      <TableCell>
                        {property.actions_analytics.find(
                          (analytics) => analytics._id === actionOption._id
                        )?.visits || "-"}
                      </TableCell>
                      <TableCell>
                        {property.actions_analytics.find(
                          (analytics) => analytics._id === actionOption._id
                        )?.avgTime || "-"}
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell>{property.hours}</TableCell>
                  <TableCell>{property.visits}</TableCell>
                  <TableCell>{property.performance}</TableCell>
                  <TableCell>{property.euro_no_vat}€</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          m={2}
          sx={{ width: "95%" }}
        >
          <Typography>
            Showing {(currentPage - 1) * itemsPerPage}-
            {Math.min(currentPage * itemsPerPage, totalProperties)} of{" "}
            {totalProperties} items
          </Typography>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={(event, page) => {
              setCurrentPage(page);
            }}
            color="primary"
          />
        </Box>
      </Box>
    </>
  );
};

export default PropertiesTable;
