import React, { useState, useEffect } from "react";

import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Select as MSelect,
  MenuItem,
} from "@mui/material";
import { Update, Delete, Edit } from "@mui/icons-material";
import Select, { components } from "react-select";

import axios from "axios";
import AddChecklistTemplateForm from "../addChecklistTemplateForm/AddChecklistTemplateForm";

const CustomOption = (props) => {
  return (
    <Tooltip
      title="color"
      placement="left"
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
            backgroundColor: `${props.data.tooltip}`,
            color: ` ${props.data.tooltip}`,
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};

const DeleteDialog = (props) => {
  const handleCategoryDelete = async (e) => {
    e.preventDefault();
    props.setError("");
    props.setSuccess("");

    // Add your API call here to delete the category
    // console.log(`Bearer ${props.user.token.toString()}`);
    try {
      const response = await axios.delete(
        `https://maliarakis-server.rhodesislandpass.com/checklist-template/deleteChecklistTemplate/${props.selectedCategory._id}`,
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      props.setSuccess("Διαγραφή Επιτυχής.");
      props.setCategories(
        props.categories.filter(
          (category) => category._id !== props.selectedCategory._id
        )
      );
      props.onClose();
    } catch (error) {
      console.log(error);
      props.setError("Failed to delete category. Please try again.");
      props.onClose();
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Διαγραφή Λίστας Ελέγχου"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Είστε σίγουροι ότι θέλετε να διαγράψετε την λίστα ελέγχου{" "}
          {props.selectedCategory.title};
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>ΑΚΥΡΩΣΗ</Button>
        <Button onClick={handleCategoryDelete} autoFocus>
          ΔΙΑΓΡΑΦΗ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateChecklistTemplate = () => {
  const [categoryName, setCategoryName] = useState("");
  const [categoryColor, setCategoryColor] = useState("#FA0808");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [checklists, setChecklists] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchChecklists = async () => {
      try {
        const response = await axios.get(
          "https://maliarakis-server.rhodesislandpass.com/checklist-template/getChecklistTemplates",
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setChecklists(response.data.checklistTemplates);
      } catch (error) {
        setError("Failed to fetch categories. Please try again.");
      }
    };
    fetchChecklists();
  }, []);

  const handleCategorySelect = (selectedOption) => {
    setSelectedCategory(selectedOption.value);
    setCategoryName(selectedOption.label);
    setCategoryColor(selectedOption.tooltip);
  };

  return (
    <div className="update-category-option-wrapper">
      <h4
        style={{
          marginBottom: "10px",
          fontWeight: "600",
        }}
      >
        Επεξεργασία Λίστας Ελέγχου
      </h4>
      <Select
        value={selectedCategory.value}
        onChange={(selectedOption) => handleCategorySelect(selectedOption)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "var(--secondary-color)",
            primary25: "var(--tertiary-color)",
          },
        })}
        options={[
          ...checklists.map((checklist) => ({
            value: checklist,
            label: checklist.title,
          })),
        ]}
        components={{ Option: CustomOption }}
        styles={{
          menu: (base) => ({
            ...base,
            zIndex: 1000, // Adjust this value as needed, ensuring it is higher than the z-index of other components
          }),
        }}
      />

      {selectedCategory && (
        <div
          style={{
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <AddChecklistTemplateForm
            key={selectedCategory._id}
            edit={true}
            selectedChecklist={selectedCategory}
          />
          <button
            className="add-ticket-button__button"
            style={{
              backgroundColor: "var(--primary-color)",
              color: "var(--primary-text-color)",
              fontSize: "15px",
              fontWeight: "600",
              width: "90%",
            }}
            onClick={() => setDeleteDialogOpen(true)}
          >
            <Delete />
            <span>Διαγραφή</span>
          </button>
          <DeleteDialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            categories={checklists}
            setCategories={setChecklists}
            selectedCategory={selectedCategory}
            setError={setError}
            setSuccess={setSuccess}
            user={user}
          />
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </div>
      )}
    </div>
  );
};

export default UpdateChecklistTemplate;
