import React, { useState, useEffect } from "react";

import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Select as MSelect,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Update, Delete } from "@mui/icons-material";
import Select, { components } from "react-select";

import axios from "axios";

const CustomOption = (props) => {
  return (
    <Tooltip
      title="color"
      placement="left"
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
            backgroundColor: `${props.data.tooltip}`,
            color: ` ${props.data.tooltip}`,
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
const AddChecklistTemplateForm = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [checklistName, setChecklistName] = useState(
    props.selectedChecklist ? props.selectedChecklist.title : ""
  );

  const view = props.view;
  const edit = props.edit;
  const [checklistItems, setChecklistItems] = useState(
    props.selectedChecklist && props.selectedChecklist.categories
      ? props.selectedChecklist.categories.map((category) => ({
          category: category.category,

          items: view ? category.items : [...category.items, ""],
        }))
      : [{ category: "", items: [""] }]
  );
  const [notes, setNotes] = useState(
    props.selectedChecklist ? props.selectedChecklist.notes : ""
  );
  const [activeChecklist, setActiveChecklist] = useState(
    props.selectedChecklist ? props.selectedChecklist.active : false
  );
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleCategoryChange = (value, index) => {
    const newChecklistItems = [...checklistItems];
    newChecklistItems[index].category = value;

    // Remove the category if the name is empty, it has no items, and it's not the last one
    if (
      value === "" &&
      newChecklistItems[index].items.length === 1 &&
      newChecklistItems[index].items[0] === "" &&
      newChecklistItems.length > 1
    ) {
      newChecklistItems.splice(index, 1);
    } else if (index === checklistItems.length - 1 && value) {
      // Add a new empty category if editing the last one and it's not empty
      newChecklistItems.push({ category: "", items: [""] });
    }

    setChecklistItems(newChecklistItems);
  };

  const handleItemChange = (value, categoryIndex, itemIndex) => {
    const newChecklistItems = [...checklistItems];
    newChecklistItems[categoryIndex].items[itemIndex] = value;

    // Remove the item if the name is empty and it's not the last one
    if (value === "" && newChecklistItems[categoryIndex].items.length > 1) {
      newChecklistItems[categoryIndex].items.splice(itemIndex, 1);
    } else if (
      itemIndex === newChecklistItems[categoryIndex].items.length - 1 &&
      value
    ) {
      // Add a new empty item if editing the last one and it's not empty
      newChecklistItems[categoryIndex].items.push("");
    }

    // Check if all items and the category name are empty, then remove the category if not the only one
    if (
      newChecklistItems[categoryIndex].items.every((item) => item === "") &&
      newChecklistItems[categoryIndex].category === "" &&
      newChecklistItems.length > 1
    ) {
      newChecklistItems.splice(categoryIndex, 1);
    }

    setChecklistItems(newChecklistItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Submit logic here
    setSuccessMessage("");
    setErrorMessage("");

    if (!checklistName) {
      console.log("No checklist name");
      setErrorMessage("Το όνομα της λίστας ελέγχου είναι υποχρεωτικό");
      return;
    }

    if (
      checklistItems.length === 1 &&
      checklistItems[0].category === "" &&
      checklistItems[0].items.length === 1 &&
      checklistItems[0].items[0] === ""
    ) {
      console.log("No checklist items");
      setErrorMessage(
        "Η λίστα ελέγχου πρέπει να περιέχει τουλάχιστον ένα στοιχείο"
      );
      return;
    }

    // Validation to ensure every category except the last is properly filled
    for (let i = 0; i < checklistItems.length - 1; i++) {
      const category = checklistItems[i];
      if (
        (category.category &&
          category.items.slice(0, -1).every((item) => item === "")) ||
        (!category.category &&
          category.items.slice(0, -1).some((item) => item !== ""))
      ) {
        setErrorMessage(
          "Κάθε κατηγορία πρέπει να έχει τίτλο και τουλάχιστον ένα στοιχείο"
        );
        return;
      }
    }

    if (
      checklistItems[checklistItems.length - 1].category.trim() === "" &&
      checklistItems[checklistItems.length - 1].items.some(
        (item) => item !== ""
      )
    ) {
      setErrorMessage("Kάθε κατηγορία πρέπει να έχει τίτλο");
      return;
    }

    const data = {
      checklistName,
      checklistItems: checklistItems
        .filter((category) => category.category)
        .map((category) => ({
          category: category.category,
          items: category.items.filter((item) => item),
        })),

      notes,
    };

    try {
      let response;
      if (edit) {
        response = await axios.put(
          `https://maliarakis-server.rhodesislandpass.com/checklist-template/updateChecklistTemplate/${props.selectedChecklist._id}`,
          {
            title: data.checklistName,
            active: activeChecklist,
            categories: data.checklistItems,
            notes: data.notes,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
      } else {
        response = await axios.post(
          "https://maliarakis-server.rhodesislandpass.com/checklist-template/createChecklistTemplate",
          {
            title: data.checklistName,
            active: activeChecklist,
            categories: data.checklistItems,
            notes: data.notes,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
      }

      setSuccessMessage(
        `Η λίστα ελέγχου ${
          edit ? "ενημερώθηκε" : "δημιουργήθηκε"
        } επιτυχώς`
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        setErrorMessage("Δεν μπορείτε να απενεργοποιήσετε την τρέχουσα λίστα ελέγχου πρωτού ενεργοποιήσετε μια άλλη");
      } else {
        setErrorMessage(
          "Υπήρξε ένα πρόβλημα κατά τη δημιουργία της λίστας ελέγχου"
        );
      }
    }
  };

  return (
    <div className="ticket-form-wrapper">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form className="ticket-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="checklist_name">
                Όνομα Λίστας Ελέγχου
              </label>
              <input
                type="text"
                id="checklist_name"
                className="form-field-input"
                value={checklistName}
                onChange={(e) => setChecklistName(e.target.value)}
              />
            </div>
          </div>
          <hr style={{ width: "100%", margin: "20px 0" }} />
          {checklistItems.map((category, index) => (
            <div key={index} style={{ width: "100%" }}>
              <div className="form-group">
                <div className="form-field">
                  <label
                    className="form-field-label"
                    htmlFor={`category_name_${index}`}
                  >
                    Όνομα Κατηγορίας
                  </label>
                  <input
                    type="text"
                    id={`category_name_${index}`}
                    className="form-field-input"
                    value={category.category}
                    onChange={(e) =>
                      handleCategoryChange(e.target.value, index)
                    }
                  />
                  {category.items.map((item, i) => (
                    <div key={`${index}_${i}`}>
                      <div className="form-group">
                        <div className="form-field">
                          <label
                            className="form-field-label"
                            htmlFor={`item_name_${index}_${i}`}
                          >
                            Ονομασία Πεδίου
                          </label>
                          <input
                            type="text"
                            id={`item_name_${index}_${i}`}
                            className="form-field-input"
                            value={item}
                            onChange={(e) =>
                              handleItemChange(e.target.value, index, i)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr style={{ width: "100%", margin: "20px 0" }} />
            </div>
          ))}

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="notes">
                Σημειώσεις
              </label>
              <textarea
                id="notes"
                className="form-field-input"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="active_checklist">
                Ορισμός Ως Ενεργή Λίστα Ελέγχου
              </label>
              <input
                type="checkbox"
                id="active_checklist"
                className="form-field-input"
                checked={activeChecklist}
                style={{
                  width: "20px",
                  height: "20px",
                  margin: "0",
                  marginLeft: "10px",
                }}
                onChange={(e) => setActiveChecklist(e.target.checked)}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <button
                className="add-ticket-button__button"
                style={{
                  backgroundColor: props.color || "var(--primary-color)",
                  color: props.textColor || "var(--primary-text-color)",
                  fontSize: props.fontSize || "15px",
                  fontWeight: props.fontWeight || "600",
                  width: props.width || "100%",
                }}
                type="submit"
              >
                {edit
                  ? "Ενημέρωση Λίστας Ελέγχου"
                  : "Δημιουργία Λίστας Ελέγχου"}
              </button>
            </div>
          </div>

          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </form>
      )}
    </div>
  );
};

export default AddChecklistTemplateForm;
