import React, { useState } from "react";
import { TextField, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

const AddLocationOption = () => {
  const [locationName, setLocationName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleAddLocation = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (locationName === "") {
      return setError("Το όνομα της τοποθεσίας είναι υποχρεωτικό.");
    }

    try {
      const response = await axios.post(
        "https://maliarakis-server.rhodesislandpass.com/location-option/createLocationOption",
        {
          name: locationName,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Επιτυχής Δημιουργία Τοποθεσίας.");
      setLocationName("");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Τοποθεσία με αυτό το όνομα υπάρχει ήδη.");
      } else {
        setError("Κάτι πήγε στραβά. Παρακαλώ δοκιμάστε ξανά.");
      }
    }
  };

  return (
    <div className="add-location-option-wrapper">
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <h4
        style={{
          marginBottom: "0px",
          fontWeight: "600",
        }}
      >
        Δημιουργία Τοποθεσίας
      </h4>
      <form
        onSubmit={handleAddLocation}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <TextField
          variant="outlined"
          label="Όνομα Τοποθεσίας"
          placeholder="π.χ. Ρόδος"
          value={locationName}
          onChange={(e) => setLocationName(e.target.value)}
          fullWidth
          margin="normal"
          InputProps={{
            inputProps: { style: { height: "56px", padding: "5px" } },
          }}
        />
        <button
          className="add-ticket-button__button"
          type="submit"
          style={{
            backgroundColor: "var(--primary-color)",
            color: "var(--primary-text-color)",
            fontSize: "15px",
            fontWeight: "600",
            width: "auto",
            padding: "10px 20px",
          }}
        >
          <AddIcon />
          <span>ΔΗΜΙΟΥΡΓΙΑ</span>
        </button>
      </form>
    </div>
  );
};

export default AddLocationOption;
