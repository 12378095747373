import React, {useState, useEffect,useRef} from "react";
import {useUser} from "../../contexts/UserContext";

import "./Properties.css";
import { 
    AddPropertyButton,
    PropertiesTable,
    PrimaryButton,
    PopUpWindow,
    PropertyOrder,
    ImportPropertiesButton
} from "../../components";
import{PageStructure} from "../../pages";
import { Add, Task, Toc } from "@mui/icons-material";

const Properties = () => {
    const [priorityPopupOpen, setPriorityPopupOpen] = useState(false);

    return (
        <PageStructure>
            <h2>Properties</h2>
            
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <AddPropertyButton />
                <ImportPropertiesButton />
                <PrimaryButton 
                    text="Επεξεργασία Προτεραιότητας"
                    icon={<Toc />}
                    color="var(--primary-color)"
                    onClick={() => setPriorityPopupOpen(true)}
                />
            </div>

            {priorityPopupOpen && (
                <PopUpWindow
                    onClose={() => setPriorityPopupOpen(false)}
                    title="Επεξεργασία Προτεραιότητας"
                >
                    <PropertyOrder />
                </PopUpWindow>
            )}

            <PropertiesTable />
        </PageStructure>
    );
}

export default Properties;