import React, { useState } from "react";

import "./ViewActionsForm.css";

import axios from "axios";

import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Paper,
  Typography,
  Box,
  Container,
  makeStyles,
  Snackbar,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";

import { Edit, Delete, ArrowBack } from "@mui/icons-material";
import AddActionForm from "../addActionForm/AddActionForm";

const ViewActionsForm = (props) => {
  const users = props.users;
  const groups = props.groups;
  const actions = props.selectedActions.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
  const property = props.property;
  const user = JSON.parse(localStorage.getItem("user"));
  
  // Get reservations from props
  const actionReservations = props.actionReservations || {};
  const propertyReservations = props.propertyReservations || [];

  const date = props.date;

  const [selectedAction, setSelectedAction] = useState("");
  const [editAction, setEditAction] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  
  // Remove console.log statements
  
  return (
    <div className="ticket-form-wrapper">
      {!editAction && !deleteAction && (
        <div className="actions-wrapper">
          {actions.map((action) => (
            <div className="action" key={action._id}>
              <div className="action-title">
                <h3>{action.name || "Unknown Action"}</h3>
              </div>

              <Tooltip
                title={
                  <h4>
                    {action.assigned_to.map((user) => {
                      return (
                        <div key={user}>
                          {users.map((u) => {
                            if (u._id === user) {
                              return u.name;
                            }
                            return null; // Add a default return statement
                          })}
                        </div>
                      );
                    })}
                  </h4>
                }
                placement="top"
                PopperProps={{
                  style: { zIndex: 10001 },
                }}
              >
                <div className="action-group">
                  <h3>
                    {groups.map((group) => {
                      if (group._id === action.group) {
                        return group.name;
                      }
                      return null; // Add a default return statement
                    })}
                  </h3>
                </div>
              </Tooltip>

              <div className="action-time">
                <h3>
                  {" "}
                  {new Date(action.planned_start_date).toLocaleTimeString(
                    "el-GR"
                  )}
                </h3>
              </div>
              
              {/* Display connected reservations if they exist */}
              {actionReservations[action._id] && actionReservations[action._id].length > 0 && (
                <div className="action-reservations">
                  <h4>Connected Reservations:</h4>
                  <ul className="reservation-list">
                    {actionReservations[action._id].map(res => (
                      <li key={res._id}>
                        {res.reservationId} - {res.firstName} {res.lastName}
                        <span className="reservation-dates">
                          ({new Date(res.checkIn).toLocaleDateString()} to {new Date(res.checkOut).toLocaleDateString()})
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="action-icons">
                <Edit
                  onClick={() => {
                    setSelectedAction(action);
                    setEditAction(true);
                  }}
                />
                <Delete
                  onClick={() => {
                    setSelectedAction(action);
                    setDeleteAction(true);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {editAction && (
        <div className="edit-action">
          <AddActionForm
            edit={true}
            view={false}
            actionId={selectedAction._id}
            action_option={{
              label: selectedAction.name,
              value: {
                _id: selectedAction.action_option,
                name: selectedAction.name,
              },
            }}
            property={property}
            group={
              groups
                .filter((group) => {
                  return group._id === selectedAction.group;
                })
                .map((group) => {
                  return {
                    label: group.name,
                    value: group,
                  };
                })[0]
            }
            assigned_to={selectedAction.assigned_to.map((user) => {
              return {
                label: users
                  .filter((u) => {
                    return u._id === user;
                  })
                  .map((u) => {
                    return u.username;
                  }),
                value: users.filter((u) => {
                  return u._id === user;
                })[0],
              };
            })}
            date={new Date(selectedAction.planned_start_date)}
            price={selectedAction.price}
            notes={selectedAction.notes}
            estimated_hours={selectedAction.estimated_hours}
            // Pass the action's currently selected reservations
            initialReservations={actionReservations[selectedAction._id] ? 
              actionReservations[selectedAction._id].map(res => ({
                value: res._id,
                label: `${res.reservationId || 'N/A'} - ${res.firstName || 'Guest'} ${res.lastName || ''} (${new Date(res.checkIn || res.startDate).toLocaleDateString()} to ${new Date(res.checkOut || res.endDate).toLocaleDateString()})`,
                reservation: res
              })) : []
            }
            // Pass ALL property reservations as available options
            reservationsOptions={propertyReservations}
          />

          <div
            className="add-ticket-button"
            style={{
              display: JSON.parse(localStorage.getItem("user")).permissions
                .canCreateTicket
                ? "flex"
                : "none",
            }}
          >
            <button
              className="add-ticket-button__button"
              style={{
                backgroundColor: props.color || "var(--primary-color)",
                color: props.textColor || "var(--primary-text-color)",
                fontSize: props.fontSize || "15px",
                fontWeight: props.fontWeight || "600",
                width: props.width || "100%",
              }}
              onClick={() => {
                setEditAction(false);
              }}
            >
              <ArrowBack />
              <span>Πίσω</span>
            </button>
          </div>
        </div>
      )}

      {deleteAction && (
        <div className="delete-action">
          <h3>Είστε σίγουροι ότι θέλετε να διαγράψετε την δραστηριότητα;</h3>
          <div className="delete-action-buttons">
            <div
              className="add-ticket-button"
              style={{
                display: JSON.parse(localStorage.getItem("user")).permissions
                  .canCreateTicket
                  ? "flex"
                  : "none",
              }}
            >
              <button
                className="add-ticket-button__button"
                style={{
                  backgroundColor: props.color || "#D40101",
                  color: props.textColor || "var(--primary-text-color)",
                  fontSize: props.fontSize || "15px",
                  fontWeight: props.fontWeight || "600",
                  width: props.width || "100%",
                }}
                onClick={() => {
                  axios
                    .delete(
                      `https://maliarakis-server.rhodesislandpass.com/action/deleteAction/${selectedAction._id}`,
                      {
                        headers: {
                          Authorization: `Bearer ${user.token}`,
                        },
                      }
                    )
                    .then((res) => {
                      // After successful deletion, close the delete dialog and reset selectedAction
                      setSelectedAction("");
                      setDeleteAction(false);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Delete />
                <span>Διαγραφή</span>
              </button>
            </div>

            <div
              className="add-ticket-button"
              style={{
                display: JSON.parse(localStorage.getItem("user")).permissions
                  .canCreateTicket
                  ? "flex"
                  : "none",
              }}
            >
              <button
                className="add-ticket-button__button"
                style={{
                  backgroundColor: props.color || "var(--primary-color)",
                  color: props.textColor || "var(--primary-text-color)",
                  fontSize: props.fontSize || "15px",
                  fontWeight: props.fontWeight || "600",
                  width: props.width || "100%",
                }}
                onClick={() => {
                  setEditAction(false);
                  setDeleteAction(false);
                }}
              >
                <ArrowBack />
                <span>Πίσω</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewActionsForm;
