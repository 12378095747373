import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select"; // Import React Select
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Button,
  Box,
  Pagination,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import {
  RemoveRedEye,
  Edit,
  Search,
  Clear,
  Delete,
  Add,
} from "@mui/icons-material/";

import { Loader, PopUpWindow, AddReservationForm } from "../../components";
import io from "socket.io-client";
import apiUrl from "../api";

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const socket = io("https://maliarakis-server.rhodesislandpass.com/");

// Modify the Select component styles to ensure its dropdown appears above the table
const selectStyles = {
  control: (base) => ({
    ...base,
    height: '56px',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Higher z-index to ensure dropdown appears above the table
  }),
};

function ReservationTable() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalReservations, setTotalReservations] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [sortField, setSortField] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState(-1); // -1 for descending, 1 for ascending
  // State for property filter
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [deletePopUpWindowIsOpen, setDeletePopUpWindowIsOpen] = useState(false);
  const [editPopUpWindowIsOpen, setEditPopUpWindowIsOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);

  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);

  useEffect(() => {
    const handleReservationCreate = (reservation) => {
      setTotalReservations((prevTotalReservations) => {
        const newTotal = prevTotalReservations + 1;

        // Calculate new page count based on the updated total reservations
        setPageCount(Math.ceil(newTotal / itemsPerPage));

        return newTotal;
      });

      if (currentPage === 1) {
        setReservations((prevReservations) => {
          if (prevReservations.length === itemsPerPage) {
            // If the page is full, remove the last item and add the new reservation at the start
            return [reservation, ...prevReservations.slice(0, -1)];
          } else {
            // If the page is not full, just add the new reservation at the start
            return [reservation, ...prevReservations];
          }
        });
      }
    };

    const handleReservationUpdate = (updatedReservation) => {
      // Update the reservation in the state
      setReservations((prevReservations) =>
        prevReservations.map((reservation) =>
          reservation._id === updatedReservation._id
            ? updatedReservation
            : reservation
        )
      );
    };

    const handleReservationDelete = (reservationId) => {
      // Remove the deleted reservation from the state
      if (
        reservations.find((reservation) => reservation._id === reservationId)
      ) {
        setReservations((prevReservations) =>
          prevReservations.filter(
            (reservation) => reservation._id !== reservationId
          )
        );
        setTotalReservations(
          (prevTotalReservations) => prevTotalReservations - 1
        );
      }
    };

    socket.on("reservationCreated", handleReservationCreate);
    socket.on("reservationUpdated", handleReservationUpdate);
    socket.on("reservationDeleted", (reservationId) =>
      handleReservationDelete(reservationId)
    );

    return () => {
      socket.off("reservationCreated", handleReservationCreate);
      socket.off("reservationUpdated", handleReservationUpdate);
      socket.off("reservationDeleted", handleReservationDelete);
    };
  }, [reservations]);

  // Fetch properties for the dropdown
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/property/getPropertiesNames`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        
        const propertyOptions = response.data.propertiesNames.map(prop => ({
          value: prop._id,
          label: prop.name
        }));
        
        setProperties([{ value: '', label: 'All Properties' }, ...propertyOptions]);
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      }
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchReservations(searchTerm, checkIn, checkOut, selectedProperty?.value || '');
    setIsLoading(false);
  }, [currentPage, itemsPerPage, sortField, sortOrder]);

  const deleteReservation = async (reservationId) => {
    try {

      const response = await axios.delete(
        `https://maliarakis-server.rhodesislandpass.com/reservation/deleteReservation/${reservationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 200) {
        // Remove the deleted reservation from the state
        setReservations((prevReservations) =>
          prevReservations.filter(
            (reservation) => reservation.reservationId !== reservationId
          )
        );
        setTotalReservations(
          (prevTotalReservations) => prevTotalReservations - 1
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReservations = async (term, checkIn, checkOut, propertyId = '') => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${apiUrl}/reservation/getReservations?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${term}&checkIn=${checkIn}&checkOut=${checkOut}&propertyId=${propertyId}&sortField=${sortField}&sortOrder=${
          sortOrder === 1 ? "asc" : "desc"
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setReservations([...response.data.reservations]);
      setTotalReservations(response.data.totalItems);
      setPageCount(Math.ceil(response.data.totalItems / itemsPerPage));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent the form from causing a page reload
    fetchReservations(searchTerm, checkIn, checkOut, selectedProperty?.value || '');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePropertyChange = (selectedOption) => {
    setSelectedProperty(selectedOption);
    if (!searchTimeout) {
      setSearchTimeout(setTimeout(() => {
        fetchReservations(searchTerm, checkIn, checkOut, selectedOption?.value || '');
        setSearchTimeout(null);
      }, 500));
    }
  };

  const handleClearSearch = (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    event.stopPropagation(); // Stop the event from propagating to the form's onSubmit handler
    setSearchTerm("");
    setCheckIn("");
    setCheckOut("");
    setSelectedProperty(null);
    setSortField("updatedAt");
    setSortOrder(-1);
    setCurrentPage(1);

    fetchReservations("", "", "", "");
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order if the same field was clicked
      setSortOrder(sortOrder * -1);
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortOrder(1);
    }
  };

  const handleSearch = async (searchTerm) => {
    setIsLoading(true);
    try {
      // Make sure to properly encode the search term for URL parameters
      const response = await axios.get(
        `${apiUrl}/reservation/getReservations?search=${encodeURIComponent(searchTerm)}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      setReservations(response.data.reservations);
      setTotalReservations(response.data.totalItems); // Fixed: use setTotalReservations instead of setTotalItems
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching reservations:", error);
      setIsLoading(false);
    }
  };

  // If you have a search input, make sure it calls the search function:
  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm); // Fixed: use setSearchTerm instead of setSearchQuery
    
    // Optional: Use debounce to avoid too many requests
    if (searchTimeout) clearTimeout(searchTimeout);
    setSearchTimeout(setTimeout(() => {
      handleSearch(searchTerm);
    }, 500)); // 500ms delay
  };

  return (
    <>
      {isLoading && <Loader />}


      {
        !isLoading && editPopUpWindowIsOpen && (
          <PopUpWindow
            onClose={() => {
              setEditPopUpWindowIsOpen(false);
              setSelectedReservation(null);
              setEdit(false);
              setView(false);
            }}
            unfocusClose={true}
          >
            <AddReservationForm
              _id={selectedReservation._id}
              reservation_id={selectedReservation.reservationId}
              property={selectedReservation.property} // Pass property ID directly
              propertyName={selectedReservation.property.name} // Pass property name as a separate prop
              checkIn={selectedReservation.checkIn}
              checkOut={selectedReservation.checkOut}
              nights={selectedReservation.nights}
              adults={selectedReservation.adults}
              kids={selectedReservation.kids}
              staffNotes={selectedReservation.staffNotes}
              firstName={selectedReservation.firstName}
              lastName={selectedReservation.lastName}
              phone={selectedReservation.phone}

              edit={edit}
              view={view}
              
            />
          </PopUpWindow>
        )
      }

      {
        !isLoading && deletePopUpWindowIsOpen && (

          <PopUpWindow
            onClose={() => {
              setDeletePopUpWindowIsOpen(false);
            }}
            unfocusClose={true}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2>Διαγραφή Κράτησης</h2>
              <p>Είστε σίγουροι ότι θέλετε να διαγράψετε την κράτηση;</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setDeletePopUpWindowIsOpen(false);
                  }}
                  style={{
                    backgroundColor: "var(--secondary-color)",
                    color: "var(--primary-text-color)",
                  }}

                >
                  ΑΚΥΡΩΣΗ
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--primary-text-color)",
                  }}

                  onClick={async () => {
                    await deleteReservation(selectedReservation._id);
                    setDeletePopUpWindowIsOpen(false);
                  }}
                >
                  ΔΙΑΓΡΑΦΗ

                </Button>
              </div>
            </div>
          </PopUpWindow>
        )
      }

      {!isLoading && (
        <>
          <form
            onSubmit={handleSearchSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "95%",
              padding: "10px",
              position: "relative", // Add position relative to the form
              zIndex: 2, // Add a z-index to ensure form elements appear above the table
              marginBottom: "15px", // Add some margin to separate from table
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                marginBottom: "10px"
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Αναζήτηση κράτησης ή ονόματος πελάτη"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ flexGrow: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: searchTerm && (
                    <InputAdornment position="end">
                      <Tooltip title="Clear search">
                        <IconButton
                          onClick={() => {
                            setSearchTerm("");
                            fetchReservations("", checkIn, checkOut, selectedProperty?.value || '');
                          }}
                          aria-label="clear search"
                        >
                          <Clear />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              
              {/* Property Select Dropdown with updated styles */}
              <div style={{ width: "25%", position: "relative" }}>
                <Select
                  value={selectedProperty}
                  onChange={handlePropertyChange}
                  options={properties}
                  placeholder="Επιλογή καταλύματος"
                  isClearable
                  styles={selectStyles}
                  menuPortalTarget={document.body} // Render dropdown in body to avoid container clipping
                  menuPosition={'fixed'} // Use fixed position for the menu
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <TextField
                label="Check-In Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={checkIn}
                onChange={(e) => setCheckIn(e.target.value)}
                sx={{ width: "20%" }}
              />
              
              <TextField
                label="Check-Out Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={checkOut}
                onChange={(e) => setCheckOut(e.target.value)}
                sx={{ width: "20%" }}
              />
              
              <Button
                type="submit"
                variant="contained"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--primary-text-color)",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                ΑΝΑΖΗΤΗΣΗ
              </Button>

              <Button
                variant="contained"
                color="secondary"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--primary-text-color)",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
                onClick={handleClearSearch}
              >
                ΕΚΚΑΘΑΡΙΣΗ
              </Button>
            </div>
          </form>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 540,
              overflow: "auto",
              width: "95%",
              paddingRight: "20px",
              position: "relative", // Add position relative
              zIndex: 1, // Lower z-index than the form
            }}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  {/* <TableCell></TableCell> */}
                  <TableCell></TableCell>
                  <TableCell align="center" key="reservationId">
                    <TableSortLabel
                      active={sortField === "reservationId"}
                      direction={
                        sortField === "reservationId" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("reservationId")}
                    >
                      ID Κράτησης
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="center" key="property">
                    <TableSortLabel
                      active={sortField === "property"}
                      direction={
                        sortField === "property" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("property")}
                    >
                      Κατάλυμα
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="checkIn">
                    <TableSortLabel
                      active={sortField === "checkIn"}
                      direction={
                        sortField === "checkIn" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("checkIn")}
                    >
                      Check-In
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="checkOut">
                    <TableSortLabel
                      active={sortField === "checkOut"}
                      direction={
                        sortField === "checkOut" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("checkOut")}
                    >
                      Check-Out
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="nights">
                    <TableSortLabel
                      active={sortField === "nights"}
                      direction={
                        sortField === "nights" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("nights")}
                    >
                      Νύχτες
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="adults">
                    <TableSortLabel
                      active={sortField === "adults"}
                      direction={
                        sortField === "adults" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("adults")}
                    >
                      Ενήλικες
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="kids">
                    <TableSortLabel
                      active={sortField === "kids"}
                      direction={
                        sortField === "kids" && sortOrder === 1 ? "asc" : "desc"
                      }
                      onClick={() => handleSortChange("kids")}
                    >
                      Παιδιά
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="center" key="staffNotes">
                    <TableSortLabel
                      active={sortField === "staffNotes"}
                      direction={
                        sortField === "staffNotes" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("staffNotes")}
                    >
                      Σημειώσεις
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="center" key="firstName">
                    <TableSortLabel
                      active={sortField === "firstName"}
                      direction={
                        sortField === "firstName" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("firstName")}
                    >
                      Όνομα
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="lastName">
                    <TableSortLabel
                      active={sortField === "lastName"}
                      direction={
                        sortField === "lastName" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("lastName")}
                    >
                      Επίθετο
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" key="phone">
                    <TableSortLabel
                      active={sortField === "phone"}
                      direction={
                        sortField === "phone" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("phone")}
                    >
                      Τηλέφωνο
                    </TableSortLabel>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reservations.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ padding: "0px" }}>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            setSelectedReservation(row);
                            setView(true);
                            setEdit(false);
                            setEditPopUpWindowIsOpen(true);
                          }}
                        >
                          <RemoveRedEye
                            sx={{ color: "var(--primary-color)" }}
                          />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <Button
                          onClick={() => {
                            setSelectedReservation(row);
                            setEdit(true);
                            setView(false);
                            setEditPopUpWindowIsOpen(true);
                          }}
                        >
                          <Edit sx={{ color: "var(--primary-color)" }} />
                        </Button>
                      </Tooltip>
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {row.reservationId}
                    </TableCell>
                    <TableCell align="center">{row.property.name}</TableCell>
                    <TableCell align="center">
                      {formatDate(row.checkIn)}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(row.checkOut)}
                    </TableCell>
                    <TableCell align="center">{row.nights}</TableCell>
                    <TableCell align="center">{row.adults}</TableCell>
                    <TableCell align="center">{row.kids}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "150px",

                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Tooltip
                        title={row.staffNotes}
                        PopperProps={{
                          style: { zIndex: 10001 },
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "13px",
                            },
                          },
                        }}
                      >
                        {row.staffNotes}
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{row.firstName}</TableCell>
                    <TableCell align="center">{row.lastName}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            setSelectedReservation(row);
                            setDeletePopUpWindowIsOpen(true);
                          }}
                        >
                          <Delete sx={{ color: "var(--error-color)" }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={2}
            sx={{ width: "95%" }}
          >
            <Typography>
              Showing{" "}
              {Math.min(
                (currentPage - 1) * itemsPerPage + 1,
                totalReservations
              )}
              -{Math.min(currentPage * itemsPerPage, totalReservations)} of{" "}
              {totalReservations} items
            </Typography>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={(event, page) => {
                setCurrentPage(page);
              }}
              color="primary"
            />
          </Box>
        </>
      )}
    </>
  );
}

export default ReservationTable;
