import React, { useState, useEffect, useCallback, useRef } from "react";
import "./Calendar.css";
import axios from "axios";
import io from "socket.io-client";
import { AddActionForm, PopUpWindow, ViewActionsForm } from "../../components";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  Typography,
  Chip,
  TableSortLabel,
  Button,
  TextField,
  Alert,
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const socket = io("https://maliarakis-server.rhodesislandpass.com/");

function formatDateString(date) {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};
// Update the UserChip component
const UserChip = ({ user, tasks, estimatedHours, onClick, isActive }) => {
  const formatHours = (hours, userActions) => {
    // If any action has 0 estimated hours, show the '+' format
    const hasZeroEstimatedHours = userActions?.some(action => 
      action.estimated_hours === 0 || !action.estimated_hours
    );
    
    if (hasZeroEstimatedHours) {
      return `~${hours.toFixed(2)}+`;
    }
    
    if (hours === 0) return "0.00";
    return hours.toFixed(2);
  };

  return (
    <div className={`user-chip ${isActive ? "active" : ""}`} onClick={onClick}>
      <Avatar
        style={{
          backgroundColor: `${getColor(user.name)}`,
          width: "30px",
          height: "30px",
          fontSize: "15px",
        }}
      >
        {user.name.charAt(0).toUpperCase()}
      </Avatar>
      <div>
        <p>
          {user.name} (#{tasks} {formatHours(estimatedHours, user.actions)}hrs)
        </p>
      </div>
    </div>
  );
};

// Add new component after UserChip component
const FilterChip = ({ label, count, onClick, isActive }) => {
  return (
    <div className={`filter-chip ${isActive ? "active" : ""}`} onClick={onClick}>
      <div>
        <p>{label} ({count})</p>
      </div>
    </div>
  );
};

// Add this helper function at the top level, before the Calendar component
const getPropertyStatus = (property, allActions, dates) => {
  // Helper function to count days between dates
  const getDaysDifference = (start, end) => {
    return Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end dates
  };

  // Get all actions for this property
  const propertyActions = allActions.filter(action => action.property === property._id);
  const actionDates = propertyActions.map(action => 
    new Date(new Date(action.planned_start_date).setHours(0, 0, 0, 0))
  );

  // Sort reservations and clip them to visible date range
  const sortedReservations = property.reservations
    .map(res => {
      const start = new Date(new Date(res.startDate).setHours(0, 0, 0, 0));
      const end = new Date(new Date(res.endDate).setHours(0, 0, 0, 0));
      
      // Clip reservation to visible range
      const visibleStart = start < dates[0] ? dates[0] : start;
      const visibleEnd = end > dates[dates.length - 1] ? dates[dates.length - 1] : end;
      
      return {
        start: visibleStart,
        end: visibleEnd,
        originalStart: start,
        originalEnd: end
      };
    })
    .filter(res => res.start <= dates[dates.length - 1] && res.end >= dates[0]) // Only keep visible reservations
    .sort((a, b) => a.start - b.start);

  // Rule 1: Check visible reservation lengths
  for (const res of sortedReservations) {
    const visibleLength = getDaysDifference(res.start, res.end);
    if (visibleLength >= 15) {
      const hasAction = actionDates.some(actionDate => 
        actionDate >= res.start && actionDate <= res.end
      );
      if (!hasAction) return 'warning';
    }
  }

  // Rule 2 & 3: Check before/after actions and collisions
  for (let i = 0; i < sortedReservations.length - 1; i++) {
    const current = sortedReservations[i];
    const next = sortedReservations[i + 1];

    // Check for collision between consecutive reservations
    // Two reservations collide when the end date of one equals the start date of another
    if (current.end.getTime() === next.start.getTime()) {
      // For a collision, we need an action specifically on this shared date
      const collisionDate = current.end.getTime();
      const hasActionOnCollision = actionDates.some(date => 
        date.getTime() === collisionDate
      );
      
      // If no action exists on the collision date, it's a critical situation
      if (!hasActionOnCollision) {
        return 'critical';
      }
    }
  }

  // Only check for before/after actions if reservations exist and no critical issues were found
  for (const current of sortedReservations) {
    // Only check for before/after actions if the reservation dates are within our visible range
    const startIsVisible = current.start.getTime() === current.originalStart.getTime();
    const endIsVisible = current.end.getTime() === current.originalEnd.getTime();

    // Check for action before start date if start is visible
    if (startIsVisible) {
      // First check if there's an action on the start date itself
      const hasActionOnStart = actionDates.some(date => 
        date.getTime() === current.start.getTime()
      );

      if (!hasActionOnStart) {
        // Check for actions up to 4 days before
        let hasActionBeforeStart = false;
        for (let j = 1; j <= 4; j++) {
          const dayToCheck = new Date(current.start);
          dayToCheck.setDate(dayToCheck.getDate() - j);
          if (actionDates.some(date => date.getTime() === dayToCheck.getTime())) {
            hasActionBeforeStart = true;
            break;
          }
        }
        if (!hasActionBeforeStart) return 'warning';
      }
    }

    // Check for action after end date if end is visible
    if (endIsVisible) {
      // First check if there's an action on the end date itself
      const hasActionOnEnd = actionDates.some(date => 
        date.getTime() === current.end.getTime()
      );

      if (!hasActionOnEnd) {
        // Check for actions up to 4 days after
        let hasActionAfterEnd = false;
        for (let j = 1; j <= 4; j++) {
          const dayToCheck = new Date(current.end);
          dayToCheck.setDate(dayToCheck.getDate() + j);
          if (actionDates.some(date => date.getTime() === dayToCheck.getTime())) {
            hasActionAfterEnd = true;
            break;
          }
        }
        if (!hasActionAfterEnd) return 'warning';
      }
    }
  }

  return 'normal';
};

// Add this helper function near the other utility functions
const hasUnassignedActions = (property) => {
  return property.actions.some(action => 
    !action.assigned_to || action.assigned_to.length === 0
  );
};

// Update the ReservationTooltip component to handle multiple reservations
const ReservationTooltip = ({ reservations }) => {
  if (!reservations || reservations.length === 0) return null;
  
  // Format dates in a readable format
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short', 
      day: 'numeric'
    });
  };

  return (
    <div className="reservation-tooltip">
      <h4>Reservations ({reservations.length})</h4>
      {reservations.map((reservation, index) => (
        <div key={reservation._id || index} className="reservation-item">
          <h5>Reservation {index + 1} {reservation._id && `(ID: ${reservation.reservationId})`}</h5>
          <p><strong>Check-in:</strong> {formatDate(reservation.startDate || reservation.checkIn)}</p>
          <p><strong>Check-out:</strong> {formatDate(reservation.endDate || reservation.checkOut)}</p>
          {reservation.guest && <p><strong>Guest:</strong> {reservation.guest.name || reservation.guest}</p>}
          {reservation.guests && <p><strong>Guests:</strong> {reservation.guests}</p>}
          {reservation.code && <p><strong>Code:</strong> {reservation.code}</p>}
          {reservation.bookingEngine && <p><strong>Booking via:</strong> {reservation.bookingEngine}</p>}
          {reservation.notes && (
            <p className="notes">
              <strong>Notes:</strong> 
              <span className="note-text">{reservation.notes}</span>
            </p>
          )}
          {index < reservations.length - 1 && <hr className="reservation-divider" />}
        </div>
      ))}
    </div>
  );
};

// Add this formatDate function at the top, after the other utility functions
function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

// Add this helper function to normalize dates for consistent comparison
function normalizeDateForComparison(date) {
  if (!date) return null;
  const normalizedDate = new Date(date);
  return new Date(
    normalizedDate.getFullYear(),
    normalizedDate.getMonth(),
    normalizedDate.getDate()
  ).getTime();
}

function Calendar() {
  const [data, setData] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [actionsOptions, setActionsOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [actions, setActions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);

  // Add these two state variables for property reservations
  const [actionReservationsMap, setActionReservationsMap] = useState({});
  const [propertyReservationsOptions, setPropertyReservationsOptions] = useState([]);

  const [createActionPopUpWindowIsOpen, setCreateActionPopUpWindowIsOpen] =
    useState(false);
  const [createActionSelectedDate, setCreateActionSelectedDate] = useState(
    new Date()
  );
  const [createActionSelectedProperty, setCreateActionSelectedProperty] =
    useState(null);

  const [viewActionsPopUpWindowIsOpen, setViewActionsPopUpWindowIsOpen] =
    useState(false);
  const [viewActionsSelectedDate, setViewActionsSelectedDate] = useState(
    new Date()
  );
  const [viewActionsSelectedActions, setViewActionsSelectedActions] = useState(
    []
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHotel, setSelectedHotel] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  const [searchTerm, setSearchTerm] = useState("");

  const [dates, setDates] = useState(
    Array.from(
      { length: 30 },
      (_, i) =>
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + i
        )
    )
  );

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
    } catch (error) {
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/group/getGroups",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGroups(response.data.groups);
    } catch (error) {
      
    }
  };

  const fetchReservations = async () => {
    try {
      // Format dates for API request
      const startDate = dates[0].toISOString();
      const endDate = dates[dates.length - 1].toISOString();
      
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/reservation/getReservationByDateRange?startDate=${startDate}&endDate=${endDate}`, 
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setReservations(response.data.reservations || []);
    } catch (error) {
    
      setReservations([]); // Set empty array on error to prevent undefined issues
    }
  };

  const fetchActionsOptions = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/action-option/getActionOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setActionsOptions(response.data.actionOptions);
    } catch (error) {
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get("https://maliarakis-server.rhodesislandpass.com/property/getCleaningProperties", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setProperties(response.data.properties);
    } catch (error) {
    }
  };

  const fetchActions = async () => {
    try {
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/action/getActionByRange?startDate=${
          dates[0] - 1
        }&endDate=${dates[dates.length - 1]}`,

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setActions(response.data.actions);
    } catch (error) {
    }
  };

  const updateAction = async (actionId, propertyId, date) => {
    try {
      await axios.put(
        `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${actionId}`,
        {
          planned_start_date: date,
          property: propertyId,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      throw error; // Propagate error to handleDrop
    }
  };

  // Add socket event handlers
  useEffect(() => {
    if (!isLoading) {
      socket.on("actionCreated", (newAction) => {
        
        // Check if we actually received an action and if it's within our date range
        if (!newAction) {
          return;
        }
        
        try {
          // Make sure the planned_start_date exists
          if (!newAction.planned_start_date) {
            return;
          }
          
          const actionDate = new Date(newAction.planned_start_date);
          const startDate = dates[0];
          const endDate = dates[dates.length - 1];
          
          if (actionDate >= startDate && actionDate <= endDate) {
            // Extract the action option name and color safely with null checks
            let actionName = "Unknown Action";
            let actionOptionId = null;
            let actionColor = "#3498db"; // Default blue color
            
            // Handle different formats of action_option with null checks
            if (newAction.action_option) {
              if (typeof newAction.action_option === 'object' && newAction.action_option !== null) {
                actionName = newAction.action_option.name || "Unknown Action";
                actionOptionId = newAction.action_option._id || null;
                actionColor = newAction.action_option.color || "#3498db";
              } else {
                // Try to find the action option name and color from our existing options
                const match = actionsOptions.find(opt => opt._id === newAction.action_option);
                actionName = match?.name || "Unknown Action";
                actionColor = match?.color || "#3498db";
                actionOptionId = newAction.action_option;
              }
            }
            
            // Get property ID safely
            const propertyId = typeof newAction.property === 'object' && newAction.property !== null
              ? newAction.property._id 
              : (newAction.property || null);
            
            // Skip if property ID is missing
            if (!propertyId) {
              return;
            }
            
            // Safely extract group ID
            const groupId = typeof newAction.group === 'object' && newAction.group !== null
              ? newAction.group._id
              : (newAction.group || null);
            
            // Process assigned_to array safely
            let assignedToArray = [];
            if (Array.isArray(newAction.assigned_to)) {
              assignedToArray = newAction.assigned_to.map(user => 
                typeof user === 'object' && user !== null ? user._id : user
              ).filter(id => id !== null && id !== undefined);
            }
            
            const formattedAction = {
              _id: newAction._id,
              planned_start_date: new Date(newAction.planned_start_date),
              date: new Date(newAction.planned_start_date),
              action_option: actionOptionId,
              name: actionName,
              property: propertyId,
              group: groupId,
              assigned_to: assignedToArray,
              price: newAction.price || 0,
              notes: newAction.notes || "",
              estimated_hours: newAction.estimated_hours || 0,
              color: actionColor // Use the extracted color
            };
            
            setActions(prevActions => [...prevActions, formattedAction]);
          }
        } catch (error) {
          console.error("Error processing new action:", error, newAction);
        }
      });

      socket.on("actionUpdated", (updatedAction) => {
        if (!updatedAction) {
          console.error("Received null or undefined action in actionUpdated event");
          return;
        }
        
        try {
          // Extract the action option name and color safely with null checks
          let actionName = "Unknown Action";
          let actionOptionId = null;
          let actionColor = "#3498db"; // Default blue color
          
          // Handle different formats of action_option with null checks
          if (updatedAction.action_option) {
            if (typeof updatedAction.action_option === 'object' && updatedAction.action_option !== null) {
              actionName = updatedAction.action_option.name || "Unknown Action";
              actionOptionId = updatedAction.action_option._id || null;
              actionColor = updatedAction.action_option.color || "#3498db";
            } else {
              // Try to find the action option name and color from our existing options
              const match = actionsOptions.find(opt => opt._id === updatedAction.action_option);
              actionName = match?.name || "Unknown Action";
              actionColor = match?.color || "#3498db";
              actionOptionId = updatedAction.action_option;
            }
          }
          
          // Get property ID safely
          const propertyId = typeof updatedAction.property === 'object' && updatedAction.property !== null
            ? updatedAction.property._id 
            : (updatedAction.property || null);
          
          // Skip if property ID is missing
          if (!propertyId) {
            console.error("Updated action has no valid property ID:", updatedAction);
            return;
          }
          
          // Safely extract group ID
          const groupId = typeof updatedAction.group === 'object' && updatedAction.group !== null
            ? updatedAction.group._id
            : (updatedAction.group || null);
          
          // Process assigned_to array safely
          let assignedToArray = [];
          if (Array.isArray(updatedAction.assigned_to)) {
            assignedToArray = updatedAction.assigned_to.map(user => 
              typeof user === 'object' && user !== null ? user._id : user
            ).filter(id => id !== null && id !== undefined);
          }
          
          const formattedAction = {
            ...updatedAction,
            planned_start_date: new Date(updatedAction.planned_start_date || new Date()),
            date: new Date(updatedAction.planned_start_date || new Date()),
            action_option: actionOptionId,
            name: actionName,
            property: propertyId,
            group: groupId,
            assigned_to: assignedToArray,
            estimated_hours: updatedAction.estimated_hours || 0,
            color: actionColor // Use the extracted color
          };

          setActions(prevActions => 
            prevActions.map(action => 
              action._id === updatedAction._id ? formattedAction : action
            )
          );
        } catch (error) {
          console.error("Error processing updated action:", error, updatedAction);
        }
      });

      // No changes needed for actionDeleted handler
      socket.on("actionDeleted", (actionId) => {
        if (!actionId) {
          console.error("Received null or undefined action ID in actionDeleted event");
          return;
        }
        
        setActions(prevActions => 
          prevActions.filter(action => action._id !== actionId)
        );
      });

      return () => {
        socket.off("actionCreated");
        socket.off("actionUpdated");
        socket.off("actionDeleted");
      };
    }
  }, [isLoading, dates, actionsOptions]);

  // Update data when actions change
  useEffect(() => {
    if (!isLoading && properties.length > 0) {

      // Create a mapping of property IDs to property objects for faster lookup
      const propertyMap = {};
      properties.forEach(property => {
        propertyMap[property._id] = {
          ...property,
          actions: [],
          reservations: []
        };
      });

      // Process reservations and add them to the corresponding properties
      reservations.forEach(reservation => {
        // Skip if the property doesn't exist in our map
        if (!propertyMap[reservation.property]) {
          return;
        }

        // Create a reservation object with properly formatted dates
        const formattedReservation = {
          ...reservation,
          startDate: new Date(reservation.checkIn),
          endDate: new Date(reservation.checkOut)
        };

        // Add the reservation to the property's reservations array
        propertyMap[reservation.property].reservations.push(formattedReservation);
      });

      // Process actions and add them to the corresponding properties
      actions.forEach(action => {
        // Skip if action is invalid or property doesn't exist in our map
        if (!action || !action.property || !propertyMap[action.property]) {
          return;
        }
        
        // Determine the action color
        let actionColor = action.color;
        
        // If action doesn't have a color, check if we can get it from actionsOptions
        if (!actionColor && action.action_option) {
          const optionId = typeof action.action_option === 'object' ? 
            action.action_option._id : action.action_option;
            
          const matchingOption = actionsOptions.find(opt => opt._id === optionId);
          actionColor = matchingOption?.color || "#3498db";
        }
        
        // Format the action with consistent date fields, name, and color
        const formattedAction = {
          ...action,
          date: new Date(action.planned_start_date || new Date()),
          color: actionColor || "#3498db", // Use the determined color or default blue
          name: action.action_option?.name || action.name || "Unknown Action"
        };
        
        // Only add actions that match the selected user filter, if any
        if (selectedUser) {
          if (selectedUser._id === 'unassigned') {
            // For unassigned filter, only include actions with no assignees
            if (action.assigned_to && action.assigned_to.length > 0) {
              return;
            }
          } else if (action.assigned_to && !action.assigned_to.includes(selectedUser._id)) {
            // For specific user filter, only include actions assigned to that user
            return;
          }
        }
        
        // Add the action to the property's actions array
        propertyMap[action.property].actions.push(formattedAction);
      });
      
      
      // Convert the property map back to an array for the component state
      const processedData = Object.values(propertyMap);
      
      
      // Filter out properties that don't match the search term
      const filteredData = searchTerm ? 
        processedData.filter(property => property.name.toLowerCase().includes(searchTerm)) : 
        processedData;
      
      // Update the data state with the processed properties
      setData(filteredData);
    }
  }, [actions, properties, reservations, actionsOptions, isLoading, selectedUser, searchTerm]);

  // Initial data fetch
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        // First fetch properties, as they're needed for other data
        await fetchProperties();
        
        // Now fetch the rest in parallel since properties are available
        await Promise.all([
          fetchActionsOptions(),
          fetchGroups(),
          fetchUsers(),
          fetchReservations(),
          fetchActions()
        ]);
        
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  // Handle date changes
  useEffect(() => {
    if (!isLoading) {
      fetchReservations();
      fetchActions();
    }
  }, [dates]);

  const changeDate = (offset) => {
    setDates(
      dates.map(
        (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate() + offset)
      )
    );
  };

  const handleDrop = async (sourceHotelId, targetHotelId, oldDate, newDate, draggedActions) => {
    // Make sure draggedActions exists or initialize an empty array
    draggedActions = draggedActions || [];
    
    try {
      // Find all actions being dragged from the source property on this date
      const sourceProperty = data.find((hotel) => hotel._id === sourceHotelId);
      if (!sourceProperty) {
        return;
      }
      
      const prevActions = sourceProperty.actions.filter((action) => 
        action.date.getDate() === oldDate.getDate() && 
        action.date.getMonth() === oldDate.getMonth() &&
        action.date.getFullYear() === oldDate.getFullYear()
      );
      
      if (prevActions.length === 0) {
        return;
      }

      // Log details of the actions being dragged
     
      
      // Find reservations on the target cell's date for the target property
      const targetProperty = data.find((hotel) => hotel._id === targetHotelId);
      if (!targetProperty) {
        return;
      }
      
      const targetCellReservations = targetProperty?.reservations.filter(reservation => {
        // Normalize dates by removing time portion
        const cellDate = new Date(
          newDate.getFullYear(), 
          newDate.getMonth(), 
          newDate.getDate()
        ).setHours(0, 0, 0, 0);
        
        const startDate = new Date(
          reservation.startDate.getFullYear(),
          reservation.startDate.getMonth(), 
          reservation.startDate.getDate()
        ).setHours(0, 0, 0, 0);
        
        const endDate = new Date(
          reservation.endDate.getFullYear(), 
          reservation.endDate.getMonth(), 
          reservation.endDate.getDate()
        ).setHours(0, 0, 0, 0);

        return cellDate >= startDate && cellDate <= endDate;
      }) || [];

      // Check if property changed (cross-property drag)
      if (sourceHotelId !== targetHotelId) {
        
        // Look for actions with reservations
        const actionsWithReservations = [];
        
        // Iterate through actions to check their reservations
        for (const action of prevActions) {
          // First check our actionReservationsMap
          const reservations = actionReservationsMap[action._id];
          
          // If we have reservations cached
          if (reservations && reservations.length > 0) {
            actionsWithReservations.push(action);
            continue;
          }
          
          // If not in the cache, try to fetch reservations for this action
          try {
            const response = await axios.get(
              `https://maliarakis-server.rhodesislandpass.com/action/getActionReservations/${action._id}`,
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            );
            
            // If the action has reservations, flag it
            if (response.data.reservations && response.data.reservations.length > 0) {
              // Update our reservation map
              setActionReservationsMap(prev => ({
                ...prev,
                [action._id]: response.data.reservations
              }));
              
              actionsWithReservations.push(action);
            }
          } catch (error) {
            console.error(`Failed to check reservations for action ${action._id}:`, error);
          }
        }
        
        // Store data for later decision making
        setPendingDragAction({
          sourceHotelId,
          targetHotelId,
          oldDate,
          newDate,
          prevActions
        });
        
        // If there are target cell reservations, we need to show reservation options
        if (targetCellReservations.length > 0) {
          
          // Format them for display
          const formattedReservations = targetCellReservations.map(res => ({
            value: res._id,
            label: `${res.reservationId || ''} - ${res.firstName || 'Guest'} ${res.lastName || ''} (${formatDate(res.checkIn || res.startDate)} to ${formatDate(res.checkOut || res.endDate)})`,
            reservation: res
          }));
          
          setTargetCellReservations(formattedReservations);
          setShowReservationOptions(true);
          setConfirmDialogOpen(true);
          return;
        }
        
        // If actions have reservations but no target cell reservations, show confirmation dialog
        if (actionsWithReservations.length > 0) {
          
          setTargetCellReservations([]);
          setShowReservationOptions(false);
          setConfirmDialogOpen(true);
          return;
        }
      }
      
      // If no reservations or same property, proceed with the drop
      await executeDragDrop(sourceHotelId, targetHotelId, oldDate, newDate, prevActions);
    } catch (error) {
    }
  };

  // Define executeDragDrop function
  const executeDragDrop = async (sourceHotelId, targetHotelId, oldDate, newDate, prevActions, reservationOption = "keep") => {
    try {
      // Store the action IDs before making changes
      const actionIdsBeingMoved = prevActions.map(action => action._id);

      // Create normalized dates for comparison
      const normalizedOldDate = normalizeDateForComparison(oldDate);
      const normalizedNewDate = normalizeDateForComparison(newDate);
      
      // Optimistically update the UI
      const updatedData = data.map((hotel) => {
        // Remove from source property
        if (hotel._id === sourceHotelId) {
          return {
            ...hotel,
            actions: hotel.actions.filter((action) => {
              // Keep actions that either are not on the target date or are not in our list to move
              const actionDateNormalized = normalizeDateForComparison(action.date);
              return actionDateNormalized !== normalizedOldDate || !actionIdsBeingMoved.includes(action._id);
            }),
          };
        } 
        // Add to target property
        else if (hotel._id === targetHotelId) {
          // Create new action objects with proper date formatting to avoid reference issues
          const movedActions = prevActions.map((action) => {
            // Create a clean new date object for the action to prevent reference issues
            const newActionDate = new Date(newDate);
            
            return {
              ...action,
              date: newActionDate, 
              planned_start_date: newActionDate,
              property: targetHotelId,
            };
          });
          
          return {
            ...hotel,
            actions: [...hotel.actions, ...movedActions],
          };
        }
        return hotel;
      });

      setData(updatedData);

      // Update each action on the server
      for (const action of prevActions) {
        const updateData = {
          planned_start_date: newDate,
          property: targetHotelId,
        };

        // Handle reservations based on selected option
        if (sourceHotelId !== targetHotelId) {
          switch (reservationOption) {
            case "keep":
              // Keep existing reservations, do nothing
              break;
            case "add":
              // Add target cell reservations to existing ones
              const currentReservations = actionReservationsMap[action._id] || [];
              const currentIds = new Set(currentReservations.map(res => res._id));
              
              // Add new reservations that aren't already there
              const newReservations = targetCellReservations
                .filter(res => !currentIds.has(res.value))
                .map(res => res.value);
                
              updateData.reservations = [
                ...currentReservations.map(res => res._id), 
                ...newReservations
              ];
              
              // Update local state
              setActionReservationsMap(prev => ({
                ...prev,
                [action._id]: [
                  ...currentReservations,
                  ...targetCellReservations.map(res => res.reservation).filter(res => !currentIds.has(res._id))
                ]
              }));
              break;
            case "replace":
              // Replace with target cell reservations
              updateData.reservations = targetCellReservations.map(res => res.value);
              
              // Update local state
              setActionReservationsMap(prev => ({
                ...prev,
                [action._id]: targetCellReservations.map(res => res.reservation)
              }));
              break;
            case "remove":
              // Remove all reservations
              updateData.reservations = [];
              
              // Update local state
              setActionReservationsMap(prev => ({
                ...prev,
                [action._id]: []
              }));
              break;
            default:
              // Default case, do nothing with reservations
              break;
          }
        }

        try {
          const response = await axios.put(
            `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${action._id}`,
            updateData,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          
        } catch (updateError) {
        }
      }
      
    } catch (error) {
      // Revert optimistic update by re-fetching data if there's an error
      fetchActions();
    }
  };

  // Handle dialog confirmation
  const handleReservationOption = (option) => {
    
    if (pendingDragAction) {
      const { sourceHotelId, targetHotelId, oldDate, newDate, prevActions } = pendingDragAction;
      executeDragDrop(sourceHotelId, targetHotelId, oldDate, newDate, prevActions, option);
    }
    
    // Reset states after handling
    setConfirmDialogOpen(false);
    setPendingDragAction(null);
    setTargetCellReservations([]);
    setShowReservationOptions(false);
  };

  const [userActionCounts, setUserActionCounts] = useState([]);

  useEffect(() => {
    if (!isLoading && users.length > 0 && actions.length > 0) {
      const isActionInRange = (action) => {
        const actionDate = new Date(action.planned_start_date);
        return actionDate >= dates[0] && actionDate <= dates[dates.length - 1];
      };

      const counts = users.map(user => {
        const userActions = actions.filter(action => {
          const actionDate = new Date(action.planned_start_date);
          const matchesDate = selectedDateFilter ? 
            actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
            actionDate.getMonth() === selectedDateFilter.getMonth() &&
            actionDate.getDate() === selectedDateFilter.getDate() : true;
          
          return action.assigned_to && 
                 action.assigned_to.includes(user._id) && 
                 matchesDate &&
                 isActionInRange(action);
        });

        const totalEstimatedHours = userActions.reduce((total, action) => {
          return total + (action.estimated_hours || 0);
        }, 0);

        return {
          ...user,
          actionCount: userActions.length,
          estimatedHours: totalEstimatedHours,
          actions: userActions // Pass the actions to check for zero estimated hours
        };
      }).filter(user => user.actionCount > 0)
        .sort((a, b) => b.actionCount - a.actionCount);
      
      setUserActionCounts(counts);
    }
  }, [actions, users, isLoading, selectedDateFilter, dates]);

  const handleDateColumnClick = (date) => {
    setSelectedDateFilter(selectedDateFilter?.getTime() === date.getTime() ? null : date);
  };

  // Add new state for property filters
  const [propertyFilters, setPropertyFilters] = useState(["all"]);
  
  // Add filter logic function
  const filterProperties = useCallback((properties) => {
    if (propertyFilters.includes("all")) {
      return properties;
    }

    return properties.filter(property => {
      const reservationsInRange = property.reservations.filter(res => {
        const resStartDate = new Date(res.startDate);
        const resEndDate = new Date(res.endDate);
        return resStartDate <= dates[dates.length - 1] && resEndDate >= dates[0];
      });

      const actionsInRange = property.actions.filter(action => {
        const actionDate = new Date(action.planned_start_date);
        return actionDate >= dates[0] && actionDate <= dates[dates.length - 1];
      });

      const hasReservation = reservationsInRange.length > 0;
      const hasAction = actionsInRange.length > 0;
      const propertyStatus = getPropertyStatus(property, actions, dates);
      
      return propertyFilters.every(filter => {
        switch (filter) {
          case "withReservation":
            return hasReservation;
          case "withoutReservation":
            return !hasReservation;
          case "withAction":
            return hasAction;
          case "withoutAction":
            return !hasAction;
          case "withWarnings":
            return propertyStatus === 'warning' || propertyStatus === 'critical';
          case "withUnassignedActions":
            return hasUnassignedActions(property);
          default:
            return true;
        }
      });
    });
  }, [propertyFilters, dates]);

  // Add this near where you handle user counts
  const [unassignedActionsCount, setUnassignedActionsCount] = useState(0);
  
  useEffect(() => {
    if (!isLoading && actions.length > 0) {
      const unassignedCount = actions.filter(action => {
        const actionDate = new Date(action.planned_start_date);
        return (!action.assigned_to || action.assigned_to.length === 0) &&
               actionDate >= dates[0] && 
               actionDate <= dates[dates.length - 1];
      }).length;
      setUnassignedActionsCount(unassignedCount);
    }
  }, [actions, dates, isLoading]);

  // Add state for tracking reservation details popup
  const [showReservationDetails, setShowReservationDetails] = useState(false);
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  
  // Update the handleDateCellClick function to handle multiple reservations
  const handleDateCellClick = async (date, property, actions, event, reservationsOnDate) => {
    // If there are reservations for this date, show the reservation details
    if (reservationsOnDate && reservationsOnDate.length > 0) {
      setSelectedReservations(reservationsOnDate);
      
      // Calculate position for the popup based on click event
      const rect = event.target.getBoundingClientRect();
      setPopupPosition({
        x: rect.left + window.scrollX,
        y: rect.bottom + window.scrollY
      });
      
      setShowReservationDetails(true);
    } else {
      // No reservation, hide the popup
      setShowReservationDetails(false);
      setSelectedReservations([]);
    }
    
    // Keep the existing behavior for viewing actions
    if (property && actions && actions.length > 0) {
      // Get the action IDs for fetching reservations
      const actionIds = actions.map((action) => action._id);
      
      // Set the actions popup as open and set selected properties
      setViewActionsPopUpWindowIsOpen(true);
      setViewActionsSelectedDate(date);
      setCreateActionSelectedProperty({
        value: {
          _id: property._id,
          name: property.name,
        },
        label: property.name,
      });
      
      // Format the actions for the view
      const formattedActions = actions.map((action) => {
        return {
          _id: action._id,
          name: action.action_option?.name || action.name || "Unknown Action",
          action_option: action.action_option?._id || action.action_option,
          group: action.group,
          assigned_to: action.assigned_to,
          planned_start_date: action.planned_start_date,
          price: action.price,
          notes: action.notes,
          estimated_hours: action.estimated_hours
        };
      });
      
      setViewActionsSelectedActions(formattedActions);
      
      // Fetch reservations for all the actions and the property
      const { actionReservations, propertyReservations } = await fetchReservationsForActions(actionIds, property._id);
    
      // Store the property reservations in state for the ViewActionsForm
      setPropertyReservationsOptions(propertyReservations);
    }
  };
  
  // Click outside handler to close the reservation popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showReservationDetails && !event.target.closest('.reservation-tooltip')) {
        setShowReservationDetails(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showReservationDetails]);

  // Add this new state for action reservations
  // const [actionReservationsMap, setActionReservationsMap] = useState({});

  // Modify the fetchReservationsForActions function to remove debugging
const fetchReservationsForActions = async (actionIds, propertyId) => {
  try {
    const reservationsMap = {};
    const propertyReservations = [];
    
    // First, fetch all property reservations
    if (propertyId) {
      try {
        const response = await axios.get(
          `https://maliarakis-server.rhodesislandpass.com/reservation/getReservationByProperty/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        
        if (response.data && response.data.reservations) {
          // Format the property reservations for react-select
          const formattedReservations = response.data.reservations.map(res => ({
            value: res._id,
            label: `${res.reservationId || "No ID"} - ${res.firstName || "Guest"} ${res.lastName || ""} (${formatDate(res.checkIn || res.startDate)} to ${formatDate(res.checkOut || res.endDate)})`,
            reservation: res
          }));
          
          // Add all formatted reservations to the propertyReservations array
          propertyReservations.push(...formattedReservations);
        }
      } catch (error) {
        console.error(`Failed to fetch reservations for property ${propertyId}:`, error);
      }
    }
    
    // Then fetch specific action reservations
    const fetchPromises = actionIds.map(async (actionId) => {
      try {
        const response = await axios.get(
          `https://maliarakis-server.rhodesislandpass.com/action/getActionReservations/${actionId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        
        if (response.data && response.data.reservations) {
          reservationsMap[actionId] = response.data.reservations;
        }
      } catch (error) {
        console.error(`Failed to fetch reservations for action ${actionId}:`, error);
        reservationsMap[actionId] = []; // Empty array on error
      }
    });
    
    // Wait for all promises to complete
    await Promise.all(fetchPromises);
    
    // Update state with all reservations and property reservations
    setActionReservationsMap(reservationsMap);
    setPropertyReservationsOptions(propertyReservations);
    
    return { actionReservations: reservationsMap, propertyReservations };
  } catch (error) {
    console.error("Error fetching reservations:", error);
    return { actionReservations: {}, propertyReservations: [] };
  }
};

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingDragAction, setPendingDragAction] = useState(null);
  const [targetCellReservations, setTargetCellReservations] = useState([]);
  const [showReservationOptions, setShowReservationOptions] = useState(false);

  return (
    <div className="calendar-wrapper">
      {createActionPopUpWindowIsOpen && (
        <PopUpWindow
          onClose={() => {
            setCreateActionPopUpWindowIsOpen(false);
          }}
          unfocusClose={true}
        >
          <AddActionForm
            date={
              new Date(
                createActionSelectedDate.getFullYear(),
                createActionSelectedDate.getMonth(),
                createActionSelectedDate.getDate()
              )
            }
            property={{
              value: createActionSelectedProperty?.value,
              label: createActionSelectedProperty?.label,
              value: {
                ...createActionSelectedProperty?.value,
                actions_analytics: properties.find(p => p._id === createActionSelectedProperty?.value?._id)?.actions_analytics || [],
                actions_prices: properties.find(p => p._id === createActionSelectedProperty?.value?._id)?.actions_prices || []
              }
            }}
            // Pass the reservations directly as initialReservations
            reservationsOptions={
              createActionSelectedProperty?.value?._id
                ? reservations.filter(
                    (res) => {
                      // Include all reservations for this property that overlap with our visible date range
                      const resStart = new Date(res.checkIn);
                      const resEnd = new Date(res.checkOut);
                      
                      return res.property === createActionSelectedProperty.value._id && 
                             // Check if reservation overlaps with visible date range
                             resStart <= dates[dates.length - 1] && 
                             resEnd >= dates[0];
                    }
                  ).map(res => ({
                    value: res._id,
                    label: `${res.reservationId} - ${res.firstName} ${res.lastName} (${formatDate(res.checkIn)} to ${formatDate(res.checkOut)})`,
                    reservation: res
                  }))
                : []
            }
            initialReservations={createActionSelectedProperty?.selectedReservations || []}
          />
        </PopUpWindow>
      )}

      <div className="calendar-buttons-wrapper">
        <div className="calendar-buttons-days">
          <button className="calendar-button" onClick={() => changeDate(-15)}>
            Προηγούμενες 15
          </button>
          <button className="calendar-button" onClick={() => changeDate(15)}>
            Έπομενες 15
          </button>
        </div>

        <div className="calendar-buttons-search">
          <Tooltip title="Δίαλεξε Ημερομηνία">
            <input
              type="date"
              value={selectedDate.toISOString().split("T")[0]}
              onChange={(e) => {
                setSelectedDate(new Date(e.target.value));
                setDates(
                  Array.from(
                    { length: 30 },
                    (_, i) =>
                      new Date(
                        new Date(e.target.value).getFullYear(),
                        new Date(e.target.value).getMonth(),
                        new Date(e.target.value).getDate() + i
                      )
                  )
                );
              }}
              style={{
                height: "30px",
                width: "300px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                padding: "5px",
                margin: "0 10px",
                cursor: "pointer",
                backgroundColor: "#f8f8f8",
                color: "#333",
                outlineColor: "var(--primary-color)",

                fontFamily: "Arial",
              }}
            />
          </Tooltip>

          <div className="search-bar">
            <input
              type="text"
              placeholder="Αναζήτηση με όνομα..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
              style={{
                padding: "10px",
                margin: "10px 0",
                width: "300px",
                fontSize: "16px",
                outlineColor: "var(--primary-color)",
              }}
            />
          </div>
        </div>
      </div>
      {!isLoading && (
        <div className="properties-filter-row">
          <FilterChip
            label="All"
            count={data.length}
            onClick={() => setPropertyFilters(["all"])}
            isActive={propertyFilters.includes("all")}
          />
            <FilterChip
            label="With Warnings"
            count={data.filter(p => {
              const status = getPropertyStatus(p, actions, dates);
              return status === 'warning' || status === 'critical';
            }).length}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withWarnings"];
                if (prev.includes("withWarnings")) {
                  return prev.filter(f => f !== "withWarnings");
                }
                return [...prev, "withWarnings"];
              });
            }}
            isActive={propertyFilters.includes("withWarnings")}
          />
          <FilterChip
            label="With Reservation"
            count={data.filter(p => {
              // Only count reservations within current date range
              const reservationsInRange = p.reservations.filter(res => {
                const resStartDate = new Date(res.startDate);
                const resEndDate = new Date(res.endDate);
                return resStartDate <= dates[dates.length - 1] && resEndDate >= dates[0];
              });
              return reservationsInRange.length > 0;
            }).length}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withReservation"];
                if (prev.includes("withReservation")) {
                  return prev.filter(f => f !== "withReservation");
                }
                return [...prev, "withReservation"];
              });
            }}
            isActive={propertyFilters.includes("withReservation")}
          />
          <FilterChip
            label="Without Reservation"
            count={data.filter(p => {
              const reservationsInRange = p.reservations.filter(res => {
                const resStartDate = new Date(res.startDate);
                const resEndDate = new Date(res.endDate);
                return resStartDate <= dates[dates.length - 1] && resEndDate >= dates[0];
              });
              return reservationsInRange.length === 0;
            }).length}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withoutReservation"];
                if (prev.includes("withoutReservation")) {
                  return prev.filter(f => f !== "withoutReservation");
                }
                return [...prev, "withoutReservation"];
              });
            }}
            isActive={propertyFilters.includes("withoutReservation")}
          />
          <FilterChip
            label="With Action"
            count={data.filter(p => {
              // Only count actions within current date range
              const actionsInRange = p.actions.filter(action => {
                const actionDate = new Date(action.planned_start_date);
                return actionDate >= dates[0] && actionDate <= dates[dates.length - 1];
              });
              return actionsInRange.length > 0;
            }).length}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withAction"];
                if (prev.includes("withAction")) {
                  return prev.filter(f => f !== "withAction");
                }
                return [...prev, "withAction"];
              });
            }}
            isActive={propertyFilters.includes("withAction")}
          />
          <FilterChip
            label="Without Action"
            count={data.filter(p => {
              const actionsInRange = p.actions.filter(action => {
                const actionDate = new Date(action.planned_start_date);
                return actionDate >= dates[0] && actionDate <= dates[dates.length - 1];
              });
              return actionsInRange.length === 0;
            }).length}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withoutAction"];
                if (prev.includes("withoutAction")) {
                  return prev.filter(f => f !== "withoutAction");
                }
                return [...prev, "withoutAction"];
              });
            }}
            isActive={propertyFilters.includes("withoutAction")}
          />
        
          <FilterChip
            label="Unassigned Actions"
            count={data.filter(p => hasUnassignedActions(p)).length}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withUnassignedActions"];
                if (prev.includes("withUnassignedActions")) {
                  return prev.filter(f => f !== "withUnassignedActions");
                }
                return [...prev, "withUnassignedActions"];
              });
            }}
            isActive={propertyFilters.includes("withUnassignedActions")}
          />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="calendar-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th className="name-column">Όνομα</th>
                  {dates.map((date) => (
                    <th 
                      key={date.toISOString()} 
                      onClick={() => handleDateColumnClick(date)}
                      className={selectedDateFilter?.getTime() === date.getTime() ? 'selected-date' : ''}
                      style={{ cursor: 'pointer' }}
                    >
                      {date.toDateString()}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterProperties(data)
                  .filter((hotel) =>
                    hotel.name.toLowerCase().includes(searchTerm)
                  )
                  .map((hotel) => (
                    <Row
                      key={hotel._id}
                      hotel={hotel}
                      dates={dates}
                      actions={actions} // Add this line to pass actions to Row
                      handleDrop={handleDrop}
                      setCreateActionPopUpWindowIsOpen={
                        setCreateActionPopUpWindowIsOpen
                      }
                      setCreateActionSelectedDate={setCreateActionSelectedDate}
                      setCreateActionSelectedProperty={
                        setCreateActionSelectedProperty
                      }
                      setViewActionsPopUpWindowIsOpen={
                        setViewActionsPopUpWindowIsOpen
                      }
                      setViewActionsSelectedDate={setViewActionsSelectedDate}
                      setViewActionsSelectedActions={
                        setViewActionsSelectedActions
                      }
                      handleDateCellClick={handleDateCellClick} // Pass new handler to Row
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {!isLoading && userActionCounts.length > 0 && (
        <div className="calendar-users-row">
          <UserChip
            key="all"
            user={{ 
              name: "All Users", 
              _id: "all",
              actions: actions.filter(action => {
                const actionDate = new Date(action.planned_start_date);
                const matchesDate = selectedDateFilter ? 
                  actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                  actionDate.getMonth() === selectedDateFilter.getMonth() &&
                  actionDate.getDate() === selectedDateFilter.getDate() : true;
                return actionDate >= dates[0] && 
                      actionDate <= dates[dates.length - 1] && 
                      matchesDate;
              })
            }}
            tasks={actions.filter(action => {
              const actionDate = new Date(action.planned_start_date);
              const matchesDate = selectedDateFilter ? 
                actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                actionDate.getMonth() === selectedDateFilter.getMonth() &&
                actionDate.getDate() === selectedDateFilter.getDate() : true;
              return actionDate >= dates[0] && 
                     actionDate <= dates[dates.length - 1] && 
                     matchesDate;
            }).length}
            estimatedHours={actions.filter(action => {
              const actionDate = new Date(action.planned_start_date);
              const matchesDate = selectedDateFilter ? 
                actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                actionDate.getMonth() === selectedDateFilter.getMonth() &&
                actionDate.getDate() === selectedDateFilter.getDate() : true;
              return actionDate >= dates[0] && 
                     actionDate <= dates[dates.length - 1] && 
                     matchesDate;
            }).reduce((total, action) => total + (action.estimated_hours || 0), 0)}
            onClick={() => setSelectedUser(null)}
            isActive={selectedUser === null}
          />
          {userActionCounts.map((user) => (
            <UserChip
              key={user._id}
              user={user}
              tasks={user.actionCount}
              estimatedHours={user.estimatedHours}
              onClick={() => setSelectedUser(selectedUser?._id === user._id ? null : user)}
              isActive={selectedUser?._id === user._id}
            />
          ))}
          {unassignedActionsCount > 0 && (
            <UserChip
              key="unassigned"
              user={{ 
                name: "Unassigned", 
                _id: "unassigned",
                actions: actions.filter(action => {
                  const actionDate = new Date(action.planned_start_date);
                  const matchesDate = selectedDateFilter ? 
                    actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                    actionDate.getMonth() === selectedDateFilter.getMonth() &&
                    actionDate.getDate() === selectedDateFilter.getDate() : true;
                  return (!action.assigned_to || action.assigned_to.length === 0) &&
                         actionDate >= dates[0] && 
                         actionDate <= dates[dates.length - 1] && 
                         matchesDate;
                })
              }}
              tasks={unassignedActionsCount}
              estimatedHours={actions.filter(action => {
                const actionDate = new Date(action.planned_start_date);
                const matchesDate = selectedDateFilter ? 
                  actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                  actionDate.getMonth() === selectedDateFilter.getMonth() &&
                  actionDate.getDate() === selectedDateFilter.getDate() : true;
                return (!action.assigned_to || action.assigned_to.length === 0) &&
                       actionDate >= dates[0] && 
                       actionDate <= dates[dates.length - 1] && 
                       matchesDate;
              }).reduce((total, action) => total + (action.estimated_hours || 0), 0)}
              onClick={() => {
                setSelectedUser(selectedUser?.name === "Unassigned" ? null : { 
                  name: "Unassigned", 
                  _id: "unassigned" 
                });
              }}
              isActive={selectedUser?.name === "Unassigned"}
            />
          )}
        </div>
      )}
      {viewActionsPopUpWindowIsOpen && (
        <PopUpWindow
          onClose={() => {
            setViewActionsPopUpWindowIsOpen(false);
          }}
          unfocusClose={true}
          title={`Δραστηριότητες - ${formatDateString(
            viewActionsSelectedDate
          )} - ${createActionSelectedProperty.label}`}
        >
          <ViewActionsForm
            users={users}
            groups={groups}
            selectedActions={viewActionsSelectedActions}
            date={
              new Date(
                viewActionsSelectedDate.getFullYear(),
                viewActionsSelectedDate.getMonth(),
                viewActionsSelectedDate.getDate(), // removed +1
                viewActionsSelectedDate.getHours(),
                viewActionsSelectedDate.getMinutes(),
                viewActionsSelectedDate.getSeconds()
              )
            }
            property={createActionSelectedProperty}
            // Pass both the actionReservationsMap and propertyReservationsOptions to ViewActionsForm
            actionReservations={actionReservationsMap}
            propertyReservations={createActionSelectedProperty?.value?._id
              ? reservations.filter(
                  (res) => {
                    // Include all reservations for this property that overlap with our visible date range
                    const resStart = new Date(res.checkIn);
                    const resEnd = new Date(res.checkOut);
                    
                    return res.property === createActionSelectedProperty.value._id && 
                           // Check if reservation overlaps with visible date range
                           resStart <= dates[dates.length - 1] && 
                           resEnd >= dates[0];
                  }
                ).map(res => ({
                  value: res._id,
                  label: `${res.reservationId} - ${res.firstName} ${res.lastName} (${formatDate(res.checkIn)} to ${formatDate(res.checkOut)})`,
                  reservation: res
                }))
              : []}
          />
        </PopUpWindow>
      )}
      {/* Update the reservation details popup */}
      {showReservationDetails && selectedReservations.length > 0 && (
        <div 
          className="reservation-tooltip-wrapper" 
          style={{ 
            position: 'absolute',
            left: `${popupPosition.x}px`,
            top: `${popupPosition.y}px`,
            zIndex: 1000
          }}
        >
          <ReservationTooltip reservations={selectedReservations} />
        </div>
      )}
      {/* Add the confirmation dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: '12px',
            padding: '8px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ 
          borderBottom: '1px solid #eee',
          fontSize: '1.4rem',
          fontWeight: '600',
          color: 'var(--secondary-color)'
        }}>
          {"Διαχείριση Κρατήσεων"}
        </DialogTitle>
        <DialogContent sx={{ padding: '24px 24px 16px' }}>
          {/* Different content based on scenario */}
          {showReservationOptions && targetCellReservations.length > 0 ? (
            <DialogContentText id="alert-dialog-description">
              <p>Υπάρχουν κρατήσεις στο κατάλυμα προορισμού για αυτή την ημερομηνία. Τι θα θέλατε να κάνετε;</p>
              <ul className="reservation-list-dialog">
                {targetCellReservations.map((res, idx) => (
                  <li key={idx}>{res.label}</li>
                ))}
              </ul>
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Αυτές οι δραστηριότητες έχουν συνδεδεμένες κρατήσεις που ανήκουν στο τρέχον κατάλυμα. Τι θα θέλατε να κάνετε με τις κρατήσεις;
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'stretch', 
          padding: '0 24px 24px',
          gap: '10px'
        }}>
          <Button 
            onClick={() => handleReservationOption("keep")} 
            variant="contained"
            fullWidth
            startIcon={<CheckCircleOutlineIcon />}
            sx={{ 
              backgroundColor: 'var(--primary-color)',
              color: 'white',
              padding: '10px 16px',
              justifyContent: 'flex-start',
              boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: 'var(--secondary-color)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              }
            }}
          >
            Διατήρηση υπαρχουσών κρατήσεων
          </Button>
          
          {showReservationOptions && targetCellReservations.length > 0 && (
            <Button 
              onClick={() => handleReservationOption("add")} 
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              fullWidth
              sx={{ 
                borderColor: 'var(--primary-color)',
                color: 'var(--primary-color)',
                padding: '10px 16px',
                justifyContent: 'flex-start',
                '&:hover': {
                  borderColor: 'var(--secondary-color)',
                  color: 'var(--secondary-color)',
                  backgroundColor: 'rgba(var(--primary-color-rgb), 0.05)'
                }
              }}
            >
              Προσθήκη νέων κρατήσεων στις υπάρχουσες
            </Button>
          )}
          
          {showReservationOptions && targetCellReservations.length > 0 && (
            <Button 
              onClick={() => handleReservationOption("replace")} 
              variant="outlined"
              startIcon={<SwapHorizontalCircleIcon />}
              fullWidth
              sx={{ 
                borderColor: 'var(--primary-color)',
                color: 'var(--primary-color)',
                padding: '10px 16px',
                justifyContent: 'flex-start',
                '&:hover': {
                  borderColor: 'var(--secondary-color)',
                  color: 'var(--secondary-color)',
                  backgroundColor: 'rgba(var(--primary-color-rgb), 0.05)'
                }
              }}
            >
              Αντικατάσταση υπαρχουσών κρατήσεων με τις νέες
            </Button>
          )}
          
          <Button 
            onClick={() => handleReservationOption("remove")} 
            variant="outlined" 
            startIcon={<RemoveCircleOutlineIcon />}
            fullWidth
            sx={{ 
              borderColor: '#ff4444',
              color: '#ff4444',
              padding: '10px 16px',
              justifyContent: 'flex-start',
              '&:hover': {
                borderColor: '#cc0000',
                color: '#cc0000',
                backgroundColor: 'rgba(255, 68, 68, 0.05)'
              }
            }}
          >
            Αφαίρεση όλων των κρατήσεων
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Row({
  hotel,
  dates,
  actions, // Add this to the props
  handleDrop,
  setCreateActionPopUpWindowIsOpen,
  setCreateActionSelectedDate,
  setCreateActionSelectedProperty,
  setViewActionsPopUpWindowIsOpen,
  setViewActionsSelectedDate,
  setViewActionsSelectedActions,
  handleDateCellClick, // Add the new prop
}) {
  const propertyStatus = getPropertyStatus(hotel, actions, dates);
  const [initialReservations, setInitialReservations] = useState([]);

  const handleDragStart = (event, date, hotelId, actionsOnDate) => {
    // Get actions on this date if not provided
    if (!actionsOnDate) {
      actionsOnDate = hotel.actions.filter(
        (action) => 
          action.date.getDate() === date.getDate() &&
          action.date.getMonth() === date.getMonth() &&
          action.date.getFullYear() === date.getFullYear()
      );
    }
    
    // Log the actions being dragged
   

    const dragData = {
      sourceHotelId: hotelId,
      actions: actionsOnDate.map((action) => ({
        actionId: action._id,
        date: date.toISOString(),
      })),
      date: date.toISOString(), 
    };
    
    event.dataTransfer.setData("application/json", JSON.stringify(dragData));
    
    // Add a class for visual feedback during drag
    event.currentTarget.classList.add('dragging');
  };

  const onDrop = (event, targetDate) => {
    event.preventDefault();
    
    try {
      const dragData = JSON.parse(event.dataTransfer.getData("application/json"));
      
      
      const oldDate = new Date(dragData.date);
      handleDrop(dragData.sourceHotelId, hotel._id, oldDate, targetDate, dragData.actions);
    } catch (error) {
    }
    
    // Remove the dragging class from any elements
    const draggingElements = document.querySelectorAll('.dragging');
    draggingElements.forEach(el => el.classList.remove('dragging'));
  };

  const onDragOver = (event) => {
    event.preventDefault(); // Allow the drop by preventing the default behavior
  };

  const cellStyle = (date) => {
    const isStartDate = hotel.reservations.some(
      (reservation) =>
        `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` ===
        `${reservation.startDate.getDate()}/${
          reservation.startDate.getMonth() + 1
        }/${reservation.startDate.getFullYear()}`
    );
    const isEndDate = hotel.reservations.some(
      (reservation) =>
        `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` ===
        `${reservation.endDate.getDate()}/${
          reservation.endDate.getMonth() + 1
        }/${reservation.endDate.getFullYear()}`
    );

    const colliding = hotel.reservations.some(
      (reservation) =>
        date.getTime() === reservation.startDate.getTime() &&
        hotel.reservations.some((r) => date.getTime() === r.endDate.getTime())
    );

    if (colliding) {
      return { className: "colliding" }; // Apply colliding class if start and end date are the same and ensure color property is included
    } else if (isStartDate) {
      return { borderRadius: "50px 0 0 0", position: "relative" };
    } else if (isEndDate) {
      return { borderRadius: "0 0 50px 0", position: "relative" };
    } else {
      return { position: "relative" };
    }
  };
  function useSingleAndDoubleClick(
    actionSimpleClick,
    actionDoubleClick,
    delay = 250
  ) {
    const timeoutRef = useRef(null);
    const clickCountRef = useRef(0);

    const eventHandler = useCallback(
      (date, hotel, actions, event, reservationsOnDate) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        clickCountRef.current += 1;

        timeoutRef.current = setTimeout(() => {
          if (clickCountRef.current === 1) {
            actionSimpleClick(date, hotel, actions, event, reservationsOnDate);
          } else if (clickCountRef.current > 1) {
            actionDoubleClick(date, hotel);
          }
          clickCountRef.current = 0;
        }, delay);
      },
      [actionSimpleClick, actionDoubleClick, delay]
    );

    useEffect(() => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    return eventHandler;
  }
  // Enhance formatCell to return all reservations for this date
  const formatCell = (date) => {
    // Find reservations for this date - no changes here, just ensuring we get all reservations
    const reservationsOnDate = hotel.reservations.filter(reservation => {
      // Normalize dates by removing time portion
      const cellDate = new Date(
        date.getFullYear(), 
        date.getMonth(), 
        date.getDate()
      ).setHours(0, 0, 0, 0);
      const startDate = new Date(
        reservation.startDate.getFullYear(),
        reservation.startDate.getMonth(), 
        reservation.startDate.getDate()
      ).setHours(0, 0, 0, 0);
      const endDate = new Date(
        reservation.endDate.getFullYear(), 
        reservation.endDate.getMonth(), 
        reservation.endDate.getDate()
      ).setHours(0, 0, 0, 0);

      return cellDate >= startDate && cellDate <= endDate;
    });

    const isActive = reservationsOnDate.length > 0;

    // Find actions for this date
    const actionsOnDate = hotel.actions.filter(action => {
      const actionDate = new Date(action.date);
      return (
        actionDate.getDate() === date.getDate() &&
        actionDate.getMonth() === date.getMonth() &&
        actionDate.getFullYear() === date.getFullYear()
      );
    }).map(action => ({ 
      ...action,
      // Ensure name is available for tooltips
      name: action.action_option?.name || action.name || "Unknown Action",
      color: action.color || "#3498db" // Ensure color is available
    }));

    return {
      booked: isActive,
      actions: actionsOnDate,
      reservations: reservationsOnDate,
      reservationCount: reservationsOnDate.length // Add count for easy access
    };
  };

  const handleSingleClick = useCallback((date, property, actions, event, reservationsOnDate) => {
    if (property) {
      handleDateCellClick(date, property, actions, event, reservationsOnDate);
    } else {
    }
  }, [handleDateCellClick]);

  const handleDoubleClick = useCallback(
    (date, property) => {
      
      if (property) {
        // Find all reservations that overlap with the selected date
        const reservationsOnDate = property.reservations.filter(reservation => {
          const cellDate = new Date(
            date.getFullYear(), 
            date.getMonth(), 
            date.getDate()
          ).setHours(0, 0, 0, 0);
          
          const startDate = new Date(
            reservation.startDate.getFullYear(),
            reservation.startDate.getMonth(), 
            reservation.startDate.getDate()
          ).setHours(0, 0, 0, 0);
          
          const endDate = new Date(
            reservation.endDate.getFullYear(), 
            reservation.endDate.getMonth(), 
            reservation.endDate.getDate()
          ).setHours(0, 0, 0, 0);

          return cellDate >= startDate && cellDate <= endDate;
        });
        
        
        // Format the selected reservations for the AddActionForm
        const formattedReservations = reservationsOnDate.map(res => ({
          value: res._id,
          label: `${res.reservationId} - ${res.firstName} ${res.lastName} (${formatDate(res.checkIn)} to ${formatDate(res.checkOut)})`,
          reservation: res
        }));
        
        setCreateActionSelectedDate(date);
        setCreateActionSelectedProperty({
          value: {
            _id: property._id,
            name: property.name,
          },
          label: property.name,
          selectedReservations: formattedReservations // Store the selected reservations
        });
        setCreateActionPopUpWindowIsOpen(true);
      } else {

      }
    },
    [
      setCreateActionSelectedDate,
      setCreateActionSelectedProperty,
      setCreateActionPopUpWindowIsOpen,
    ]
  );

  const clickHandler = useSingleAndDoubleClick(
    handleSingleClick,
    handleDoubleClick
  );

  return (
    <tr className={`property-row ${propertyStatus}`}>
      <td className="name-column">
        <div className={`name-cell-wrapper ${propertyStatus}`}>
          {hotel.name}
        </div>
      </td>
      {dates.map((date) => {
        const status = formatCell(date);
        const styleInfo = cellStyle(date); // Get the style information

        return (
          <td
            key={date.toISOString()}
            className={`${status.booked ? "booked" : ""} ${
              styleInfo.className || ""
            }`}
            style={styleInfo}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => onDrop(e, date)}
            onClick={(e) => {
              clickHandler(date, hotel, status.actions, e, status.reservations);
            }}
          >
            {/* Show reservation count if multiple reservations */}
            {status.reservationCount > 1 && (
              <div className="reservation-count">
                {status.reservationCount}
              </div>
            )}
            
            {status.actions && status.actions.length >= 2 && (
              <Tooltip
                title={status.actions.map((action) => action.name).join(", ")}
                placement="top"
                arrow
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundImage: `linear-gradient(to right, ${status.actions
                      .map((action) => action.color || "#3498db")
                      .join(", ")})`,
                    opacity: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: status.actions.some(action => !action.assigned_to || action.assigned_to.length === 0) ? "2px solid red" : "none"
                  }}
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, date, hotel._id)}
                >
                  <span style={{ color: "white" }}>
                    {status.actions.length}
                  </span>
                </div>
              </Tooltip>
            )}

            {status.actions &&
              status.actions.length < 2 &&
              status.actions.map((action) => (
                <Tooltip
                  title={action.name || "Unknown Action"}
                  placement="top"
                  arrow
                  key={action._id}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    key={action._id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, date, hotel._id)}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      backgroundColor: action.color || "#3498db", // Use color with fallback
                      opacity: 0.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      border: (!action.assigned_to || action.assigned_to.length === 0) ? "2px solid red" : "none"
                    }}
                  ></div>
                </Tooltip>
              ))}
          </td>
        );
      })}
    </tr>
  );
}

export default Calendar;