import React, { useState } from "react";
import { TextField, Alert } from "@mui/material";
import { Add } from "@mui/icons-material";
import { PopUpWindow } from "..";

import axios from "axios";

const AddPropertyButton = (props ) => {
  const [propertyName, setPropertyName] = useState("");
  const [loggiaId, setLoggiaId] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClick = () => {
    setPopUpOpen(true);
  };
  const handleAddProperty = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (propertyName === "") {
      return setError("Property name is required.");
    }

    // Add your API call here to add the property
    try {
      const response = await axios.post(
        "https://maliarakis-server.rhodesislandpass.com/property/createProperty",
        {
          title: propertyName,
          loggia_id: loggiaId // Include loggia_id in the request (can be empty)
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Property added successfully.");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Property already exists.");
      } else {
        setError("Failed to add property. Please try again.");
      }
    }

    setPropertyName("");
    setLoggiaId("");
  };

  return (
    <>
      <div className="add-ticket-button"
        style={{
          display: JSON.parse(localStorage.getItem("user")).permissions.canAddProperty? "flex" : "none",
        }}

      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
          }}
          onClick={handleClick}
        >
          <Add />
          <span>Προσθήκη καταλύματος</span>
        </button>
      </div>
      {
        popUpOpen &&
        <PopUpWindow
        onClose={() => {
          setPopUpOpen(false);
          setError("");
          setSuccess("");
        }}
      >
        <div className="add-property-option-wrapper">
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <h4
            style={{
              marginBottom: "0px",

              fontWeight: "600",
            }}
          >
            Add Property
          </h4>
          <form
            onSubmit={handleAddProperty}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <TextField
              variant="outlined"
              label="Property Name"
              placeholder="Property Name"
              value={propertyName}
              onChange={(e) => setPropertyName(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                inputProps: { style: { height: "36px", padding: "5px" } },
              }}
            />
            
            <TextField
              variant="outlined"
              label="Loggia ID (Optional)"
              placeholder="Loggia ID"
              value={loggiaId}
              onChange={(e) => setLoggiaId(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                inputProps: { style: { height: "36px", padding: "5px" } },
              }}
            />

            <button
              className="add-ticket-button__button"
              type="submit"
              style={{
                backgroundColor: "var(--primary-color)",
                color: "var(--primary-text-color)",
                fontSize: "15px",
                fontWeight: "600",
                width: "auto",
                padding: "10px 20px",
              }}
            >
              <Add />
              <span>Add</span>
            </button>
          </form>
        </div>
      </PopUpWindow>}
    </>
  );
};

export default AddPropertyButton;
