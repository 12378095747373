import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import axios from "axios";

import { Delete } from "@mui/icons-material";
import { Tooltip, Alert, Autocomplete, TextField } from "@mui/material";
import { FileUpload } from "@mui/icons-material";

import "./addTicketForm.css";

const CustomOption = (props) => {
  return (
    <Tooltip
      title={props.data.tooltip}
      placement={props.data.tooltipPlacement || "bottom"}
      arrow
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};
function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

const AddTicketForm = (props) => {
  const [ticketData, setTicketData] = useState({
    title: "",
    description: "",
    type: "",
    priority: "",
    status: "",
    group: [],
    assignedTo: [],
    property: props.property || "",
    dueDate: "",
    gonnaHandleAt: "",
    attachments: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [users, setUsers] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]); // Add this line
  const [reservationIds, setReservationIds] = useState([]);
  const [groups, setGroups] = useState([]); // Add this line
  const [statusOptions, setStatusOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const closeWindow = () => {
    props.setShowAddTicketForm(false);
  };
  const user = JSON.parse(localStorage.getItem("user"));

  // Fetching

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
      setUsersOptions(
        response.data.users.map((user) => ({
          value: user,
          label: user.username,
          tooltip: user.name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/group/getGroups",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGroups(response.data.groups);
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const fetchReservationIds = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/reservation/getReservationsIds",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setReservationIds(response.data.reservations);
    } catch (error) {
      console.error("Failed to fetch reservation IDs: ", error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/property/getPropertiesNames",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.propertiesNames);
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/ticket-options/getStatusOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setStatusOptions(response.data.statusOptions);
    } catch (error) {
      console.error("Failed to fetch status options: ", error);
    }
  };

  const fetchPriorityOptions = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/ticket-options/getPriorityOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setPriorityOptions(response.data.priorityOptions);
    } catch (error) {
      console.error("Failed to fetch priority options: ", error);
    }
  };

  const fetchCategoryOptions = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/ticket-options/getCategoryOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setCategoryOptions(response.data.categoryOptions);
    } catch (error) {
      console.error("Failed to fetch category options: ", error);
    }
  };

  const handleGroupChange = (selectedGroups) => {
    const newUsers = selectedGroups.flatMap((group) =>
      group.value.users.map((user) => ({
        value: user,
        label: user.username,
        tooltip: user.name,
      }))
    );

    setTicketData({
      ...ticketData,
      group: selectedGroups,
      assignedTo: [...ticketData.assignedTo, ...newUsers],
    });

    setUsersOptions(
      usersOptions.filter(
        (user) =>
          !selectedGroups
            .flatMap((group) => group.value.users.map((user) => user._id))
            .includes(user.value._id)
      )
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTicketData({ ...ticketData, [name]: value });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files); // Convert FileList to Array
    const fileNames = newFiles.map((file) => file.name);
    const allFiles = [...ticketData.attachments, ...newFiles]; // Combine old and new files
    setTicketData({ ...ticketData, attachments: allFiles });
    setSelectedFileNames([...selectedFileNames, ...fileNames]); // Update state with all file names
  };

  const removeFile = (fileNameToRemove) => {
    // Filter out the file to remove from the attachments array
    const updatedFiles = ticketData.attachments.filter(
      (file) => file.name !== fileNameToRemove
    );

    // Update the attachments in ticketData state
    setTicketData({ ...ticketData, attachments: updatedFiles });

    // Update the selected file names to reflect this removal
    const updatedFileNames = selectedFileNames.filter(
      (fileName) => fileName !== fileNameToRemove
    );
    setSelectedFileNames(updatedFileNames);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (ticketData.title === "") {
      setIsLoading(false);
      setErrorMessage("Title is required.");
      return;
    }

    if (ticketData.description === "") {
      setIsLoading(false);
      setErrorMessage("Description is required.");
      return;
    }

    const dataToSend = {
      title: ticketData.title,
      description: ticketData.description,
      type:
        ticketData.type?.value?._id ||
        categoryOptions.find((category) => category.title === "ΑΛΛΟ")?._id ||
        null,
      priority:
        ticketData.priority?.value?._id ||
        priorityOptions.find((priority) => priority.title === "ΧΑΜΗΛΗ")?._id ||
        null,
      status:
        ticketData.status?.value?._id ||
        statusOptions.find((status) => status.title === "ΑΝΟΙΧΤΟ")?._id ||
        null,
      assignedTo: ticketData.assignedTo?.map((user) => user.value._id) || [],
      group: ticketData.group?.map((group) => group.value._id) || [],
      gonnaHandleAt: ticketData.gonnaHandleAt || "",
      property: ticketData.property?.value?._id || null,
      dueDate: ticketData.dueDate || "",
      attachments: ticketData.attachments || [],
      userId: user.userId,
    };

    try {
      const formData = new FormData();
      formData.append("title", dataToSend.title);
      formData.append("description", dataToSend.description);
      formData.append("type", dataToSend.type);
      formData.append("priority", dataToSend.priority);
      formData.append("status", dataToSend.status);
      formData.append("groupAssignedTo", JSON.stringify(dataToSend.group));
      formData.append("assignedTo", JSON.stringify(dataToSend.assignedTo));
      formData.append("property", dataToSend.property);
      formData.append("dueDate", dataToSend.dueDate);
      formData.append("gonnaHandleAt", dataToSend.gonnaHandleAt);
      formData.append("userId", dataToSend.userId);
      for (let i = 0; i < dataToSend.attachments.length; i++) {
        formData.append("attachments", dataToSend.attachments[i]);
      }

      const response = await axios.post(
        "https://maliarakis-server.rhodesislandpass.com/ticket/createTicket",
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsLoading(false);
      setSuccessMessage("Ticket created successfully!");
      setTimeout(() => {
        closeWindow();
      }, 1500);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(
        error.response?.data?.message || "Failed to create ticket."
      );
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchGroups();
    fetchReservationIds();
    fetchStatusOptions();
    fetchPriorityOptions();
    fetchCategoryOptions();
    fetchProperties();
  }, []);

  return (
    <div className="ticket-form-wrapper">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form className="ticket-form" onSubmit={handleSubmit}>
          {/* Title */}
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="title">
                Τίτλος:
              </label>

              <input
                className="form-field-input"
                type="text"
                id="title"
                name="title"
                value={ticketData.title}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          {/* Description */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="description" className="form-field-label">
                Περιγραφή:
              </label>

              <textarea
                id="description"
                name="description"
                className="form-field-input"
                value={ticketData.description}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          {/* ReservationID || Type */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="property" className="form-field-label">
                Κατάλυμα:
              </label>
              <Select
                id="property"
                name="property"
                value={ticketData.property}
                onChange={(selectedOption) => {
                  console.log(selectedOption);

                  setTicketData({ ...ticketData, property: selectedOption });
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  properties &&
                  properties.map((property) => ({
                    value: property,
                    label: property.name,
                  }))
                } // Add more options as needed
              />
            </div>

            <div className="form-field">
              <label htmlFor="type" className="form-field-label">
                Τύπος:
              </label>

              <Select
                id="type"
                name="type"
                value={ticketData.type}
                onChange={(selectedOption) =>
                  setTicketData({ ...ticketData, type: selectedOption })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  categoryOptions &&
                  categoryOptions.map((category) => ({
                    value: category,
                    label: category.title,
                  }))
                } // Add more options as needed
                isClearable
              />
            </div>
          </div>
          {

            user.permissions.canAssignTickets &&
            <>
              {/* Ticket Due Date || Gonna Handle At */}
              <div className="form-group">
                <div className="form-field">
                  <label htmlFor="dueDate" className="form-field-label">
                    Ημερομηνία Λήξης Ticket:
                  </label>
                  <input
                    id="dueDate"
                    name="dueDate"
                    type="date"
                    min={formatDateData(new Date())}
                    className="form-field-input"
                    value={ticketData.dueDate}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-field">
                  <label htmlFor="gonnaHandleAt" className="form-field-label">
                    Ημερομηνία Επίλυσης:
                  </label>
                  <input
                    id="gonnaHandleAt"
                    name="gonnaHandleAt"
                    type="date"
                    min={formatDateData(new Date())}
                    className="form-field-input"
                    value={ticketData.gonnaHandleAt}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Group || Assign To */}
              <div className="form-group">
                <div className="form-field">
                  <label htmlFor="group" className="form-field-label">
                    Ανάθεση σε Ομάδα:
                  </label>

                  <Select
                    id="group"
                    name="group"
                    value={ticketData.group}
                    onChange={handleGroupChange}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "var(--secondary-color)",
                        primary25: "var(--tertiary-color)",
                      },
                    })}
                    options={
                      groups &&
                      groups.map((group) => ({
                        value: group,
                        label: group.name,
                      }))
                    } // Add more options as needed
                    isMulti
                    isClearable
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="assignTo" className="form-field-label">
                    Ανάθεση σε Χρήστη:
                  </label>

                  <Select
                    id="assignTo"
                    name="assignTo"
                    value={ticketData.assignedTo}
                    onChange={(selectedOption) => {
                      setUsersOptions(
                        users
                          .filter(
                            (user) =>
                              !selectedOption
                                .map((option) => option.value._id)
                                .includes(user._id)
                          )
                          .map((user) => ({
                            value: user,
                            label: user.username,
                            tooltip: user.name,
                          }))
                      );
                      setTicketData({
                        ...ticketData,
                        assignedTo: selectedOption,
                      });
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "var(--secondary-color)",
                        primary25: "var(--tertiary-color)",
                      },
                    })}
                    options={usersOptions} // Add more options as needed
                    isMulti
                    isClearable
                    components={{ Option: CustomOption }}
                  />
                </div>
              </div>
            </>
          }

          {/* Importance || Status*/}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="priority" className="form-field-label">
                Σημαντικότητα:
              </label>

              <Select
                id="priority"
                name="priority"
                value={ticketData.priority}
                onChange={(selectedOption) =>
                  setTicketData({ ...ticketData, priority: selectedOption })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  priorityOptions &&
                  priorityOptions.map((priority) => ({
                    value: priority,
                    label: priority.title,
                  }))
                } // Add more options as needed
                isClearable
              />
            </div>

            <div className="form-field">
              <label htmlFor="status" className="form-field-label">
                Κατάσταση:
              </label>

              <Select
                id="status"
                name="status"
                value={ticketData.status}
                onChange={(selectedOption) =>
                  setTicketData({ ...ticketData, status: selectedOption })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  statusOptions &&
                  statusOptions.map((status) => ({
                    value: status,
                    label: status.title,
                  }))
                } // Add more options as needed
                isClearable
              />
            </div>
          </div>

          {/* Attachments  */}
          <div
            className="form-group"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Tooltip
              title="Upload Files"
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    fontSize: "15px",
                  },
                },
                style: { zIndex: 10001, fontSize: "16px" },
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}
              arrow
              placement="bottom"
            >
              <div className="form-field">
                <label
                  htmlFor="attachments"
                  className="form-field-label custom-file-upload"
                >
                  Συνημμένα Αρχεία:
                  <input
                    type="file"
                    className="form-field-input"
                    id="attachments"
                    name="attachments"
                    multiple
                    onChange={handleFileChange}
                  />
                  <FileUpload
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "40px",
                      cursor: "pointer",
                      alignSelf: "center",
                      pt: "10px",
                      pb: "10px",
                      "&:hover": {
                        color: "var(--secondary-color)",
                      },
                      transition: "color 0.3s",
                    }}
                  />
                </label>
              </div>
            </Tooltip>
            {selectedFileNames.length > 0 && (
              <div className="file-list">
                <p>Επιλεγμένα Αρχεία:</p>
                <ul>
                  {selectedFileNames.map((fileName, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {fileName}
                      <Tooltip
                        title="Remove File"
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "15px",
                            },
                          },
                          style: { zIndex: 10001, fontSize: "16px" },
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        }}
                        arrow
                        placement="right"
                      >
                        <Delete
                          sx={{
                            color: "var(--primary-color)",
                            fontSize: "20px",
                            cursor: "pointer",
                            "&:hover": {
                              color: "var(--secondary-color)",
                            },
                            transition: "color 0.3s",
                            alignSelf: "center",
                            textAlign: "center",

                            padding: "10px",
                          }}
                          onClick={() => removeFile(fileName)}
                        />
                      </Tooltip>
                    </li>
                  ))}
                </ul>
                <p>Συνολικά Αρχεία: {selectedFileNames.length}</p>
              </div>
            )}
          </div>

          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: "10px" }}>
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert severity="success" sx={{ marginBottom: "10px" }}>
              {successMessage}
            </Alert>
          )}

          {/* Submit Button */}

          <div className="form-group">
            <div className="form-field">
              <button type="submit" className="submit-button">
                ΔΗΜΙΟΥΡΓΙΑ
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddTicketForm;
