import React from 'react';
import { Avatar } from "@mui/material";

// Utility function to generate color from name
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const UserChip = ({ user, tasks, estimatedHours, onClick, isActive }) => {
  const formatHours = (hours, userActions) => {
    // If any action has 0 estimated hours, show the '+' format
    const hasZeroEstimatedHours = userActions?.some(action => 
      action.estimated_hours === 0 || !action.estimated_hours
    );
    
    if (hasZeroEstimatedHours) {
      return `~${hours.toFixed(2)}+`;
    }
    
    if (hours === 0) return "0.00";
    return hours.toFixed(2);
  };

  return (
    <div className={`user-chip ${isActive ? "active" : ""}`} onClick={onClick}>
      <Avatar
        style={{
          backgroundColor: `${getColor(user.name)}`,
          width: "30px",
          height: "30px",
          fontSize: "15px",
        }}
      >
        {user.name.charAt(0).toUpperCase()}
      </Avatar>
      <div>
        <p>
          {user.name} (#{tasks} {formatHours(estimatedHours, user.actions)}hrs)
        </p>
      </div>
    </div>
  );
};

export default UserChip;
