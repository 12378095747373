import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Container,
  makeStyles,
  Snackbar,
  IconButton,
  CircularProgress,
  Tooltip,
  Chip,
} from "@mui/material";

import {
  Edit,
  Delete,
  ArrowBack,
  PlayArrow,
  Stop,
  RemoveRedEye,
  ListAlt,
  Info,
  Feed,
  MapsHomeWork,
  Receipt,
} from "@mui/icons-material";

import {
  PopUpWindow,
  AddActionForm,
  ChecklistForm,
  AddTicketForm,
  ViewProperty,
  AddReservationForm,
} from "../../components";
import io from "socket.io-client";

import axios from "axios";

const socket = io("https://maliarakis-server.rhodesislandpass.com/");

const ViewActionsForm = (props) => {
  const [actions, setActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [timerValue, setTimerValue] = useState("");

  const [selectedAction, setSelectedAction] = useState("");
  const [popUpWindowIsOpen, setPopUpWindowIsOpen] = useState(false);
  const [checkListOpen, setCheckListOpen] = useState(false);
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const [addTicketOpen, setAddTicketOpen] = useState(false);
  const [propertyInfoOpen, setPropertyInfoOpen] = useState(false);
  const [reservationInfoOpen, setReservationInfoOpen] = useState(false);
  const [reservationData, setReservationData] = useState({});
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  // Add a new state to track if there's a connected reservation
  const [hasReservation, setHasReservation] = useState(false);
  
  // Add useEffect to check for reservation when an action is selected
  useEffect(() => {
    if (selectedAction && selectedAction.property && selectedAction.property._id) {
      // Check if there's a connected reservation
      checkForReservation(selectedAction.property._id);
    }
  }, [selectedAction]);
  
  // Function to check if there's an active reservation for the property
  const checkForReservation = async (propertyId) => {
    try {
      const res = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/reservation/getAllReservations`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      if (res.data.reservations && res.data.reservations.length > 0) {
        const today = new Date();
        // Filter for this property and check if active
        const propertyReservations = res.data.reservations.filter(
          reservation => {
            const propertyMatch = 
              (reservation.property._id === propertyId) || 
              (typeof reservation.property === 'string' && reservation.property === propertyId);
              
            if (propertyMatch) {
              const isActive = 
                new Date(reservation.checkIn) <= today && 
                new Date(reservation.checkOut) >= today;
              
              return isActive;
            }
            return false;
          }
        );
        
        if (propertyReservations.length > 0) {
          setHasReservation(true);
        } else {
          setHasReservation(false);
        }
      } else {
        setHasReservation(false);
      }
    } catch (err) {
      console.log("Error checking for reservation:", err);
      setHasReservation(false);
    }
  };

  useEffect(() => {
    fetchActions();
  }, []);

  useEffect(() => {
    let interval = null;

    if (selectedAction.start_date && !selectedAction.end_date) {
      // Update the timer value every second
      interval = setInterval(() => {
        const now = new Date();
        const start = new Date(selectedAction.start_date);
        const duration = new Date(now - start);
        const durationString = new Date(duration).toISOString().substr(11, 8);
        setTimerValue(durationString);
      }, 1000); // Update every second
    } else {
      setTimerValue(initializeTimer());
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [selectedAction.start_date, selectedAction.end_date]);

  useEffect(() => {
    const handleActionUpdate = (data) => {
      if (actions.find((action) => action._id === data._id)) {
        setActions(
          actions.map((action) => {
            if (action._id === data._id) {
              return data;
            }
            return action;
          })
        );
      }
    };

    socket.on("actionUpdated", handleActionUpdate);

    return () => {
      socket.off("actionUpdated");
    };
  }, [actions]);

  const initializeTimer = () => {
    if (selectedAction.start_date && selectedAction.end_date) {
      const start = new Date(selectedAction.start_date);
      const end = new Date(selectedAction.end_date);
      const duration = new Date(end - start);
      return new Date(duration).toISOString().substr(11, 8);
    }
    return "00:00:00";
  };

  const fetchActions = async () => {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const nextDay = new Date();
      nextDay.setHours(23, 59, 59, 999);
      nextDay.setDate(nextDay.getDate()+1);
      const res = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/action/getMyUpcomingActions?startDate=${today.toISOString()}&endDate=${nextDay.toISOString()}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setActions(res.data.actions);
      console.log(res.data.actions);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchReservation = async (propertyId) => {
    setLoading(true);
    try {
    
      setReservationData(null);
      setReservationInfoOpen(true);
      setLoading(false);
    } catch (err) {
      console.log("Error fetching reservations:", err);
      setLoading(false);
      // Try an alternative approach if the first one fails
      try {
        console.log("Trying alternative approach with property name");
        // Get the property name from selectedAction
        const propertyName = selectedAction.property.name;
        
        // Fetch all reservations and filter by property name
        const res = await axios.get(
          `https://maliarakis-server.rhodesislandpass.com/reservation/getReservation/`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        
        const propertyReservations = res.data.reservations.filter(
          reservation => 
            (reservation.property.name === propertyName) || 
            (typeof reservation.property === 'string' && 
             reservation.property === propertyName)
        );
        
        console.log("Property reservations by name:", propertyReservations);
        
        if (propertyReservations && propertyReservations.length > 0) {
          const today = new Date();
          const activeReservation = propertyReservations.find(
            reservation => 
              new Date(reservation.checkIn) <= today && 
              new Date(reservation.checkOut) >= today
          );
          
          if (activeReservation) {
            setReservationData(activeReservation);
            setReservationInfoOpen(true);
            return;
          }
        }
        setReservationData(null);
        setReservationInfoOpen(true);
      } catch (secondError) {
        console.log("Second attempt failed:", secondError);
        setReservationData(null);
        setReservationInfoOpen(true);
      }
    }
  };

  return (
    <div className="upcoming-actions-table">
      {popUpWindowIsOpen && !isLoading && (
        <PopUpWindow
          onClose={() => {
            setMoreInfoOpen(false);
            setCheckListOpen(false);
            setAddTicketOpen(false);
            setPropertyInfoOpen(false);
            setReservationInfoOpen(false);
            setPopUpWindowIsOpen(false);
          }}
          unfocusClose={true}
        >
          <div
            className="add-ticket-form"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
            }}
          >
            {!moreInfoOpen &&
              !checkListOpen &&
              !addTicketOpen &&
              !propertyInfoOpen &&
              !reservationInfoOpen && (
                <div className="timer">
                  <div className="start-time">
                    <Typography variant="h6">
                      Start:{" "}
                      {selectedAction.start_date
                        ? new Date(
                            selectedAction.start_date
                          ).toLocaleTimeString()
                        : "Δεν έχει ξεκινήσει"}
                    </Typography>
                  </div>
                  <div className="end-time">
                    <Typography variant="h6">
                      End:{" "}
                      {selectedAction.end_date
                        ? new Date(selectedAction.end_date).toLocaleTimeString()
                        : "Δεν έχει λήξει"}
                    </Typography>
                  </div>

                  <div className="notes">
                    <Typography variant="h6">
                      Notes: {selectedAction.notes || "-"}
                    </Typography>
                  </div>

                  <div className="timer-total">
                    <Typography
                      variant="h3"
                      style={{
                        paddingTop: "20px",
                        display: "flex",
                        gap: "10px",
                        alignSelf: "center",
                        justifySelf: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "var(--primary-color)",
                      }}
                    >
                      {timerValue}
                    </Typography>
                  </div>

                  <div
                    className="timer-buttons"
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignSelf: "center",
                      justifySelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {((!selectedAction.start_date &&
                      (!selectedAction.end_date ||
                        selectedAction.end_date === "")) ||
                      selectedAction.end_date) && (
                      <Button
                        style={{
                          backgroundColor: "var(--primary-color)",
                          color: "var(--tertiary-color)",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          if (!selectedAction.start_date) {
                            axios
                              .put(
                                `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${selectedAction._id}`,
                                {
                                  start_date: new Date(),
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${user.token}`,
                                  },
                                }
                              )
                              .then((res) => {
                                setSelectedAction({
                                  ...selectedAction,
                                  start_date: new Date(),
                                });
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          } else {
                            console.log("stop");
                            axios
                              .put(
                                `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${selectedAction._id}`,
                                {
                                  end_date: "-",
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${user.token}`,
                                  },
                                }
                              )
                              .then((res) => {
                                setSelectedAction({
                                  ...selectedAction,
                                  end_date: "",
                                });
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }
                        }}
                      >
                        <PlayArrow fontSize="large" />
                      </Button>
                    )}

                    {selectedAction.start_date && !selectedAction.end_date && (
                      <Button
                        style={{
                          backgroundColor: "var(--primary-color)",
                          color: "var(--tertiary-color)",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          axios
                            .put(
                              `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${selectedAction._id}`,
                              {
                                end_date: new Date(),
                              },
                              {
                                headers: {
                                  Authorization: `Bearer ${user.token}`,
                                },
                              }
                            )
                            .then((res) => {
                              setSelectedAction({
                                ...selectedAction,
                                end_date: new Date(),
                              });
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      >
                        <Stop fontSize="large" />
                      </Button>
                    )}
                  </div>
                </div>
              )}
            {!moreInfoOpen &&
              !checkListOpen &&
              !addTicketOpen &&
              !propertyInfoOpen &&
              !reservationInfoOpen && (
                <div
                  className="add-ticket-button"
                  style={{
                    display: JSON.parse(localStorage.getItem("user"))
                      .permissions.canCreateTicket
                      ? "flex"
                      : "none",
                  }}
                >
                  <button
                    className="add-ticket-button__button"
                    style={{
                      backgroundColor: props.color || "var(--primary-color)",
                      color: props.textColor || "var(--primary-text-color)",
                      fontSize: props.fontSize || "15px",
                      fontWeight: props.fontWeight || "600",
                      width: props.width || "100%",
                    }}
                    onClick={() => {
                      setAddTicketOpen(true);
                    }}
                  >
                    <Feed />
                    <span>Create Ticket</span>
                  </button>
                </div>
              )}

            {!moreInfoOpen &&
              !checkListOpen &&
              !addTicketOpen &&
              !propertyInfoOpen &&
              !reservationInfoOpen && (
                <div
                  className="add-ticket-button"
                  style={{
                    display: JSON.parse(localStorage.getItem("user"))
                      .permissions.canCreateTicket
                      ? "flex"
                      : "none",
                  }}
                >
                  <button
                    className="add-ticket-button__button"
                    style={{
                      backgroundColor: props.color || "var(--primary-color)",
                      color: props.textColor || "var(--primary-text-color)",
                      fontSize: props.fontSize || "15px",
                      fontWeight: props.fontWeight || "600",
                      width: props.width || "100%",
                    }}
                    onClick={() => {
                      setCheckListOpen(true);
                    }}
                  >
                    <ListAlt />
                    <span>Check List</span>
                  </button>
                </div>
              )}
            {!moreInfoOpen &&
              !checkListOpen &&
              !addTicketOpen &&
              !propertyInfoOpen &&
              !reservationInfoOpen && (
                <div
                  className="add-ticket-button"
                  style={{
                    display: JSON.parse(localStorage.getItem("user"))
                      .permissions.canCreateTicket
                      ? "flex"
                      : "none",
                  }}
                >
                  <button
                    className="add-ticket-button__button"
                    style={{
                      backgroundColor: props.color || "var(--primary-color)",
                      color: props.textColor || "var(--primary-text-color)",
                      fontSize: props.fontSize || "15px",
                      fontWeight: props.fontWeight || "600",
                      width: props.width || "100%",
                    }}
                    onClick={() => {
                      setMoreInfoOpen(true);
                    }}
                  >
                    <Info />
                    <span>More Info</span>
                  </button>
                </div>
              )}
            {!moreInfoOpen &&
              !checkListOpen &&
              !addTicketOpen &&
              !propertyInfoOpen &&
              !reservationInfoOpen && (
                <div
                  className="add-ticket-button"
                  style={{
                    display: "flex"
                  }}
                >
                  <button
                    className="add-ticket-button__button"
                    style={{
                      backgroundColor: props.color || "var(--primary-color)",
                      color: props.textColor || "var(--primary-text-color)",
                      fontSize: props.fontSize || "15px",
                      fontWeight: props.fontWeight || "600",
                      width: props.width || "100%",
                    }}
                    onClick={() => {
                      setPropertyInfoOpen(true);
                    }}
                  >
                    <MapsHomeWork />
                    <span>Property Info</span>
                  </button>
                </div>
              )}
            {moreInfoOpen && (
              <div display="flex" flexDirection="column" gap="20px">
                <AddActionForm
                  actionId={selectedAction._id}
                  group={
                    selectedAction.group && {
                      value: {
                        _id: selectedAction.group._id,
                        name: selectedAction.group.name,
                      },
                      label: selectedAction.group.name,
                    }
                  }
                  action_option={{
                    value: {
                      _id: selectedAction.action_option._id,
                      name: selectedAction.action_option.name,
                    },
                    label: selectedAction.action_option.name,
                  }}
                  property={{
                    value: {
                      _id: selectedAction.property._id,
                      name: selectedAction.property.name,
                    },
                    label: selectedAction.property.name,
                  }}
                  assigned_to={selectedAction.assigned_to.map((user) => {
                    return {
                      label: user.username,
                      value: {
                        _id: user._id,
                        username: user.username,
                      },
                    };
                  })}
                  date={new Date(selectedAction.planned_start_date)}
                  setPopUpWindowIsOpen={setPopUpWindowIsOpen}
                  edit={false}
                  view={true}
                  price={selectedAction.price}
                  notes={selectedAction.notes}
                />
              </div>
            )}
            {checkListOpen && (
              <div style={{ width: "100%" }}>
                <ChecklistForm actionId={selectedAction._id} />
              </div>
            )}
            {propertyInfoOpen && (
              <div style={{ width: "100%" }}>
                <ViewProperty propertyId={selectedAction.property._id} />
              </div>
            )}
            {addTicketOpen && (
              <div style={{ width: "100%" }}>
                <AddTicketForm
                  setShowAddTicketForm={setAddTicketOpen}
                  property={{
                    value: {
                      _id: selectedAction.property._id,
                      name: selectedAction.property.name,
                    },
                    label: selectedAction.property.name,
                  }}
                />
              </div>
            )}
            {(moreInfoOpen ||
              checkListOpen ||
              addTicketOpen ||
              propertyInfoOpen ||
              reservationInfoOpen) && (
              <div
                className="add-ticket-button"
                style={{
                  display: JSON.parse(localStorage.getItem("user")).permissions
                    .canCreateTicket
                    ? "flex"
                    : "none",
                }}
              >
                <button
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: props.color || "var(--primary-color)",
                    color: props.textColor || "var(--primary-text-color)",
                    fontSize: props.fontSize || "15px",
                    fontWeight: props.fontWeight || "600",
                    width: props.width || "100%",
                  }}
                  onClick={() => {
                    setMoreInfoOpen(false);
                    setCheckListOpen(false);
                    setAddTicketOpen(false);
                    setPropertyInfoOpen(false);
                    setReservationInfoOpen(false);
                  }}
                >
                  <span>Return</span>
                </button>
              </div>
            )}
          </div>
        </PopUpWindow>
      )}

      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxheight: 540,
            overflow: "auto",
            overflowX: "scroll",
            width: "100%",
            paddingRight: "20px",
          }}
        >
          <Table
            aria-label="simple table"
            stickyHeader
            sx={{
              padding: "10px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Property</TableCell>
                <TableCell>Date</TableCell>

                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {actions.map((action) => (
                <TableRow key={action._id}>
                  <TableCell sx={{ padding: "0px" }}>
                    <Tooltip title="Προβολή" placement="right">
                      <Button
                        onClick={() => {
                          setSelectedAction(action);
                          setPopUpWindowIsOpen(true);
                        }}
                      >
                        <RemoveRedEye sx={{ color: "var(--primary-color)" }} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{action.action_option.name}</TableCell>
                  <TableCell>{action.property.name}</TableCell>
                  <TableCell>
                    {new Date(action.planned_start_date).toLocaleDateString()}
                  </TableCell>

                  <TableCell align="left">
                    <Chip
                      label={action.end_date ? "Ολοκληρώθηκε" : "Εκκρεμεί"}
                      color={action.end_date ? "success" : "warning"}
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ViewActionsForm;
