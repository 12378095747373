import React, { useEffect } from "react";
import { PageStructure } from "../../pages";
import { AddActionButton, CalendarView } from "../../components";

const Calendar = (props) => {
  return (
    <PageStructure>

      <AddActionButton/>
      <CalendarView />
    </PageStructure>
  );
};

export default Calendar;
