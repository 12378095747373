import React, { useState, useEffect } from "react";
import { TextField, Alert } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Select from "react-select";
import axios from "axios";

const EditPropertyForm = ({ property, actionOptions }) => {
  const [propertyName, setPropertyName] = useState(property.name);
  const [persons, setPersons] = useState(property.persons);
  const [loggiaId, setLoggiaId] = useState(property.loggia_id || "");
  const [masterlock, setMasterlock] = useState(property.masterlock || "");
  const [notes, setNotes] = useState(property.notes || ""); 
  const [privateNotes, setPrivateNotes] = useState(property.private_notes || "");
  const [checkin, setCheckin] = useState(property.checkin);
  const [checkout, setCheckout] = useState(property.checkout);
  // Add state for latitude and longitude
  const [lat, setLat] = useState(property.lat || "");
  const [lng, setLng] = useState(property.lng || "");
  const [actionsPrices, setActionsPrices] = useState(property.actions_prices);
  const [actionsAnalytics, setActionsAnalytics] = useState(property.actions_analytics || []);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    property.location_id ? { value: property.location_id, label: property.location_id.name } : null
  );
  
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );
  console.log(property);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    // Fetch location options
    const fetchLocationOptions = async () => {
      try {
        const response = await axios.get(
          "https://maliarakis-server.rhodesislandpass.com/location-option/getLocationOptions",
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setLocationOptions(response.data.locationOptions.map(location => ({
          value: location._id,
          label: location.name
        })));
        
        // Set the initial selected location if property has one
        if (property.location_id) {
          const currentLocation = response.data.locationOptions.find(
            loc => loc._id === property.location_id
          );
          if (currentLocation) {
            setSelectedLocation({
              value: currentLocation._id,
              label: currentLocation.name
            });
          }
        }
      } catch (error) {
        console.error("Error fetching location options:", error);
      }
    };

    fetchLocationOptions();
  }, [property._id]); // Changed dependency to property._id for better re-rendering

  // Add helper functions for calculations
  const calculateAvgTime = (hours, visits) => {
    return visits > 0 ? Number((hours / visits).toFixed(2)) : 0;
  };

  const calculateHours = (avgTime, visits) => {
    return Number((avgTime * visits).toFixed(2));
  };

  // Modified onChange handlers for analytics inputs
  const handleAnalyticsChange = (actionOptionId, field, value) => {
    const newActionsAnalytics = [...actionsAnalytics];
    const analyticsIndex = newActionsAnalytics.findIndex(
      (analytics) => analytics._id === actionOptionId
    );

    let newAnalytics;
    if (analyticsIndex === -1) {
      newAnalytics = {
        _id: actionOptionId,
        hours: 0,
        visits: 0,
        avgTime: 0,
      };
      newActionsAnalytics.push(newAnalytics);
    } else {
      newAnalytics = newActionsAnalytics[analyticsIndex];
    }

    // Update the changed field and recalculate related fields
    switch (field) {
      case 'hours':
        newAnalytics.hours = Number(value);
        newAnalytics.avgTime = calculateAvgTime(Number(value), newAnalytics.visits);
        break;
      case 'visits':
        newAnalytics.visits = Number(value);
        newAnalytics.avgTime = calculateAvgTime(newAnalytics.hours, Number(value));
        break;
      case 'avgTime':
        newAnalytics.avgTime = Number(value);
        newAnalytics.hours = calculateHours(Number(value), newAnalytics.visits);
        break;
    }

    setActionsAnalytics(newActionsAnalytics);
  };

  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
  };

  const handleEditProperty = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (propertyName === "") {
      return setError("Property name is required.");
    }

    // Prepare request payload
    const requestData = {
      title: propertyName,
      persons: persons,
      checkin: checkin,
      checkout: checkout,
      actions_prices: actionsPrices,
      actions_analytics: actionsAnalytics,
      loggia_id: loggiaId,
      masterlock: masterlock,
      notes: notes,
      private_notes: privateNotes,
      // Add lat and lng to request data
      lat: lat ? parseFloat(lat) : null,
      lng: lng ? parseFloat(lng) : null
    };

    // Only include location_id if a location is selected
    if (selectedLocation) {
      requestData.location_id = selectedLocation.value;
    } else {
      requestData.location_id = null; // Explicitly set to null if no location selected
    }

    console.log("Sending update with data:", requestData); // Debug log

    // Edit your API call here to edit the property
    try {
      const response = await axios.put(
        `https://maliarakis-server.rhodesislandpass.com/property/updateProperty/${property._id}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      console.log("Update response:", response.data); // Debug log
      setSuccess("Κατάλυμα επεξεργάστηκε επιτυχώς.");
    } catch (error) {
      console.error("Update error:", error); // Debug log
      if (error.response && error.response.status === 409) {
        setError("Κατάλυμα υπάρχει ήδη.");
      } else {
        setError(
          "Αποτυχία επεξεργασίας καταλύματος. Παρακαλώ προσπαθήστε ξανά."
        );
      }
    }
  };

  return (
    <>
      <div className="ticket-form-wrapper">
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}

        <form className="ticket-form" onSubmit={handleEditProperty}>
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="name">
                Όνομα Καταλύματος
              </label>
              <input
                id="name"
                type="text"
                className="form-field-input"
                value={propertyName}
                onChange={(e) => setPropertyName(e.target.value)}
              />
            </div>

            <div className="form-field">
              <label className="form-field-label" htmlFor="loggia_id">
                Loggia ID
              </label>
              <input
                id="loggia_id"
                type="text"
                className="form-field-input"
                value={loggiaId}
                onChange={(e) => setLoggiaId(e.target.value)}
                placeholder="Auto-generated if left empty"
              />
            </div>
          </div>

          {/* Add latitude and longitude fields */}
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="lat">
                Latitude
              </label>
              <input
                id="lat"
                type="text"
                className="form-field-input"
                value={lat}
                onChange={(e) => setLat(e.target.value)}
                placeholder="Enter latitude (e.g., 37.9838)"
              />
            </div>

            <div className="form-field">
              <label className="form-field-label" htmlFor="lng">
                Longitude
              </label>
              <input
                id="lng"
                type="text"
                className="form-field-input"
                value={lng}
                onChange={(e) => setLng(e.target.value)}
                placeholder="Enter longitude (e.g., 23.7275)"
              />
            </div>
          </div>

          {/* Add the masterlock field */}
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="masterlock">
                Masterlock
              </label>
              <input
                id="masterlock"
                type="text"
                className="form-field-input"
                value={masterlock}
                onChange={(e) => setMasterlock(e.target.value)}
                placeholder="Enter masterlock code"
              />
            </div>
          </div>

          {/* Add notes field */}
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="notes">
                Notes
              </label>
              <textarea
                id="notes"
                className="form-field-input"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Enter public notes about this property"
                rows={3}
              />
            </div>
          </div>

          {/* Add private notes field */}
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="privateNotes">
                Private Notes
              </label>
              <textarea
                id="privateNotes"
                className="form-field-input"
                value={privateNotes}
                onChange={(e) => setPrivateNotes(e.target.value)}
                placeholder="Enter private/internal notes about this property"
                rows={3}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="persons">
                Αριθμός Ατόμων
              </label>
              <input
                id="persons"
                type="number"
                className="form-field-input"
                value={persons}
                onChange={(e) => setPersons(e.target.value)}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="checkin">
                Checkin
              </label>
              <input
                id="checkin"
                type="text"
                className="form-field-input"
                value={checkin}
                onChange={(e) => setCheckin(e.target.value)}
              />
            </div>

            <div className="form-field">
              <label className="form-field-label" htmlFor="checkout">
                Checkout
              </label>
              <input
                id="checkout"
                type="text"
                className="form-field-input"
                value={checkout}
                onChange={(e) => setCheckout(e.target.value)}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="location">
                Τοποθεσία
              </label>
              <Select
                id="location"
                value={selectedLocation}
                onChange={handleLocationChange}
                options={locationOptions}
                isClearable
                placeholder="Επιλέξτε τοποθεσία..."
                className="react-select-container"
                classNamePrefix="react-select"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    minHeight: '38px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
              />
            </div>
          </div>
          <hr />
          {actionOptions?.map((actionOption, index) => (
            <div key={index} className="form-group-wrapper"> 
              <div className="form-group-title">
                <h3>{actionOption.name}</h3>
                <hr />
              </div>
              <div className="form-group" key={index}>

                <div className="form-field">
                  <label
                    className="form-field-label"
                    htmlFor={`action-${index}`}
                  >
                    Τιμή
                  </label>

                  <input
                    id={`action-${index}`}
                    type="number"
                    step={0.01}
                    className="form-field-input"
                    value={
                      actionsPrices.find(
                        (actionPrice) => actionPrice._id === actionOption._id
                      )
                        ? actionsPrices.find(
                            (actionPrice) =>
                              actionPrice._id === actionOption._id
                          ).price
                        : null
                    }
                    onChange={(e) => {
                      const newActionsPrices = [...actionsPrices];
                      const actionPriceIndex = newActionsPrices.findIndex(
                        (actionPrice) => actionPrice._id === actionOption._id
                      );

                      if (actionPriceIndex === -1) {
                        newActionsPrices.push({
                          _id: actionOption._id,
                          price: e.target.value,
                        });
                      } else {
                        newActionsPrices[actionPriceIndex].price =
                          e.target.value;
                      }

                      setActionsPrices(newActionsPrices);
                    }}
                  />
                </div>

                {/* New analytics inputs */}
                <div className="form-field">
                  <label className="form-field-label" htmlFor={`hours-${index}`}>
                    Ώρες
                  </label>
                  <input
                    id={`hours-${index}`}
                    type="number"
                    className="form-field-input"
                    value={
                      actionsAnalytics.find(
                        (analytics) => analytics._id === actionOption._id
                      )?.hours || 0
                    }
                    onChange={(e) => handleAnalyticsChange(actionOption._id, 'hours', e.target.value)}
                  />
                </div>

                <div className="form-field">
                  <label className="form-field-label" htmlFor={`visits-${index}`}>
                    Επισκέψεις
                  </label>
                  <input
                    id={`visits-${index}`}
                    type="number"
                    className="form-field-input"
                    value={
                      actionsAnalytics.find(
                        (analytics) => analytics._id === actionOption._id
                      )?.visits || 0
                    }
                    onChange={(e) => handleAnalyticsChange(actionOption._id, 'visits', e.target.value)}
                  />
                </div>

                <div className="form-field">
                  <label className="form-field-label" htmlFor={`avgTime-${index}`}>
                    Μέσος Χρόνος
                  </label>
                  <input
                    id={`avgTime-${index}`}
                    type="number"
                    className="form-field-input"
                    value={
                      actionsAnalytics.find(
                        (analytics) => analytics._id === actionOption._id
                      )?.avgTime || 0
                    }
                    onChange={(e) => handleAnalyticsChange(actionOption._id, 'avgTime', e.target.value)}
                  />
                </div>
              </div>
            </div>
          ))}

          <div className="form-group">
            <div className="form-field">
              <button type="submit" className="submit-button">
                <Edit />
                <span>Επεξεργασία Καταλύματος</span>
              </button>
            </div>
          </div>
             {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        </form>
      </div>
    </>
  );
};

export default EditPropertyForm;
