import React from 'react';
import CalendarRow from './CalendarRow';

function CalendarTable({
  data,
  dates,
  actions,
  handleDrop,
  setCreateActionPopUpWindowIsOpen,
  setCreateActionSelectedDate,
  setCreateActionSelectedProperty,
  setViewActionsPopUpWindowIsOpen,
  setViewActionsSelectedDate,
  setViewActionsSelectedActions,
  handleDateCellClick,
  searchTerm,
  filterProperties,
  selectedDateFilter,
  handleDateColumnClick,
}) {
  return (
    <div className="calendar-table-wrapper">
      <table>
        <thead>
          <tr>
            <th className="name-column">Όνομα</th>
            {dates.map((date) => (
              <th 
                key={date.toISOString()} 
                onClick={() => handleDateColumnClick(date)}
                className={selectedDateFilter?.getTime() === date.getTime() ? 'selected-date' : ''}
                style={{ cursor: 'pointer' }}
              >
                {date.toDateString()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filterProperties(data)
            .filter((hotel) => hotel.name.toLowerCase().includes(searchTerm))
            .map((hotel) => (
              <CalendarRow
                key={hotel._id}
                hotel={hotel}
                dates={dates}
                actions={actions}
                handleDrop={handleDrop}
                setCreateActionPopUpWindowIsOpen={
                  setCreateActionPopUpWindowIsOpen
                }
                setCreateActionSelectedDate={setCreateActionSelectedDate}
                setCreateActionSelectedProperty={
                  setCreateActionSelectedProperty
                }
                setViewActionsPopUpWindowIsOpen={
                  setViewActionsPopUpWindowIsOpen
                }
                setViewActionsSelectedDate={setViewActionsSelectedDate}
                setViewActionsSelectedActions={
                  setViewActionsSelectedActions
                }
                handleDateCellClick={handleDateCellClick}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default CalendarTable;
