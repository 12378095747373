import React, { useCallback, useEffect, useRef, useState } from 'react';
import CalendarCell from './CalendarCell';
import { Tooltip } from "@mui/material";
import { getPropertyStatus } from './CalendarHelpers';
import { useDragDrop } from '../../hooks/useDragDrop';

// Add this formatDate function at the top, after the other utility functions
function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function Row({
  hotel,
  dates,
  actions,
  handleDrop,
  setCreateActionPopUpWindowIsOpen,
  setCreateActionSelectedDate,
  setCreateActionSelectedProperty,
  setViewActionsPopUpWindowIsOpen,
  setViewActionsSelectedDate,
  setViewActionsSelectedActions,
  handleDateCellClick,
}) {
  // Get the property status based on its reservations and actions
  const propertyStatus = getPropertyStatus(hotel, actions, dates);
  
  const [initialReservations, setInitialReservations] = useState([]);

  // Use the drag and drop hook
  const {
    handleDragStart,
    handleDragOver,
    handleDragLeave,
    handleDrop: handleCellDrop,
  } = useDragDrop({
    onSuccess: (result) => {
      // Call the parent's handleDrop method
      if (handleDrop) {
        handleDrop(
          result.sourcePropertyId, 
          result.targetPropertyId, 
          result.oldDate, 
          result.newDate, 
          result.action
        );
      }
    }
  });

  const onDrop = (event, targetDate) => {
    event.preventDefault();
    
    try {
      // Parse the drag data
      const dragData = JSON.parse(event.dataTransfer.getData("application/json"));
      
      if (!dragData) return;
      
      const sourceHotelId = dragData.sourceHotelId;
      const oldDate = new Date(dragData.date);
      const action = dragData.action;
      
      // Call handleCellDrop from the hook
      handleCellDrop(event, targetDate, hotel._id);
    } catch (error) {
      console.error("Error handling drop:", error);
    }
    
    // Remove the dragging class from any elements
    const draggingElements = document.querySelectorAll('.dragging');
    draggingElements.forEach(el => el.classList.remove('dragging'));
  };

  const cellStyle = (date) => {
    const isStartDate = hotel.reservations.some(
      (reservation) =>
        `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` ===
        `${reservation.startDate.getDate()}/${
          reservation.startDate.getMonth() + 1
        }/${reservation.startDate.getFullYear()}`
    );
    const isEndDate = hotel.reservations.some(
      (reservation) =>
        `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` ===
        `${reservation.endDate.getDate()}/${
          reservation.endDate.getMonth() + 1
        }/${reservation.endDate.getFullYear()}`
    );

    const colliding = hotel.reservations.some(
      (reservation) =>
        date.getTime() === reservation.startDate.getTime() &&
        hotel.reservations.some((r) => date.getTime() === r.endDate.getTime())
    );

    if (colliding) {
      return { className: "colliding" }; // Apply colliding class if start and end date are the same and ensure color property is included
    } else if (isStartDate) {
      return { borderRadius: "50px 0 0 0", position: "relative" };
    } else if (isEndDate) {
      return { borderRadius: "0 0 50px 0", position: "relative" };
    } else {
      return { position: "relative" };
    }
  };
  function useSingleAndDoubleClick(
    actionSimpleClick,
    actionDoubleClick,
    delay = 250
  ) {
    const timeoutRef = useRef(null);
    const clickCountRef = useRef(0);

    const eventHandler = useCallback(
      (date, hotel, actions, event, reservationsOnDate) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        clickCountRef.current += 1;

        timeoutRef.current = setTimeout(() => {
          if (clickCountRef.current === 1) {
            actionSimpleClick(date, hotel, actions, event, reservationsOnDate);
          } else if (clickCountRef.current > 1) {
            actionDoubleClick(date, hotel);
          }
          clickCountRef.current = 0;
        }, delay);
      },
      [actionSimpleClick, actionDoubleClick, delay]
    );

    useEffect(() => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    return eventHandler;
  }
  // Enhance formatCell to return all reservations for this date
  const formatCell = (date) => {
    // Find reservations for this date - no changes here, just ensuring we get all reservations
    const reservationsOnDate = hotel.reservations.filter(reservation => {
      // Normalize dates by removing time portion
      const cellDate = new Date(
        date.getFullYear(), 
        date.getMonth(), 
        date.getDate()
      ).setHours(0, 0, 0, 0);
      const startDate = new Date(
        reservation.startDate.getFullYear(),
        reservation.startDate.getMonth(), 
        reservation.startDate.getDate()
      ).setHours(0, 0, 0, 0);
      const endDate = new Date(
        reservation.endDate.getFullYear(), 
        reservation.endDate.getMonth(), 
        reservation.endDate.getDate()
      ).setHours(0, 0, 0, 0);

      return cellDate >= startDate && cellDate <= endDate;
    });

    const isActive = reservationsOnDate.length > 0;

    // Find actions for this date
    const actionsOnDate = hotel.actions.filter(action => {
      const actionDate = new Date(action.date);
      return (
        actionDate.getDate() === date.getDate() &&
        actionDate.getMonth() === date.getMonth() &&
        actionDate.getFullYear() === date.getFullYear()
      );
    }).map(action => ({ 
      ...action,
      // Ensure name is available for tooltips
      name: action.action_option?.name || action.name || "Unknown Action",
      color: action.color || "#3498db" // Ensure color is available
    }));

    return {
      booked: isActive,
      actions: actionsOnDate,
      reservations: reservationsOnDate,
      reservationCount: reservationsOnDate.length // Add count for easy access
    };
  };

  const handleSingleClick = useCallback((date, property, actions, event, reservationsOnDate) => {
    if (property) {
      handleDateCellClick(date, property, actions, event, reservationsOnDate);
    } else {
    }
  }, [handleDateCellClick]);

  const handleDoubleClick = useCallback(
    (date, property) => {
      
      if (property) {
        // Find all reservations that overlap with the selected date
        const reservationsOnDate = property.reservations.filter(reservation => {
          const cellDate = new Date(
            date.getFullYear(), 
            date.getMonth(), 
            date.getDate()
          ).setHours(0, 0, 0, 0);
          
          const startDate = new Date(
            reservation.startDate.getFullYear(),
            reservation.startDate.getMonth(), 
            reservation.startDate.getDate()
          ).setHours(0, 0, 0, 0);
          
          const endDate = new Date(
            reservation.endDate.getFullYear(), 
            reservation.endDate.getMonth(), 
            reservation.endDate.getDate()
          ).setHours(0, 0, 0, 0);

          return cellDate >= startDate && cellDate <= endDate;
        });
        
        
        // Format the selected reservations for the AddActionForm
        const formattedReservations = reservationsOnDate.map(res => ({
          value: res._id,
          label: `${res.reservationId} - ${res.firstName} ${res.lastName} (${formatDate(res.checkIn)} to ${formatDate(res.checkOut)})`,
          reservation: res
        }));
        
        setCreateActionSelectedDate(date);
        setCreateActionSelectedProperty({
          value: {
            _id: property._id,
            name: property.name,
          },
          label: property.name,
          selectedReservations: formattedReservations // Store the selected reservations
        });
        setCreateActionPopUpWindowIsOpen(true);
      } else {

      }
    },
    [
      setCreateActionSelectedDate,
      setCreateActionSelectedProperty,
      setCreateActionPopUpWindowIsOpen,
    ]
  );

  const clickHandler = useSingleAndDoubleClick(
    handleSingleClick,
    handleDoubleClick
  );

  return (
    <tr className={`property-row ${propertyStatus}`}>
      <td className="name-column">
        <div className={`name-cell-wrapper ${propertyStatus}`}>
          {hotel.name}
        </div>
      </td>
      {dates.map((date) => {
        const status = formatCell(date);
        const styleInfo = cellStyle(date);

        return (
          <CalendarCell
            key={date.toISOString()}
            date={date}
            hotel={hotel}
            status={status}
            styleInfo={styleInfo}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={onDrop}
            handleDragStart={handleDragStart}
            clickHandler={clickHandler}
          />
        );
      })}
    </tr>
  );
}

export default Row;
