import React from 'react';
import { Tooltip } from "@mui/material";
import { prepareDragData } from './CalendarHelpers';

function CalendarCell({
  date,
  hotel,
  status,
  styleInfo,
  onDragOver,
  onDragLeave,
  onDrop,
  handleDragStart,
  clickHandler,
}) {
  // Determine if this cell has multiple reservations (collision)
  const hasMultipleReservations = status.reservationCount > 1;
  const collisionClass = hasMultipleReservations ? "colliding" : "";
  
  // Check if any actions are unassigned
  const hasUnassignedActions = status.actions && 
    status.actions.some(action => !action.assigned_to || action.assigned_to.length === 0);

  // Check if any actions are pending (no action_option)
  const hasPendingActions = status.actions &&
    status.actions.some(action => !action.action_option);

  // Count pending actions
  const pendingActionsCount = status.actions ? 
    status.actions.filter(action => !action.action_option).length : 0;

  // Extract regular and pending actions for separate handling
  const regularActions = status.actions ? 
    status.actions.filter(action => action.action_option) : [];
  
  const pendingActions = status.actions ?
    status.actions.filter(action => !action.action_option) : [];

  // Handle drag start with awareness of pending vs regular actions
  const onDragStartHandler = (e, date, hotelId, action) => {
    // Use the helper function to prepare drag data
    const dragData = prepareDragData(action, date, hotelId);
    
    // Set the drag data
    e.dataTransfer.setData("application/json", JSON.stringify(dragData));
    
    // Add class for visual feedback during drag
    e.currentTarget.classList.add('dragging');
    
    // Add pending class if needed
    if (!action?.action_option) {
      e.currentTarget.classList.add('dragging-pending');
    }
    
    // Call the parent handler if provided
    if (handleDragStart) {
      handleDragStart(e, date, hotelId, action);
    }
  };

  return (
    <td
      key={date.toISOString()}
      className={`${status.booked ? "booked" : ""} ${styleInfo.className || ""} ${collisionClass} ${hasPendingActions ? 'has-pending' : ''} calendar-cell`} 
      style={styleInfo}
      onDragOver={(e) => onDragOver(e)}
      onDragLeave={(e) => onDragLeave && onDragLeave(e)}
      onDrop={(e) => onDrop(e, date, hotel._id)}
      onClick={(e) => {
        clickHandler(date, hotel, status.actions, e, status.reservations);
      }}
    >
      {/* Show reservation count if multiple reservations */}
      {status.reservationCount > 1 && (
        <div className="reservation-count">
          {status.reservationCount}
        </div>
      )}
      
      {/* Place action count outside of the action div for full opacity */}
      {status.actions && status.actions.length >= 2 && (
        <div className="actions-count-wrapper">
          <div className={`actions-count ${hasPendingActions ? 'pending' : ''}`}>
            {status.actions.length}
            {pendingActionsCount > 0 && ` (${pendingActionsCount}?)`}
          </div>
        </div>
      )}
      
      {/* Single action styling for both regular and pending actions */}
      {status.actions &&
        status.actions.length < 2 &&
        status.actions.map((action) => (
          <Tooltip
            title={action.action_option ? (action.name || "Unknown Action") : "Pending Action (No Type Selected)"}
            placement="top"
            arrow
            key={action._id}
            style={{ cursor: "pointer" }}
          >
            <div
              key={action._id}
              className={`cell-action single-action ${(!action.assigned_to || action.assigned_to.length === 0) ? 'unassigned' : ''} ${!action.action_option ? 'pending' : ''}`}
              draggable="true"
              onDragStart={(e) => onDragStartHandler(e, date, hotel._id, action)}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: !action.action_option ? 'transparent' : (action.color || "#3498db"),
                opacity: 0.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
            >
              {!action.action_option && (
                <div className="pending-icon">?</div>
              )}
            </div>
          </Tooltip>
        ))}
      
      {/* Multiple actions styling with special handling for pending */}
      {status.actions && status.actions.length >= 2 && (
        <Tooltip
          title={status.actions.map((action) => 
            action.action_option ? (action.name || "Unknown Action") : "Pending Action (No Type Selected)"
          ).join(", ")}
          placement="top"
          arrow
        >
          <div
            className={`cell-action multiple-actions ${hasUnassignedActions ? 'unassigned' : ''} ${hasPendingActions ? 'pending' : ''}`}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage: hasPendingActions ? 'none' : `linear-gradient(to right, ${status.actions
                .filter(action => action.action_option) // Only include actions with action_option
                .map((action) => action.color || "#3498db")
                .join(", ")})`,
              opacity: 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1
            }}
            draggable="true"
            onDragStart={(e) => onDragStartHandler(e, date, hotel._id, status.actions[0])} // Pass first action for multiple
          >
            {/* Action count is outside of this div */}
            {hasPendingActions && (
              <div className="pending-icon">?</div>
            )}
          </div>
        </Tooltip>
      )}
        
      {/* Add a turnover indicator if there's a collision */}
      {status.reservationCount > 1 && (
        <div className="turnover-indicator">
          <div className="turnover-arrow"></div>
        </div>
      )}
    </td>
  );
}

export default CalendarCell;
