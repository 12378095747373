import React, { useState, useEffect } from "react";
import axios from "axios";
import { DragIndicator, Save } from "@mui/icons-material";
import { TextField, Button, IconButton, Paper, Alert } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./PropertyOrder.css";

const PropertyOrder = () => {
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [changedProperties, setChangedProperties] = useState(new Map());
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchProperties();
  }, []);

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/property/getPropertiesNames",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      // Ensure each property has an order value based on its index
      const propertiesWithOrder = response.data.propertiesNames.map(
        (property, index) => ({
          ...property,
          order: property.order || index + 1,
        })
      );
      setProperties(propertiesWithOrder);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleOrderChange = (propertyId, newOrder) => {
    const parsedOrder = parseInt(newOrder) || 0;
    setProperties((prevProperties) =>
      prevProperties.map((property) =>
        property._id === propertyId
          ? { ...property, order: parsedOrder }
          : property
      )
    );
    setChangedProperties((prev) => new Map(prev).set(propertyId, parsedOrder));
    setHasChanges(true);
  };

  const handleOrderBlur = () => {
    const sortedProperties = [...properties].sort((a, b) => a.order - b.order);
    const reorderedProperties = sortedProperties.map((property, index) => {
      const newOrder = index + 1;
      if (property.order !== newOrder) {
        setChangedProperties((prev) =>
          new Map(prev).set(property._id, newOrder)
        );
      }
      return { ...property, order: newOrder };
    });
    setProperties(reorderedProperties);
    setHasChanges(true);
  };

  const handleSave = async () => {
    try {
      setError(null);
      setSuccess(null);

      const updatePromises = Array.from(changedProperties.entries()).map(
        ([propertyId, newOrder]) =>
          axios.put(
            `https://maliarakis-server.rhodesislandpass.com/property/changePropertyOrder/${propertyId}`,
            { newOrder },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          )
      );

      await Promise.all(updatePromises);
      setChangedProperties(new Map());
      setHasChanges(false);
      await fetchProperties();
      setSuccess("Property orders updated successfully");
    } catch (error) {
      console.error("Failed to save order changes:", error);
      setError("Failed to update property orders. Please try again.");
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(properties);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => {
      const newOrder = index + 1;
      if (item.order !== newOrder) {
        setChangedProperties((prev) => new Map(prev).set(item._id, newOrder));
      }
      return { ...item, order: newOrder };
    });

    setProperties(updatedItems);
    setHasChanges(true);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="property-order-container">
      <div className="top-container">
        <Button
          variant="contained"
          startIcon={<Save />}
          onClick={handleSave}
          className="save-button"
          disabled={!hasChanges}
        >
          Save Changes
        </Button>
      </div>

      <div className="alerts-container">
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      </div>

      <div className="scroll-container">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="properties">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {properties
                  .sort((a, b) => a.order - b.order)
                  .map((property, index) => (
                    <Draggable
                      key={property._id}
                      draggableId={property._id}
                      index={index}
                    >
                      {(provided) => (
                        <Paper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="property-order-item"
                        >
                          <div {...provided.dragHandleProps}>
                            <IconButton className="drag-handle">
                              <DragIndicator />
                            </IconButton>
                          </div>
                          <TextField
                            type="number"
                            value={property.order}
                            onChange={(e) =>
                              handleOrderChange(property._id, e.target.value)
                            }
                            onBlur={handleOrderBlur}
                            className="order-input"
                            size="small"
                          />
                          <span className="property-name">{property.name}</span>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default PropertyOrder;
