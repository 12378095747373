import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Select as MSelect,
  MenuItem,
  Chip,
  CircularProgress,
  Checkbox,
  Typography,
} from "@mui/material";

import Select from "react-select";

const ChecklistForm = (props) => {
  const actionId = props.actionId;
  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [checklist, setChecklist] = useState([]);
  const [checklistTemplate, setChecklistTemplate] = useState([]);
  const [checklistFound, setChecklistFound] = useState(false);
  const [notes, setNotes] = useState("");
  const [note, setNote] = useState("");
  const [checklistId, setChecklistId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const fetchChecklistTemplate = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/checklist-template/getActiveChecklistTemplate",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      // Extract categories from the response
      const categories = response.data.activeChecklistTemplate.categories;

      // Transform categories to include items with `name` and `value: false`
      const transformedCategories = categories.map((category) => ({
        category: category.category,
        items: category.items.map((item) => ({
          name: item,
          value: false,
        })),
      }));
      setChecklistTemplate(transformedCategories);
      setChecklist(transformedCategories);
      createChecklist(
        actionId,
        response.data.activeChecklistTemplate.notes,
        transformedCategories
      );
    } catch (error) {
      console.error("Failed to fetch checklist template: ", error);
    }
  };

  const fetchChecklist = async () => {
    try {
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/checklist/getChecklistByActionId/${actionId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );



      setChecklist(response.data.checklist.items);
      setNote(response.data.checklist.note);
      setChecklistId(response.data.checklist._id);
      setChecklistFound(true);
    } catch (error) {
      console.error("Failed to fetch checklist: ", error);
      fetchChecklistTemplate();
    }
  };

  const updateChecklist = async () => {
    try {
      console.log("Updating checklist", actionId, notes, checklist);
      setIsLoading(true);
      const response = await axios.put(
        `https://maliarakis-server.rhodesislandpass.com/checklist/updateChecklist/${checklistId}`,
        {
          actionId: actionId,
          items: checklist,
          checklistId: checklistId,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      console.error("Failed to update checklist: ", error);
      setErrorMessage("Failed to update checklist");
    } finally {
      setIsLoading(false);
    }
  };

  const createChecklist = async (actionId, notes, checklist) => {
    try {
      console.log("Creating checklist", actionId, notes, checklist);
      setIsLoading(true);
      const response = await axios.post(
        "https://maliarakis-server.rhodesislandpass.com/checklist/createChecklist",
        {
          actionId: actionId,
          note: notes,
          items: checklist,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setChecklistId(response.data.checklist._id);
      setNote(response.data.checklist.note);
      setChecklistFound(true);
    } catch (error) {
      console.error("Failed to create checklist: ", error);
      setErrorMessage("Failed to create checklist");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!props.view && !props.edit) {
      fetchChecklist();
    } else {
      setChecklist(props.selectedChecklist.items);
      setNote(props.selectedChecklist.note);
      setChecklistId(props.selectedChecklist._id);
    }
  }, []);

  useEffect(() => {
    if (checklistFound) {
      updateChecklist();
    }
  }, [checklist]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
      }}
    >
      {isLoading && <CircularProgress />}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          width: "100%",
        }}
      >
        {!isLoading &&
          checklist.length > 0 &&
          checklist.map((checklistCategories, index) => (
            <div
              key={index}
              style={{
                width: "100%",
              }}
            >
              <h3
                style={{
                  width: "100%",
                  textAlign: "left",
                  borderBottom: "1px solid var(--secondary-color)",
                  marginBottom: "5px",
                  paddingBottom: "5px",
                  color: "var(--primary-color)",
                }}
              >
                {checklistCategories.category}
              </h3>

              {checklistCategories.items.map((item, index) => (
                <div
                  key={index}
                  style={{ display: "flex", gap: "5px", width: "100%" }}
                >
                  <Checkbox
                    disabled={props.view}
                    checked={item.value}
                    onChange={(e) => {
                      const updatedChecklist = checklist.map((category) => {
                        if (
                          category.category === checklistCategories.category
                        ) {
                          return {
                            category: category.category,
                            items: category.items.map((i) => {
                              if (i.name === item.name) {
                                return {
                                  name: item.name,
                                  value: e.target.checked,
                                };
                              }
                              return i;
                            }),
                          };
                        }
                        return category;
                      });

                      setChecklist(updatedChecklist);
                    }}
                  />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          ))}

        <div
          style={{
            display: "flex",
            flexDirection: "column",

            width: "100%",
            padding: "0px",
            margin: "0px",

            borderRadius: "10px",
          }}
        >
          <Typography
            variant="p"
            style={{
              width: "100%",
              textAlign: "center",
              border: "4px solid var(--secondary-color)",
              borderRadius: "20px",
              padding: "10px 0",
              margin: "0px",

              // padding: "10px",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            {note}
          </Typography>

          {/* <TextField
                label="Σημειώσεις"
                variant="outlined"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                fullWidth
                multiline
                rows={1}
                style={{
                    width: "100%",
                }}
            /> */}
        </div>
      </div>
    </div>
  );
};

export default ChecklistForm;
