// This file contains helper functions used by the Calendar component

/**
 * Format date string in DD/MM/YYYY format
 */
export function formatDateString(date) {
  const d = new Date(date);
  return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
}

/**
 * Format date in DD/MM/YYYY format
 */
export function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

/**
 * Format date for API data
 */
export function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

/**
 * Normalize date for comparison (removes time portion)
 */
export function normalizeDateForComparison(date) {
  if (!date) return null;
  const normalizedDate = new Date(date);
  return new Date(
    normalizedDate.getFullYear(),
    normalizedDate.getMonth(),
    normalizedDate.getDate()
  ).getTime();
}

/**
 * Check if a property has any unassigned actions
 */
export function hasUnassignedActions(property) {
  return property.actions.some(action => 
    action.action_option && // Only check actions with action_option
    (!action.assigned_to || action.assigned_to.length === 0)
  );
}

/**
 * Check if a property has any pending actions (actions without action_option)
 */
export function hasPendingActions(property) {
  return property.actions.some(action => !action.action_option);
}

/**
 * Get property status based on its actions and reservations
 */
export function getPropertyStatus(property, allActions, dates) {
  // Helper function to count days between dates
  const getDaysDifference = (start, end) => {
    return Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end dates
  };

  // Get all actions for this property - filtering out actions without an action_option
  const propertyActions = allActions.filter(action => 
    action.property === property._id && action.action_option
  );
  
  const actionDates = propertyActions.map(action => 
    new Date(new Date(action.planned_start_date).setHours(0, 0, 0, 0))
  );

  // Sort reservations and clip them to visible date range
  const sortedReservations = property.reservations
    .map(res => {
      const start = new Date(new Date(res.startDate).setHours(0, 0, 0, 0));
      const end = new Date(new Date(res.endDate).setHours(0, 0, 0, 0));
      
      // Clip reservation to visible range
      const visibleStart = start < dates[0] ? dates[0] : start;
      const visibleEnd = end > dates[dates.length - 1] ? dates[dates.length - 1] : end;
      
      return {
        start: visibleStart,
        end: visibleEnd,
        originalStart: start,
        originalEnd: end
      };
    })
    .filter(res => res.start <= dates[dates.length - 1] && res.end >= dates[0]) // Only keep visible reservations
    .sort((a, b) => a.start - b.start);

  // Rule 1: Check visible reservation lengths
  for (const res of sortedReservations) {
    const visibleLength = getDaysDifference(res.start, res.end);
    if (visibleLength >= 15) {
      const hasAction = actionDates.some(actionDate => 
        actionDate >= res.start && actionDate <= res.end
      );
      if (!hasAction) return 'warning';
    }
  }

  // Rule 2 & 3: Check before/after actions and collisions
  for (let i = 0; i < sortedReservations.length - 1; i++) {
    const current = sortedReservations[i];
    const next = sortedReservations[i + 1];

    // Check for collision between consecutive reservations
    // Two reservations collide when the end date of one equals the start date of another
    if (current.end.getTime() === next.start.getTime()) {
      // For a collision, we need an action specifically on this shared date
      const collisionDate = current.end.getTime();
      const hasActionOnCollision = actionDates.some(date => 
        date.getTime() === collisionDate
      );
      
      // If no action exists on the collision date, it's a critical situation
      if (!hasActionOnCollision) {
        return 'critical';
      }
    }
  }

  // Only check for before/after actions if reservations exist and no critical issues were found
  for (const current of sortedReservations) {
    // Only check for before/after actions if the reservation dates are within our visible range
    const startIsVisible = current.start.getTime() === current.originalStart.getTime();
    const endIsVisible = current.end.getTime() === current.originalEnd.getTime();

    // Check for action before start date if start is visible
    if (startIsVisible) {
      // First check if there's an action on the start date itself
      const hasActionOnStart = actionDates.some(date => 
        date.getTime() === current.start.getTime()
      );

      if (!hasActionOnStart) {
        // Check for actions up to 4 days before
        let hasActionBeforeStart = false;
        for (let j = 1; j <= 4; j++) {
          const dayToCheck = new Date(current.start);
          dayToCheck.setDate(dayToCheck.getDate() - j);
          if (actionDates.some(date => date.getTime() === dayToCheck.getTime())) {
            hasActionBeforeStart = true;
            break;
          }
        }
        if (!hasActionBeforeStart) return 'warning';
      }
    }

    // Check for action after end date if end is visible
    if (endIsVisible) {
      // First check if there's an action on the end date itself
      const hasActionOnEnd = actionDates.some(date => 
        date.getTime() === current.end.getTime()
      );

      if (!hasActionOnEnd) {
        // Check for actions up to 4 days after
        let hasActionAfterEnd = false;
        for (let j = 1; j <= 4; j++) {
          const dayToCheck = new Date(current.end);
          dayToCheck.setDate(dayToCheck.getDate() + j);
          if (actionDates.some(date => date.getTime() === dayToCheck.getTime())) {
            hasActionAfterEnd = true;
            break;
          }
        }
        if (!hasActionAfterEnd) return 'warning';
      }
    }
  }

  // Check for pending actions (actions without action_option)
  const hasPending = property.actions.some(action => !action.action_option);
  // if (hasPending) return 'critical';
  
  // Check for unassigned actions (only for actions with action_option)
  const hasUnassigned = property.actions.some(action => 
    action.action_option && // Only check actions with action_option
    (!action.assigned_to || action.assigned_to.length === 0)
  );
  
  if (hasUnassigned) return 'warning';

  return 'normal';
}

/**
 * Prepare data for drag events
 * @param {Object} action Action object to prepare
 * @param {Date} date Date associated with this action
 * @param {String} propertyId Property ID
 * @returns {Object} Formatted drag data
 */
export function prepareDragData(action, date, propertyId) {
  // Check if action is pending (no action_option)
  const isPending = !action?.action_option;
  
  // Create a transfer object with all necessary data
  return {
    sourceHotelId: propertyId,
    date: date.toISOString(),
    isPending,
    action: action ? {
      _id: action._id,
      date: date.toISOString(),
      action_option: action.action_option,
      name: action.name,
      property: propertyId,
      color: action.color,
      assigned_to: action.assigned_to,
      notes: action.notes,
      price: action.price,
      is_pending: isPending
    } : null
  };
}

/**
 * Handle drop events for calendar cells
 * @param {Function} updateAction Function to call to update an action
 * @param {Array} data Calendar data array
 * @param {Object} dragData Drag event data
 * @param {Date} targetDate Target date
 * @param {String} targetPropertyId Target property ID 
 * @param {String} token Auth token
 * @returns {Promise} Promise with the operation result
 */
export async function handleActionDrop(updateAction, data, dragData, targetDate, targetPropertyId, token) {
  // Extract data from the drag event
  const sourcePropertyId = dragData.sourceHotelId;
  const oldDate = new Date(dragData.date);
  const action = dragData.action;
  
  if (!action) return { success: false, message: "No action data found" };
  
  try {
    // Find source and target properties
    const sourceProperty = data.find(hotel => hotel._id === sourcePropertyId);
    if (!sourceProperty) return { success: false, message: "Source property not found" };
    
    const targetProperty = data.find(hotel => hotel._id === targetPropertyId);
    if (!targetProperty) return { success: false, message: "Target property not found" };
    
    // Format date for API
    const formattedNewDate = formatDateData(targetDate);
    
    // Create update payload
    const updateData = {
      planned_start_date: formattedNewDate,
    };
    
    // If moving to a different property, update property field
    if (sourcePropertyId !== targetPropertyId) {
      updateData.property = targetPropertyId;
    }
    
    // Call update API
    const response = await updateAction(action._id, updateData, token);
    
    return {
      success: true,
      data: {
        sourcePropertyId,
        targetPropertyId,
        oldDate,
        newDate: targetDate,
        action,
        response
      }
    };
  } catch (error) {
    console.error("Error in handleActionDrop:", error);
    return { 
      success: false, 
      message: error.message || "Failed to update action",
      error
    };
  }
}

/**
 * Handle reservation conflicts when moving actions between properties
 * @param {Array} sourceReservations Source property reservations
 * @param {Array} targetReservations Target property reservations 
 * @param {String} option How to handle conflict ("keep", "add", "replace", "remove")
 * @returns {Array} Final list of reservation IDs
 */
export function resolveReservationConflicts(sourceReservations, targetReservations, option = "keep") {
  switch (option) {
    case "keep":
      // Keep existing reservations
      return sourceReservations.map(res => typeof res === 'string' ? res : res._id);
      
    case "add":
      // Add target reservations to source reservations
      const sourceIds = new Set(sourceReservations.map(res => typeof res === 'string' ? res : res._id));
      const newIds = targetReservations
        .filter(res => !sourceIds.has(typeof res === 'string' ? res : res._id))
        .map(res => typeof res === 'string' ? res : res._id);
      
      return [
        ...sourceReservations.map(res => typeof res === 'string' ? res : res._id),
        ...newIds
      ];
      
    case "replace":
      // Replace with target reservations
      return targetReservations.map(res => typeof res === 'string' ? res : res._id);
      
    case "remove":
      // Remove all reservations
      return [];
      
    default:
      return sourceReservations.map(res => typeof res === 'string' ? res : res._id);
  }
}

/**
 * Filter properties based on selected filters
 * @param {Array} properties List of properties to filter
 * @param {Array} filters Array of active filter names
 * @param {Array} dates Date range to consider for filtering
 * @param {Array} actions All actions for reference
 * @returns {Array} Filtered properties list
 */
export function filterProperties(properties, filters, dates, actions) {
  if (filters.includes("all")) {
    return properties;
  }

  return properties.filter(property => {
    const reservationsInRange = property.reservations.filter(res => {
      const resStartDate = new Date(res.startDate);
      const resEndDate = new Date(res.endDate);
      return resStartDate <= dates[dates.length - 1] && resEndDate >= dates[0];
    });

    const actionsInRange = property.actions.filter(action => {
      const actionDate = new Date(action.planned_start_date);
      return actionDate >= dates[0] && actionDate <= dates[dates.length - 1];
    });

    const hasReservation = reservationsInRange.length > 0;
    const hasAction = actionsInRange.length > 0;
    const propertyStatus = getPropertyStatus(property, actions, dates);
    
    // Check for pending actions
    const hasPendingAction = property.actions.some(action => {
      const actionDate = new Date(action.planned_start_date);
      return !action.action_option && 
             actionDate >= dates[0] && 
             actionDate <= dates[dates.length - 1];
    });
    
    return filters.every(filter => {
      switch (filter) {
        case "withReservation":
          return hasReservation;
        case "withoutReservation":
          return !hasReservation;
        case "withAction":
          return hasAction;
        case "withoutAction":
          return !hasAction;
        case "withWarnings":
          return propertyStatus === 'warning' || propertyStatus === 'critical';
        case "withUnassignedActions":
          return hasUnassignedActions(property);
        case "withPendingActions":
          return hasPendingAction;
        default:
          return true;
      }
    });
  });
}

/**
 * Compare location IDs safely regardless of format (object or string)
 * @param {Object|String} propertyLocation The property's location_id
 * @param {String} filterLocationId The location ID to filter by
 * @returns {Boolean} True if locations match
 */
export function compareLocationIds(propertyLocation, filterLocationId) {
  if (!propertyLocation || !filterLocationId) return false;
  
  // Extract the ID value depending on whether propertyLocation is an object or string
  const propLocationId = typeof propertyLocation === 'object' && propertyLocation !== null
    ? propertyLocation._id || propertyLocation.id
    : propertyLocation;
  
  // Ensure string comparison
  return propLocationId && propLocationId.toString() === filterLocationId.toString();
}

/**
 * Filter properties by location ID
 * @param {Array} properties Array of properties to filter
 * @param {String} locationId Location ID to filter by (or null for all)
 * @returns {Array} Filtered properties
 */
export function filterPropertiesByLocation(properties, locationId) {
  if (!locationId) return properties;
  
  return properties.filter(property => 
    property && property.location_id && compareLocationIds(property.location_id, locationId)
  );
}

/**
 * Check if an action is on a specific date
 * @param {Object} action The action object
 * @param {Date} date The date to check
 * @returns {Boolean} True if the action is on the specified date
 */
export function isActionOnDate(action, date) {
  if (!action || !date) return false;
  
  const actionDate = new Date(action.planned_start_date);
  return (
    actionDate.getDate() === date.getDate() &&
    actionDate.getMonth() === date.getMonth() &&
    actionDate.getFullYear() === date.getFullYear()
  );
}
