import React from 'react';

const FilterChip = ({ label, count, onClick, isActive }) => {
  // Add special class for pending actions filter
  const chipClasses = `filter-chip ${isActive ? "active" : ""} ${
    label === "Pending Action" ? "withPendingActions" : ""
  }`;
  
  return (
    <div className={chipClasses} onClick={onClick}>
      <div>
        <p>{label} ({count})</p>
      </div>
    </div>
  );
};

export default FilterChip;
