import React, { useState, useEffect, useCallback } from "react";
import { Upload } from "@mui/icons-material";
import axios from "axios";
import * as XLSX from "xlsx";
import "./AddReservationButtonLoggia.css";
import apiUrl from "../api";

const AddReservationButtonLoggia = (props) => {
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );

  props.setLoggia(true);
  const user = JSON.parse(localStorage.getItem("user"));

  const [duplicateProperties, setDuplicateProperties] = useState([]);
  const [newProperties, setNewProperties] = useState([]);
  const [newPropertiesReservations, setNewPropertiesReservations] = useState(
    []
  );
  const [duplicatePropertiesReservations, setDuplicatePropertiesReservations] =
    useState([]);

  const [showPopUp, setShowPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const BATCH_SIZE = 100;

  const mapReservation = (reservation) => {
    // Convert Excel date serial number to DD-MM-YYYY string
    const excelDateToString = (excelDate) => {
      if (!excelDate) return "";
      
      // Check if the date is already a string in DD-MM-YYYY format
      if (typeof excelDate === 'string' && excelDate.match(/^\d{1,2}-\d{1,2}-\d{4}$/)) {
        return excelDate;
      }
      
      // Convert Excel numeric date (days since 1900-01-01, except Excel incorrectly thinks 1900 is a leap year)
      if (typeof excelDate === 'number' || !isNaN(Number(excelDate))) {
        // Excel date serial numbers start from January 0, 1900
        const date = new Date(1900, 0, -1);
        date.setDate(date.getDate() + Number(excelDate));
        
        // Format as DD-MM-YYYY
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        
        return `${day}-${month}-${year}`;
      }
      
      return String(excelDate);
    };
    
    return {
      reservationId: reservation["Reservation ID"],
      property: reservation["Property"],
      checkIn: excelDateToString(reservation["Checkin"]),
      checkOut: excelDateToString(reservation["Checkout"]),
      nights: reservation["Nights"],
      adults: reservation["Adults"],
      kids: reservation["Kids"] || 0,
      cribs: reservation["Cribs"] || 0,
      highChairs: reservation["Highchairs"] || 0,
      idPassport: reservation["ID/Passport"] || "",
      guestTotal: reservation["Guest Total"] || 0,
      channel: reservation["Channel"] || "",
      channelAmount: reservation["Channel Amount"] || 0,
      firstName: reservation["First Name"],
      lastName: reservation["Last Name"],
      email: reservation["Email"] || "",
      phone: reservation["Phone"] || "",
      staffNotes: reservation["Staff Notes"] || "",
      customerNotes: reservation["Customer Notes"] || "",
      arrivalTime: reservation["Arrival Time"] || "",
      arrivalLocation: reservation["Arrival Location"] || "",
      arrivalFlight: reservation["Arrival Flight"] || "",
      departureTime: reservation["Departure Time"] || "",
      departureLocation: reservation["Departure Location"] || "",
      departureFlight: reservation["Departure Flight"] || "",
      status: reservation["Status"] || "",
      loggia_id: "",
    };
  };

  const checkProperties = async (properties, callback = () => {}) => {
    try {
      const response = await axios.post(
        `${apiUrl}/property/checkProperties`,
        { properties },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );

      const duplicateProperties = response.data.duplicateProperties || [];
      const newProperties = response.data.notFoundProperties || [];

      callback(duplicateProperties, newProperties);

      return {
        duplicateProperties,
        newProperties,
      };
    } catch (error) {
      
      callback([], []); // Call the callback with empty arrays in case of error
      return { duplicateProperties: [], newProperties: [] };
    }
  };

  const filterUniqueReservations = (reservations) => {
    return reservations.filter(
      (reservation, index, self) =>
        index ===
        self.findIndex((r) => r.reservationId === reservation.reservationId)
    );
  };

  const uploadReservation = async (
    reservation,
    duplicateProperties,
    newProperties
  ) => {
    try {
      await axios.post(
        `${apiUrl}/reservation/createReservation`,
        {
          dontCreate:
            duplicateProperties.includes(
              reservation.property
                .trim()
                .replace(/\([^()]*\)/g, "")
                .trim()
            ) ||
            newProperties.includes(
              reservation.property
                .trim()
                .replace(/\([^()]*\)/g, "")
                .trim()
            ),
          reservationId: reservation.reservationId,
          property: reservation.property,
          checkIn: reservation.checkIn
            .split("-")
            .map((part, index, array) =>
              index === 0 ? array[1] : index === 1 ? array[0] : part
            )
            .join("-"),
          checkOut: reservation.checkOut
            .split("-")
            .map((part, index, array) =>
              index === 0 ? array[1] : index === 1 ? array[0] : part
            )
            .join("-"),
          nights: reservation.nights,
          adults: reservation.adults,
          kids: reservation.kids,
          cribs: reservation.cribs,
          highChairs: reservation.highChairs,
          idPassport: reservation.idPassport,
          guestTotal: reservation.guestTotal,
          channel: reservation.channel,
          channelAmount: reservation.channelAmount,
          firstName: reservation.firstName,
          lastName: reservation.lastName,
          email: reservation.email,
          phone: reservation.phone,
          staffNotes: reservation.staffNotes,
          customerNotes: reservation.customerNotes,
          arrivalTime: reservation.arrivalTime,
          arrivalLocation: reservation.arrivalLocation,
          arrivalFlight: reservation.arrivalFlight,
          departureTime: reservation.departureTime,
          departureLocation: reservation.departureLocation,
          departureFlight: reservation.departureFlight,
          status: reservation.status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      
    }
  };

  const uploadReservationsBatch = async (
    reservations,
    duplicateProperties,
    newProperties
  ) => {
    try {
      const batchSize = BATCH_SIZE;
      for (let i = 0; i < reservations.length; i += batchSize) {
        const batch = reservations.slice(i, i + batchSize);
        const formattedBatch = batch.map((reservation) => {
          // For dates, if they're already in DD-MM-YYYY format, leave them as is
          // If they need conversion from MM-DD-YYYY to DD-MM-YYYY, handle that case
          const formatDateIfNeeded = (dateStr) => {
            if (!dateStr) return dateStr;
            
            // If already in DD-MM-YYYY format, return as is
            if (dateStr.match(/^\d{1,2}-\d{1,2}-\d{4}$/)) {
              const parts = dateStr.split('-');
              // Verify format - first part should be day (1-31), second month (1-12)
              if (parseInt(parts[0]) <= 31 && parseInt(parts[1]) <= 12) {
                return dateStr;
              }
            }
            
            // If has dashes but in wrong format, try to convert
            if (dateStr.includes('-')) {
              const parts = dateStr.split('-');
              if (parts.length === 3) {
                // Assuming MM-DD-YYYY format needs conversion to DD-MM-YYYY
                if (parseInt(parts[0]) <= 12 && parseInt(parts[1]) <= 31) {
                  return `${parts[1]}-${parts[0]}-${parts[2]}`;
                }
              }
            }
            
            return dateStr;
          };
          
          // Add null checks for all property values
          const safeProperty = reservation.property || "";
          const propertyTrimmed = typeof safeProperty === 'string' ? 
            safeProperty.trim().replace(/\([^()]*\)/g, "").trim() : "";

          return {
            dontCreate:
              duplicateProperties.includes(propertyTrimmed) ||
              newProperties.includes(propertyTrimmed),
            reservationId: reservation.reservationId || "",
            property: safeProperty,
            checkIn: formatDateIfNeeded(reservation.checkIn) || "",
            checkOut: formatDateIfNeeded(reservation.checkOut) || "",
            nights: reservation.nights || 0,
            adults: reservation.adults || 0,
            kids: reservation.kids || 0,
            cribs: reservation.cribs || 0,
            highChairs: reservation.highChairs || 0,
            idPassport: reservation.idPassport || "",
            guestTotal: reservation.guestTotal || 0,
            channel: reservation.channel || "",
            channelAmount: reservation.channelAmount || 0,
            firstName: reservation.firstName || "",
            lastName: reservation.lastName || "",
            email: reservation.email || "",
            phone: reservation.phone || "",
            staffNotes: reservation.staffNotes || "",
            customerNotes: reservation.customerNotes || "",
            arrivalTime: reservation.arrivalTime || "",
            arrivalLocation: reservation.arrivalLocation || "",
            arrivalFlight: reservation.arrivalFlight || "",
            departureTime: reservation.departureTime || "",
            departureLocation: reservation.departureLocation || "",
            departureFlight: reservation.departureFlight || "",
            status: reservation.status || "",
          };
        });

        await axios.put(
          `${apiUrl}/reservation/createReservationsBatch`, // Update the backend endpoint
          { reservations: formattedBatch },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        // Check if the reservation is a duplicate or new property
        batch.forEach((reservation) => {
          const property = reservation.property
            .trim()
            .replace(/\([^()]*\)/g, "")
            .trim();

          if (duplicateProperties.includes(property)) {
            
            props.setDuplicatePropertyReservations &&
              props.setDuplicatePropertyReservations(
                (prev) => filterUniqueReservations([...prev, reservation])
              );
          } else if (newProperties.includes(property)) {
            props.setNewPropertyReservations &&
              props.setNewPropertyReservations(
                (prev) => filterUniqueReservations([...prev, reservation])
              );
          }
        });
      }
    } catch (error) {
      
      // Handle errors appropriately (e.g., show an error message to the user)
    }
  };

  const handleFiles = async (files) => {
    let totalReservations = 0;
    let processedReservations = 0;
    const tempNewReservations = [];
    const tempDuplicateReservations = [];
    props.setIsLoading && props.setIsLoading(true);
    setIsLoading(true);

    try {
      // Calculate total number of reservations across all files
      for (const file of files) {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: "buffer" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        totalReservations += jsonData.length;
      }

      // Now process each file one by one
      for (const file of files) {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: "buffer" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        
        // Set date detection option to true to ensure Excel dates are parsed correctly
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { 
          raw: false, // Don't use raw values
          dateNF: 'DD-MM-YYYY' // Specify the date format
        });

        

        // Filter out entries with no property name
        const validData = jsonData.filter(item => item["Property"] && typeof item["Property"] === 'string');
        
        if (validData.length < jsonData.length) {
          
        }

        const uniqueProperties = [
          ...new Set(validData.map((reservation) => reservation["Property"]))
        ].filter(Boolean); // Filter out null, undefined, empty strings

        // Check if we have any valid properties to process
        if (uniqueProperties.length === 0) {
          
          continue;
        }

        const { duplicateProperties, newProperties } = await checkProperties(
          uniqueProperties
        );

        

        for (let i = 0; i < validData.length; i += BATCH_SIZE) {
          const batch = validData.slice(i, i + BATCH_SIZE);
          const reservations = batch.map(mapReservation);

          await uploadReservationsBatch(
            reservations,
            duplicateProperties,
            newProperties
          );

          // Update the total processed reservations across all files
          processedReservations += batch.length;

          // Calculate the overall progress percentage based on all files
          const progressPercentage =
            (processedReservations / totalReservations) * 100;

          props.setProgress && props.setProgress(progressPercentage);
        }
      }
    } catch (err) {
      
    } finally {
      // Set the new and duplicate reservations at the end
      setNewPropertiesReservations(tempNewReservations);
      setDuplicatePropertiesReservations(tempDuplicateReservations);

      // Stop loading when all reservations are processed
      props.setIsLoading && props.setIsLoading(false);
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    props.setIsLoading && props.setIsLoading(true);
    props.setProgress && props.setProgress(0);
    setIsLoading(true);
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    input.multiple = true; // Allow multiple file selection
    input.onchange = (e) => handleFiles(e.target.files);
    input.click();
  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth <= 768 ? "100%" : "auto";
      setButtonWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      newPropertiesReservations.length > 0 ||
      duplicatePropertiesReservations.length > 0
    ) {
      setShowPopUp(true);
      props.setNewPropertyReservations &&
        props.setNewPropertyReservations(newPropertiesReservations);
      props.setDuplicatePropertyReservations &&
        props.setDuplicatePropertyReservations(duplicatePropertiesReservations);
    }
  }, [newPropertiesReservations, duplicatePropertiesReservations]);

  return (
    <>
      <div
        className="add-ticket-button"
        style={{
          display: user.permissions.canAddReservation ? "flex" : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
            marginLeft: props.marginLeft || "0px",
            marginRight: props.marginRight || "0px",
          }}
          onClick={handleClick}
        >
          <Upload />
          <span>Import Reservations</span>
        </button>
      </div>
    </>
  );
};

export default AddReservationButtonLoggia;
