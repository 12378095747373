import React, { useState, useEffect, useRef } from "react";

import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Select as MSelect,
  MenuItem,
  Autocomplete,
  FormHelperText, // Add this import
} from "@mui/material";
import { Update, Delete } from "@mui/icons-material";
import Select, { components } from "react-select";

import axios from "axios";

const CustomOption = (props) => {
  return (
    <Tooltip
      title="color"
      placement="left"
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
            backgroundColor: `${props.data.tooltip}`,
            color: ` ${props.data.tooltip}`,
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function formatDateTime(date) {
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
  return adjustedDate.toISOString().slice(0, 16);
}
const AddActionForm = (props) => {
  const hasInitializedRef = useRef(false);
  const [reservationsLoaded, setReservationsLoaded] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // Make sure the initialPropertyData uses the right format
  const initialPropertyData = props.property ? {
    ...props.property,
    value: {
      ...props.property.value,
      actions_analytics: props.property.value?.actions_analytics || [],
      actions_prices: props.property.value?.actions_prices || []
    }
  } : "";

  // Initialize with props.initialReservations if provided
  const [actionData, setActionData] = useState({
    action_option: props.action_option || "",
    property: initialPropertyData,
    group: props.group || "",
    assigned_to: props.assigned_to || [],
    planned_start_date: props.date ? formatDateTime(props.date) : "",
    start_date: props.start_date ? formatDateTime(new Date(props.start_date)) : "",
    end_date: props.end_date ? formatDateTime(new Date(props.end_date)) : "",
    price: props.price || "",
    notes: props.notes || "",
    estimated_hours: props.estimated_hours || 0,
    // Initialize with initialReservations from props if provided
    reservations: props.initialReservations || [],
    is_pending: !props.action_option // Mark as pending if no action_option
  });
  
  // State to track if this is a pending action (no action_option)
  const [isPendingAction, setIsPendingAction] = useState(!props.action_option);

  const actionId = props.actionId || "";

  const edit = props.edit || false;
  const view = props.view || false;

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const [usersOptions, setUsersOptions] = useState([]); // Add this line

  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [actionOptions, setActionOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  // Use reservationsOptions directly from props if provided
  const [propertyReservations, setPropertyReservations] = useState(props.reservationsOptions || []);

  // Add state to track existing action reservations for edit mode
  const [existingReservations, setExistingReservations] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
      setUsersOptions(
        response.data.users.map((user) => ({
          value: user,
          label: user.username,
          tooltip: user.name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/group/getGroups",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGroups(response.data.groups);
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/property/getPropertiesWithAnalytics",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.properties);
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  const fetchActionOptions = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/action-option/getActionOptions",

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setActionOptions(response.data.actionOptions);
    } catch (error) {
      console.error("Failed to fetch action options: ", error);
    }
  };

  // Add function to fetch reservations for an existing action
  const fetchActionReservations = async (actionId) => {
    if (!actionId) return;
    
    try {
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/action/getActionReservations/${actionId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      if (response.data.reservations && response.data.reservations.length > 0) {
        console.log("Fetched action reservations:", response.data.reservations);
        
        // Format existing reservations for react-select
        const formattedReservations = response.data.reservations.map(res => ({
          value: res._id,
          label: `${res.reservationId} - ${res.firstName} ${res.lastName} (${formatDate(res.checkIn || res.startDate)} to ${formatDate(res.checkOut || res.endDate)})`,
          reservation: res
        }));
        
        // Update state with the fetched reservations
        setExistingReservations(formattedReservations);
        
        // Also set these as the selected reservations in actionData
        setActionData(prev => ({
          ...prev,
          reservations: formattedReservations
        }));
      }
      setReservationsLoaded(true);
    } catch (error) {
      console.error("Failed to fetch action reservations:", error);
      setReservationsLoaded(true);
    }
  };

  // Modify property reservations fetch to merge with existing selected reservations
  const fetchPropertyReservations = async (propertyId) => {
    if (!propertyId) return;
    
    try {
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/reservation/getReservationByProperty/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      // Format reservations for react-select
      const reservations = response.data.reservations.map(res => ({
        value: res._id,
        label: `${res.reservationId || "No ID"} - ${res.firstName || "Guest"} ${res.lastName || ""} (${formatDate(res.checkIn || res.startDate)} to ${formatDate(res.checkOut || res.endDate)})`,
        reservation: res
      }));

      // Get existing reservation IDs to avoid duplicates
      const existingIds = new Set(actionData.reservations.map(r => r.value));
      const currentIds = new Set(existingReservations.map(r => r.value));
      
      // Combine existing reservations with property reservations, avoiding duplicates
      const mergedReservations = [
        ...actionData.reservations,
        ...reservations.filter(r => !existingIds.has(r.value) && !currentIds.has(r.value))
      ];
      
      setPropertyReservations(mergedReservations);
    } catch (error) {
      console.error("Failed to fetch property reservations:", error);
      setPropertyReservations([...actionData.reservations, ...existingReservations]);
    }
  };

  const handleGroupChange = (selectedGroups) => {
    // Don't allow group assignment for pending actions
    if (isPendingAction) return;
    
    const newUsers = selectedGroups?.users?.filter(
      (user) =>
        !actionData.assigned_to.map((user) => user.value._id).includes(user._id)
    ) || [];

    setActionData({
      ...actionData,
      group: selectedGroups,
      assigned_to: actionData.assigned_to.concat(
        newUsers.map((user) => ({
          value: {
            _id: user._id,
            username: user.username,
          },
          label: user.username,
        }))
      ),
    });

    console.log({
      value: selectedGroups?.value,
      label: selectedGroups?.label,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setActionData({
      ...actionData,
      [name]: value,
    });
  };

  const updateEstimatedHours = (propertyData, actionOptionData) => {
    if (propertyData && actionOptionData) {
      const propertyAnalytics = propertyData.actions_analytics || [];
      const matchingAnalytic = propertyAnalytics.find(
        a => a._id === actionOptionData._id
      );
      // Return the actual value, not a falsy check
      return matchingAnalytic ? matchingAnalytic.avgTime : 0;
    }
    return 0;
  };

const handlePropertyChange = (selectedOption) => {
  const propertyData = selectedOption?.value;
  const actionOptionData = actionData.action_option?.value;
  
  let newEstimatedHours = 0;
  let newPrice = actionData.price; // Keep existing price by default

  if (propertyData && actionOptionData) {
    const matchingAnalytic = propertyData.actions_analytics?.find(
      a => a._id === actionOptionData._id
    );
    
    const matchingPrice = propertyData.actions_prices?.find(
      p => p._id === actionOptionData._id
    );

    newEstimatedHours = matchingAnalytic?.avgTime || 0;
    newPrice = matchingPrice?.price || newPrice; // Only update if we found a matching price
  }

  setActionData({
    ...actionData,
    property: selectedOption,
    estimated_hours: newEstimatedHours,
    price: newPrice,
    reservations: [] // Reset reservations when property changes
  });
  
  // Fetch reservations for the selected property
  if (selectedOption?.value?._id) {
    fetchPropertyReservations(selectedOption.value._id);
  } else {
    setPropertyReservations([]);
  }
};

const handleActionOptionChange = (selectedOption) => {
  const propertyData = actionData.property?.value;
  const actionOptionData = selectedOption?.value;
  
  let newEstimatedHours = 0;
  let newPrice = actionData.price; // Keep existing price by default

  // Set pending status based on whether an action_option is selected
  const newIsPendingAction = !selectedOption;
  setIsPendingAction(newIsPendingAction);

  // If switching to pending, clear assigned users and group
  let newAssignedTo = actionData.assigned_to;
  let newGroup = actionData.group;
  
  if (newIsPendingAction) {
    newAssignedTo = [];
    newGroup = "";
  }

  if (propertyData && actionOptionData) {
    // Find matching analytic for estimated hours
    const propertyAnalytics = propertyData.actions_analytics || [];
    const matchingAnalytic = propertyAnalytics.find(
      a => a._id === actionOptionData._id
    );
    
    // Find matching price
    const matchingPrice = propertyData.actions_prices?.find(
      p => p._id === actionOptionData._id
    );

    newEstimatedHours = matchingAnalytic ? matchingAnalytic.avgTime : 0;
    newPrice = matchingPrice ? matchingPrice.price : newPrice; // Only update if we found a matching price
  }

  setActionData({
    ...actionData,
    action_option: selectedOption,
    estimated_hours: newEstimatedHours,
    price: newPrice,
    assigned_to: newAssignedTo,
    group: newGroup,
    is_pending: newIsPendingAction
  });
};

const validateForm = () => {
  const errors = {};
  
  if (!actionData.property) {
    errors.property = "Property is required";
  }
  
  if (!actionData.planned_start_date) {
    errors.planned_start_date = "Date is required";
  }
  
  // No need to validate action_option, it's optional now
  
  setFormErrors(errors);
  return Object.keys(errors).length === 0;
};

const handleSubmit = async (e) => {
  if (!view) {
    e.preventDefault();
    
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    
    setIsLoading(true);

    const dataToSend = {
      ...actionData,
      action_option: actionData.action_option?.value?._id || null, // Can be null now
      property: actionData.property?.value?._id,
      group: actionData.group ? actionData.group.value._id : "",
      assigned_to: actionData.action_option ? actionData.assigned_to.map((user) => user.value._id) : [], // Only send assigned users if not pending
      planned_start_date: formatDateData(actionData.planned_start_date),
      start_date: actionData.start_date ? formatDateData(actionData.start_date) : null,
      end_date: actionData.end_date ? formatDateData(actionData.end_date) : null,
      price: actionData.price,
      notes: actionData.notes,
      estimated_hours: actionData.estimated_hours,
      reservations: actionData.reservations.map(res => res.value), // Extract just the IDs
      is_pending: !actionData.action_option // Mark as pending if no action_option
    };

    try {
      if (!edit) {
        const response = await axios.post(
          "https://maliarakis-server.rhodesislandpass.com/action/createAction",
          {
            action_option: dataToSend.action_option,
            property: dataToSend.property,
            groupAssignedTo: JSON.stringify(dataToSend.group),
            assignedTo: JSON.stringify(dataToSend.assigned_to),
            planned_start_date: dataToSend.planned_start_date,
            start_date: dataToSend.start_date,
            end_date: dataToSend.end_date,
            price: dataToSend.price,
            notes: dataToSend.notes,
            estimated_hours: dataToSend.estimated_hours,
            reservations: dataToSend.reservations // Send as array, not stringified
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
      } else {
        const response = await axios.put(
          `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${actionId}`,
          {
            action_option: dataToSend.action_option,
            property: dataToSend.property,
            groupAssignedTo: JSON.stringify(dataToSend.group),
            assignedTo: JSON.stringify(dataToSend.assigned_to),
            planned_start_date: dataToSend.planned_start_date,
            start_date: dataToSend.start_date,
            end_date: dataToSend.end_date,
            price: dataToSend.price,
            notes: dataToSend.notes,
            reservations: dataToSend.reservations // Send as array, not stringified
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
      }

      setSuccessMessage(
        !edit
          ? "Η δραστηριότητα δημιουργήθηκε επιτυχώς"
          : "Η δραστηριότητα ενημερώθηκε επιτυχώς"
      );
      setErrorMessage("");
      setIsLoading(false);

    } catch (error) {
      console.error("Error saving action:", error);
      console.error("Error details:", error.response?.data);
      setSuccessMessage("");
      setErrorMessage(
        !edit
          ? "Σφάλμα κατά τη δημιουργία της δραστηριότητας"
          : "Σφάλμα κατά την ενημέρωση της δραστηριότητας"
      );
      setIsLoading(false);
    }
  }
};

  useEffect(() => {
    fetchUsers();
    fetchGroups();
    fetchProperties();
    fetchActionOptions();
    
    // If we're editing an action, fetch its reservations
    if (edit && actionId && !reservationsLoaded) {
      fetchActionReservations(actionId);
    }
  }, [edit, actionId]);

  useEffect(() => {
    if (actionData.property?.value && actionData.action_option?.value) {
      const propertyAnalytics = actionData.property.value.actions_analytics || [];
      const matchingAnalytic = propertyAnalytics.find(
        a => a._id === actionData.action_option.value._id
      );
      
      if (matchingAnalytic) {
        setActionData(prev => ({
          ...prev,
          estimated_hours: matchingAnalytic.avgTime || 0
        }));
      }
    }
  }, []);

  useEffect(() => {
    // console.log("Current property data:", actionData.property?.value?.actions_analytics);
  }, [actionData.property]);

  useEffect(() => {
    if (!hasInitializedRef.current && props.property) {
      const fetchAndUpdateProperty = async () => {
        try {
          const response = await axios.get(
            "https://maliarakis-server.rhodesislandpass.com/property/getPropertiesWithAnalytics",
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          
          const fullPropertyData = response.data.properties.find(
            p => p._id === props.property.value._id
          );

          if (fullPropertyData) {
            const propertyWithData = {
              ...props.property,
              value: {
                ...props.property.value,
                actions_analytics: fullPropertyData.actions_analytics || [],
                actions_prices: fullPropertyData.actions_prices || []
              }
            };

            // If we have both property and action option, calculate estimated hours and price
            if (props.action_option) {
              const matchingAnalytic = fullPropertyData.actions_analytics?.find(
                a => a._id === props.action_option.value._id
              );
              const matchingPrice = fullPropertyData.actions_prices?.find(
                p => p._id === props.action_option.value._id
              );

              setActionData(prev => ({
                ...prev,
                property: propertyWithData,
                estimated_hours: matchingAnalytic?.avgTime || 0,
                price: matchingPrice?.price || props.price || 0
              }));
            } else {
              setActionData(prev => ({
                ...prev,
                property: propertyWithData
              }));
            }
          }
        } catch (error) {
          console.error("Failed to fetch property analytics:", error);
        }
      };

      fetchAndUpdateProperty();
      hasInitializedRef.current = true;
    }
  }, [props.property, props.action_option]);

  // Fetch property reservations on component mount if property is provided
  useEffect(() => {
    if (props.property?.value?._id) {
      if (edit && actionId && !reservationsLoaded) {
        // For edit mode, wait for action reservations to load first
        if (existingReservations.length > 0) {
          fetchPropertyReservations(props.property.value._id);
        }
      } else if (props.initialReservations?.length > 0) {
        // Set initialReservations if provided (e.g., from Calendar double-click)
        setActionData(prev => ({
          ...prev,
          reservations: props.initialReservations
        }));
        
        // Also fetch other reservations for this property
        // fetchPropertyReservations(props.property.value._id);
      } else if (!props.reservationsOptions) {
        // Default case: just fetch property reservations
        fetchPropertyReservations(props.property.value._id);
      }
    }
  }, [props.property, props.initialReservations, existingReservations, reservationsLoaded, edit, actionId]);

  // Update propertyReservations when reservationsOptions changes
  useEffect(() => {
    if (props.reservationsOptions && props.reservationsOptions.length > 0) {
      // Find selected reservations that are not in options and add them
      const optionsIds = new Set(props.reservationsOptions.map(r => r.value));
      const selectedNotInOptions = actionData.reservations.filter(r => !optionsIds.has(r.value));
      
      setPropertyReservations([...props.reservationsOptions, ...selectedNotInOptions]);
    }
  }, [props.reservationsOptions]);

  return (
    <div className="ticket-form-wrapper">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form className="ticket-form" onSubmit={view ? null : handleSubmit}>
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="action_option">
                Ενέργεια {!isPendingAction && <span style={{color: "red"}}>*</span>}
              </label>
              <Select
                isDisabled={view}
                options={actionOptions
                  .filter((option) => {
                    return !actionData.action_option
                      ? true
                      : option._id !== actionData.action_option.value._id;
                  })
                  .map((option) => ({
                    value: option,
                    label: option.name,
                  }))}
                onChange={handleActionOptionChange}
                value={actionData.action_option}
                placeholder="Επιλέξτε ενέργεια (προαιρετικό)"
                isClearable={true}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
              />
              {isPendingAction && (
                <div className="pending-action-toggle">
                  <span style={{color: "#555", fontSize: "13px"}}>
                    Εκκρεμής ενέργεια - δεν μπορεί να ανατεθεί σε ομάδα ή χρήστες μέχρι να επιλεγεί τύπος ενέργειας
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="property">
                Κατάλυμα <span style={{color: "red"}}>*</span>
              </label>
              <Select
                isDisabled={view}
                
                options={properties
                  .filter((property) => {
                    return !actionData.property
                      ? true
                      : property._id !== actionData.property.value._id;
                  })
                  .map((property) => ({
                    value: property,
                    label: property.name,
                  }))}
                onChange={handlePropertyChange}
                value={actionData.property}
                placeholder="Επιλέξτε κατάλυμα"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
              />
            </div>
          </div>

          {/* Add new Reservations field after property selection */}
          {(
            <div className="form-group">
              <div className="form-field">
                <label className="form-field-label" htmlFor="reservations">
                  Συνδεδεμένες Κρατήσεις {edit && <span style={{fontSize: '0.8em', color: '#666'}}>(Διαθέσιμες: {propertyReservations?.length || 0})</span>}
                </label>
                <Select
                  isDisabled={view}
                  options={propertyReservations}
                  onChange={(selectedOptions) => {
                    setActionData({
                      ...actionData,
                      reservations: selectedOptions || []
                    });
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "var(--secondary-color)",
                      primary25: "var(--tertiary-color)",
                    },
                  })}
                  value={actionData.reservations}
                  placeholder={propertyReservations && propertyReservations.length > 0 ? 
                    "Επιλέξτε κρατήσεις" : 
                    "Δεν υπάρχουν διαθέσιμες κρατήσεις"}
                  isMulti
                  noOptionsMessage={() => "Δεν υπάρχουν διαθέσιμες κρατήσεις"}
                />
                <FormHelperText>
                  Συνδέστε αυτή την ενέργεια με μία ή περισσότερες κρατήσεις
                </FormHelperText>
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="group">
                Ομάδα
              </label>
              <Select
                isDisabled={view || isPendingAction}
                options={groups
                  .filter((group) => {
                    return !actionData.group
                      ? true
                      : group._id !== actionData.group.value._id;
                  })
                  .map((group) => ({
                    value: group,
                    label: group.name,
                    users: group.users,
                  }))}
                onChange={(selectedOption) => {
                  handleGroupChange(selectedOption);
                }}
                value={actionData.group}
                placeholder="Επιλέξτε ομάδα"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="assigned_to">
                Ανατεθειμένοι
              </label>
              <Select
                isDisabled={view || isPendingAction} // Disable if pending action
                options={users
                  .filter((user) => {
                    return !actionData.assigned_to
                      ? true
                      : !actionData.assigned_to
                          .map((user) => user.value._id)
                          .includes(user._id);
                  })
                  .map((user) => ({
                    value: user,
                    label: user.username,
                    tooltip: user.name,
                  }))}
                onChange={(selectedOption) => {
                  console.log(selectedOption);
                  setUsersOptions(
                    users
                      .filter(
                        (user) =>
                          !selectedOption
                            .map((option) => option.value._id)
                            .includes(user._id)
                      )
                      .map((user) => ({
                        value: user,
                        label: user.username,
                        tooltip: user.name,
                      }))
                  );
                  setActionData({
                    ...actionData,
                    assigned_to: selectedOption,
                  });
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                value={actionData.assigned_to}
                placeholder="Επιλέξτε χρήστες"
                isMulti
              />
            </div>
          </div>
{
  user.permissions.canViewPrices &&
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="price">
                Τιμή
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="text"
                name="price"
                value={actionData.price}
                onChange={handleInputChange}
              />
            </div>
          </div>}

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="planned_start_date">
                Ημερομηνία <span style={{color: "red"}}>*</span>
              </label>
              <input
                disabled={view}
                className="form-field-input"
                type="datetime-local"
                name="planned_start_date"
                value={actionData.planned_start_date}
                onChange={handleInputChange}
              />
              {formErrors.planned_start_date && (
                <div className="error-message">{formErrors.planned_start_date}</div>
              )}
            </div>
          </div>

          {edit && (
            <>
              <div className="form-group">
                <div className="form-field">
                  <label className="form-field-label" htmlFor="start_date">
                    Ημερομηνία Έναρξης
                  </label>
                  <input
                    disabled={view}
                    className="form-field-input"
                    type="datetime-local"
                    name="start_date"
                    value={actionData.start_date}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="form-field">
                  <label className="form-field-label" htmlFor="end_date">
                    Ημερομηνία Λήξης
                  </label>
                  <input
                    disabled={view}
                    className="form-field-input"
                    type="datetime-local"
                    name="end_date"
                    value={actionData.end_date}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </>
          )}

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="estimated_hours">
                Εκτιμώμενες Ώρες
              </label>
              <input
                disabled={true}
                className="form-field-input"
                type="number"
                name="estimated_hours"
                value={actionData.estimated_hours || "0.00"} // Format to 2 decimal places
                readOnly
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="notes">
                Σημειώσεις
              </label>
              <textarea
                disabled={view}
                className="form-field-input"
                name="notes"
                value={actionData.notes}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div
            className="form-group"
            style={{
              display: view ? "none" : "block",
            }}
          >
            <div className="form-field">
              <button
                className="add-ticket-button__button"
                style={{
                  backgroundColor: props.color || "var(--primary-color)",
                  color: props.textColor || "var(--primary-text-color)",
                  fontSize: props.fontSize || "15px",
                  fontWeight: props.fontWeight || "600",
                  width: props.width || "100%",
                }}
                type="submit"
              >
                {edit ? "Ενημέρωση" : "Δημιουργία"}
              </button>
            </div>
          </div>
        </form>
      )}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </div>
  );
};

export default AddActionForm;
