import React, { useState, useEffect } from "react";
import { LoginForm, SignUpForm } from "../../components";
import logo from "../../images/logo-white.svg";

import "./AuthForms.css";
const AuthForms = (props) => {
  const [isLogin, setIsLogin] = useState(props.isLogin || true);




  return (
    <div>

        <div className='logo'>
        {/* <img src={logo} alt="logo" /> */}
        </div>
            
      {isLogin ? (
        <LoginForm setIsLogin={setIsLogin} />
      ) : (
        <SignUpForm setIsLogin={setIsLogin} />
      )}
    
    </div>
  );
};

export default AuthForms;
