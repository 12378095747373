import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";

import "./Actions.css";

import {
  ImportReservationButton,
  Sidebar,
  TaskManager,
  ReservationTable,
  Loader,

  ActionsTable,
  AddActionButton,
} from "../../components";
import { Add, Task } from "@mui/icons-material";
import { PageStructure } from "../../pages";

const Actions = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageStructure>
      <h1>Δραστηριότητες</h1>

      {isLoading && <Loader />}
      <div
        style={
          {
            // visibility: isLoading ? "hidden" : "visible",
          }
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            overflowX: "auto",
          }}
        >
          <AddActionButton />
        </div>

        <ActionsTable
          searchStartDate={window.location.href.includes("pending") ? new Date() : ""}
          searchEndDate={window.location.href.includes("completed") ? new Date() : ""}
        />
      </div>
    </PageStructure>
  );
};

export default Actions;
