import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  CircularProgress,
  Alert,
  Chip,
  Avatar,
  Tooltip,
  IconButton,
  Snackbar
} from '@mui/material';
import { DragIndicator, Save } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './GroupDailyActions.css';

const GroupDailyActions = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [orderedActions, setOrderedActions] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    fetchGroups();
    fetchUsers();
    fetchProperties();
  }, []);

  useEffect(() => {
    if (selectedGroup && selectedDate) {
      fetchActions();
    }
  }, [selectedGroup, selectedDate]);

  useEffect(() => {
    // Apply time ordering when actions change
    if (actions.length > 0) {
      const sortedActions = [...actions].sort((a, b) => {
        // First sort by planned_start_date
        const dateA = new Date(a.planned_start_date).getTime();
        const dateB = new Date(b.planned_start_date).getTime();
        return dateA - dateB;
      });
    
      
      // Now add sequential times to ensure proper ordering
      const timezoneOffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
      const baseDate = new Date(selectedDate);
      baseDate.setHours(0, 0, 0, 0);
      const baseTime = baseDate.getTime() - timezoneOffset;
      
      const orderedActionsWithTime = sortedActions.map((action, index) => {
        // Add 1 second for each index (1000 ms)
        const adjustedTime = new Date(baseTime + (index * 1000));
        return {
          ...action,
          displayOrder: index + 1,
          orderedTime: adjustedTime
        };
      });
      
      setOrderedActions(orderedActionsWithTime);
    }
    else {
      setOrderedActions([]);
    }
  }, [actions, selectedDate]);

  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        'https://maliarakis-server.rhodesislandpass.com/group/getGroups',
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setGroups(response.data.groups);
      // If groups are loaded and none is selected yet, select the first one by default
      if (response.data.groups.length > 0 && !selectedGroup) {
        setSelectedGroup(response.data.groups[0]._id);
      }
    } catch (err) {
      console.error('Error fetching groups:', err);
      setError('Failed to load groups. Please try again.');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://maliarakis-server.rhodesislandpass.com/auth/getUsers', {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setUsers(response.data.users);
    } catch (err) {
      console.error('Error fetching users:', err);
      // We don't set an error here to not block the main functionality
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get('https://maliarakis-server.rhodesislandpass.com/property/getProperties', {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setProperties(response.data.properties);
    } catch (err) {
      console.error('Error fetching properties:', err);
      // We don't set an error here to not block the main functionality
    }
  };

  const fetchActions = async () => {
    if (!selectedGroup || !selectedDate) return;

    setLoading(true);
    setError(null);
    
    // Format date as YYYY-MM-DD and add timezone offset
    const formattedDate = formatDateForServer(selectedDate);
    const timezoneOffset = new Date().getTimezoneOffset();
    
    try {
      console.log(`Fetching actions for date: ${formattedDate} and group: ${selectedGroup}`);
      console.log(`Client timezone offset: ${timezoneOffset} minutes`);
      
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/action/getActionsByDayAndGroup?date=${formattedDate}&group=${selectedGroup}&timezone=${timezoneOffset}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      setActions(response.data.actions);
    } catch (err) {
      console.error('Error fetching actions:', err);
      setError('Failed to load actions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Format date for server as YYYY-MM-DD in local time zone
  const formatDateForServer = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    if (dateValue) {
      try {
        const newDate = new Date(dateValue);
        if (!isNaN(newDate.getTime())) {
          setSelectedDate(newDate);
        }
        console.log('Selected date:', newDate);
      } catch (err) {
        console.error('Invalid date format', err);
      }
    }
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const getPropertyName = (propertyId) => {
    const property = properties.find(p => p._id === propertyId);
    return property ? property.name : 'Unknown Property';
  };

  const getStatusClass = (action) => {
    if (action.end_date) return 'completed';
    if (action.start_date) return 'in-progress';
    return 'not-started';
  };

  const getStatusText = (action) => {
    if (action.end_date) return 'Completed';
    if (action.start_date) return 'In Progress';
    return 'Not Started';
  };

  const calculateDuration = (startDate, endDate) => {
    if (!startDate || !endDate) return '-';
    
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffMs = end - start;
    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMins = Math.round((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    
    return `${diffHrs}h ${diffMins}m`;
  };

  const selectedGroupName = selectedGroup ? 
    groups.find(group => group._id === selectedGroup)?.name : 'All Groups';

  const getFormattedDateForInput = () => {
    const d = selectedDate;
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(orderedActions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Re-assign display order
    const updatedItems = items.map((item, index) => ({
      ...item,
      displayOrder: index + 1
    }));

    setOrderedActions(updatedItems);
    setHasChanges(true);
  };

  const saveOrderChanges = async () => {
    try {
      const formattedDate = formatDateForServer(selectedDate);
      const timezoneOffset = new Date().getTimezoneOffset();
      
      // Parse the selected date to create base date for time adjustments
      const [year, month, day] = formattedDate.split('-').map(num => parseInt(num));
      const baseDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0) + (timezoneOffset * 60000));
      
      // Create the update promises for each action
      const updatePromises = orderedActions.map((action, index) => {
        // Calculate the new planned_start_date by adding seconds based on index
        const newPlannedDate = new Date(baseDate.getTime() + (index * 1000));
        
        return axios.put(
          `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${action._id}`,
          { planned_start_date: newPlannedDate },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              'Content-Type': 'application/json'
            }
          }
        );
      });
      
      // Execute all update requests
      await Promise.all(updatePromises);
      
      setSnackbarMessage('Order saved successfully');
      setSnackbarOpen(true);
      setHasChanges(false);
      
      // Refresh actions to get updated order
      fetchActions();
    } catch (error) {
      console.error('Error saving order:', error);
      setSnackbarMessage('Failed to save order changes');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="group-daily-actions">
      <Paper elevation={3} className="control-panel">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={5}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="group-select-label">Group</InputLabel>
              <Select
                labelId="group-select-label"
                id="group-select"
                value={selectedGroup}
                onChange={handleGroupChange}
                label="Group"
              >
                {groups.map((group) => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              id="date-select"
              label="Select Date"
              type="date"
              defaultValue={getFormattedDateForInput()}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            {hasChanges && (
              <IconButton 
                color="primary" 
                onClick={saveOrderChanges}
                className="save-order-button"
              >
                <Save />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Box my={3}>
        <Typography variant="h5" gutterBottom>
          Actions for {selectedGroupName} on {formatDate(selectedDate)}
        </Typography>
      </Box>

      {error && <Alert severity="error" sx={{ my: 2 }}>{error}</Alert>}

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : orderedActions.length === 0 ? (
        <Alert severity="info">No actions found for this group on the selected date.</Alert>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="actions-table">
            {(provided) => (
              <TableContainer 
                component={Paper} 
                className="actions-table" 
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="50px"></TableCell>
                      <TableCell width="50px">#</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>Property</TableCell>

                      <TableCell>Planned Date</TableCell>
                      <TableCell>Start Time</TableCell>
                      <TableCell>End Time</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderedActions.map((action, index) => (
                      <Draggable 
                        key={action._id} 
                        draggableId={action._id} 
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow 
                            key={action._id} 
                            className={`${getStatusClass(action)} ${snapshot.isDragging ? 'dragging' : ''}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <TableCell {...provided.dragHandleProps}>
                              <DragIndicator />
                            </TableCell>
                            <TableCell>{action.displayOrder}</TableCell>
                            <TableCell>{action.action_option?.name || 'Unknown'}</TableCell>
                            <TableCell>{getPropertyName(action.property)}</TableCell>
                    
                            <TableCell>{formatDate(action.planned_start_date)}</TableCell>
                            <TableCell>{action.start_date ? new Date(action.start_date).toLocaleTimeString() : '-'}</TableCell>
                            <TableCell>{action.end_date ? new Date(action.end_date).toLocaleTimeString() : '-'}</TableCell>
                            <TableCell>{calculateDuration(action.start_date, action.end_date)}</TableCell>
                            <TableCell>
                              <Chip 
                                label={getStatusText(action)} 
                                className={`status-chip ${getStatusClass(action)}`} 
                              />
                            </TableCell>
                            <TableCell>{action.notes || '-'}</TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Droppable>
        </DragDropContext>
      )}
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default GroupDailyActions;
