import React from 'react';

const ReservationTooltip = ({ reservations }) => {
  if (!reservations || reservations.length === 0) return null;
  
  // Format dates in a readable format
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short', 
      day: 'numeric'
    });
  };

  // Calculate nights based on check-in and check-out dates
  const calculateNights = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  // SVG paths
  const ADULT_SVG = 'M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z';
  const CHILD_SVG = 'M96 64a64 64 0 1 1 128 0A64 64 0 1 1 96 64zm48 320v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V287.8L59.1 321c-9.4 15-29.2 19.4-44.1 10s-19.4-29.2-10-44.1l51.7-82.1c17.6-27.9 48.3-44.9 81.2-44.9h12.3c33 0 63.7 16.9 81.2 44.9L283.1 287c9.4 15 4.9 34.7-10 44.1s-34.7 4.9-44.1-10L208 287.8V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V384H144z';
  const NIGHT_SVG = 'M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z';

  return (
    <div className="reservation-tooltip">
      <h4>Reservations ({reservations.length})</h4>
      {reservations.map((reservation, index) => {
        // Extract guest information
        const adults = reservation.adults || reservation.numAdults || 1;
        const children = reservation.kids || reservation.numChildren || 0;
        const nights = calculateNights(
          reservation.startDate || reservation.checkIn, 
          reservation.endDate || reservation.checkOut
        );
        
        return (
          <div key={reservation._id || index} className="reservation-item">
            <h5>Reservation {index + 1} {reservation._id && `(ID: ${reservation.reservationId})`}</h5>
            
            <div className="reservation-details">
              <p>
                <strong>Check-in:</strong> {formatDate(reservation.startDate || reservation.checkIn)}
              </p>
              
              <p>
                <strong>Check-out:</strong> {formatDate(reservation.endDate || reservation.checkOut)}
              </p>
              
              <div className="guest-stats">
                <div className="stat-item">
                  <span className="icon-container adult-icon">
                    <svg width="16" height="16" viewBox="0 0 448 512">
                      <path fill="#333" d={ADULT_SVG} />
                    </svg>
                  </span>
                  <span className="count">{adults}</span>
                </div>
                
                {children > 0 && (
                  <div className="stat-item">
                    <span className="icon-container child-icon">
                      <svg width="14" height="16" viewBox="0 0 320 512">
                        <path fill="#333" d={CHILD_SVG} />
                      </svg>
                    </span>
                    <span className="count">{children}</span>
                  </div>
                )}
                
                <div className="stat-item">
                  <span className="icon-container night-icon">
                    <svg width="16" height="16" viewBox="0 0 384 512">
                      <path fill="#333" d={NIGHT_SVG} />
                    </svg>
                  </span>
                  <span className="count">{nights}</span>
                </div>
              </div>
            </div>

            
            {reservation.customerNotes && (
              <div className="notes">
                <span className="icon notes-icon">📝</span>
                <span className="note-text">{reservation.customerNotes}</span>
              </div>
            )}
            
            {index < reservations.length - 1 && <hr className="reservation-divider" />}
          </div>
        );
      })}
    </div>
  );
};

export default ReservationTooltip;
