import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./Calendar.css";
import axios from "axios";
import io from "socket.io-client";
import { AddActionForm, PopUpWindow, ViewActionsForm } from "../../components";
import Select from 'react-select';
// Import custom components
import CalendarTable from './CalendarTable';
import FilterChip from './CalendarFilters';
import UserChip from './UserChip';
import GroupChip from './GroupChip'; // Import the new GroupChip component
import ReservationTooltip from './ReservationTooltip';

// Import helper functions
import { 
  formatDateString, 
  formatDate, 
  formatDateData,
  normalizeDateForComparison, 
  hasUnassignedActions,
  hasPendingActions,
  getPropertyStatus,
  filterProperties
} from './CalendarHelpers';

// Import Material UI components
import {
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// Import Material UI icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// Initialize socket connection
const socket = io("https://maliarakis-server.rhodesislandpass.com/");

function CalendarView() {
  // State variables for data
  const [data, setData] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [actionsOptions, setActionsOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [actions, setActions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  
  // User and date filtering
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null); // Add state for selected group
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [propertyFilters, setPropertyFilters] = useState(["all"]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // Reservation handling
  const [actionReservationsMap, setActionReservationsMap] = useState({});
  const [propertyReservationsOptions, setPropertyReservationsOptions] = useState([]);

  // Action form handling
  const [createActionPopUpWindowIsOpen, setCreateActionPopUpWindowIsOpen] = useState(false);
  const [createActionSelectedDate, setCreateActionSelectedDate] = useState(new Date());
  const [createActionSelectedProperty, setCreateActionSelectedProperty] = useState(null);

  // View actions form
  const [viewActionsPopUpWindowIsOpen, setViewActionsPopUpWindowIsOpen] = useState(false);
  const [viewActionsSelectedDate, setViewActionsSelectedDate] = useState(new Date());
  const [viewActionsSelectedActions, setViewActionsSelectedActions] = useState([]);
  
  // Date selection
  const [selectedDate, setSelectedDate] = useState(new Date());
  
  // Get user from local storage
  const user = JSON.parse(localStorage.getItem("user"));

  // Generate dates array (30 days starting from selectedDate)
  const [dates, setDates] = useState(
    Array.from(
      { length: 30 },
      (_, i) =>
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + i
        )
    )
  );

  // Dialog state for drag and drop
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingDragAction, setPendingDragAction] = useState(null);
  const [targetCellReservations, setTargetCellReservations] = useState([]);
  const [showReservationOptions, setShowReservationOptions] = useState(false);

  // Reservation tooltip state
  const [showReservationDetails, setShowReservationDetails] = useState(false);
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  
  // User stats
  const [groupActionCounts, setGroupActionCounts] = useState([]);
  const [userActionCounts, setUserActionCounts] = useState([]);
  const [unassignedActionsCount, setUnassignedActionsCount] = useState(0);

  // Data fetching functions
  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setUsers(response.data.users);
    } catch (error) {
      // Handle error silently
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get("https://maliarakis-server.rhodesislandpass.com/group/getGroups", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setGroups(response.data.groups);
    } catch (error) {
      // Handle error silently
    }
  };

  const fetchActionsOptions = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/action-option/getActionOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setActionsOptions(response.data.actionOptions);
    } catch (error) {
      // Handle error silently
    }
  };

  const fetchProperties = async () => {
    try {
      // Use the cleaning properties endpoint which already filters by user access
      const response = await axios.get("https://maliarakis-server.rhodesislandpass.com/property/getPropertiesWithAnalytics", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      
      const filteredProperties = response.data.properties || [];
      setProperties(filteredProperties);
      return filteredProperties; // Return properties for chaining
    } catch (error) {
      console.error("Error fetching properties:", error);
      setProperties([]);
      return []; // Return empty array on error
    }
  };

  const fetchReservations = async (propertyIds) => {
    try {
      if (!propertyIds || propertyIds.length === 0) {
        setReservations([]);
        return;
      }
      
      const startDate = dates[0].toISOString();
      const endDate = dates[dates.length - 1].toISOString();
      
      // Convert the array of property IDs to a query parameter
      const propertyIdParam = propertyIds.join(',');
      
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/reservation/getReservationByDateRange?startDate=${startDate}&endDate=${endDate}&propertyIds=${propertyIdParam}`, 
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setReservations(response.data.reservations || []);
    } catch (error) {
      console.error("Error fetching reservations:", error);
      setReservations([]); // Set empty array on error
    }
  };

  const fetchActions = async (propertyIds) => {
    try {
      if (!propertyIds || propertyIds.length === 0) {
        setActions([]);
        return;
      }
      
      const propertyIdParam = propertyIds.join(',');
      const startDate = dates[0].toISOString();
      const endDate = dates[dates.length - 1].toISOString();
      
      const response = await axios.get(
        `https://maliarakis-server.rhodesislandpass.com/action/getActionByRange?startDate=${startDate}&endDate=${endDate}&propertyIds=${propertyIdParam}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      setActions(response.data.actions || []);
    } catch (error) {
      console.error("Error fetching actions:", error);
      setActions([]);
    }
  };

  const fetchReservationsForActions = async (actionIds, propertyId) => {
    try {
      const reservationsMap = {};
      const propertyReservations = [];
      
      // First, fetch all property reservations
      if (propertyId) {
        try {
          const response = await axios.get(
            `https://maliarakis-server.rhodesislandpass.com/reservation/getReservationByProperty/${propertyId}`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          
          if (response.data && response.data.reservations) {
            const formattedReservations = response.data.reservations.map(res => ({
              value: res._id,
              label: `${res.reservationId || "No ID"} - ${res.firstName || "Guest"} ${res.lastName || ""} (${formatDate(res.checkIn || res.startDate)} to ${formatDate(res.checkOut || res.endDate)})`,
              reservation: res
            }));
            propertyReservations.push(...formattedReservations);
          }
        } catch (error) {
          console.error(`Failed to fetch reservations for property ${propertyId}:`, error);
        }
      }
      
      // Then fetch specific action reservations
      const fetchPromises = actionIds.map(async (actionId) => {
        try {
          const response = await axios.get(
            `https://maliarakis-server.rhodesislandpass.com/action/getActionReservations/${actionId}`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          
          if (response.data && response.data.reservations) {
            reservationsMap[actionId] = response.data.reservations;
          }
        } catch (error) {
          reservationsMap[actionId] = []; // Empty array on error
        }
      });
      
      await Promise.all(fetchPromises);
      
      // Update state
      setActionReservationsMap(reservationsMap);
      setPropertyReservationsOptions(propertyReservations);
      
      return { actionReservations: reservationsMap, propertyReservations };
    } catch (error) {
      return { actionReservations: {}, propertyReservations: [] };
    }
  };

  const fetchLocationOptions = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/location-option/getLocationOptions",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      const options = [
        { value: null, label: 'Όλες οι τοποθεσίες' },
        ...response.data.locationOptions.map(location => ({
          value: location._id,
          label: location.name
        }))
      ];
      
      setLocationOptions(options);
    } catch (error) {
      console.error("Error fetching location options:", error);
    }
  };

  // Initial data fetch - modified to fetch sequentially
  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        // First fetch properties
        const fetchedProperties = await fetchProperties();
        
        // Extract property IDs
        const propertyIds = fetchedProperties.map(prop => prop._id);
        
        // Then fetch the rest in parallel, using the property IDs
        await Promise.all([
          fetchLocationOptions(),
          fetchActionsOptions(),
          fetchGroups(),
          fetchUsers(),
          fetchReservations(propertyIds),
          fetchActions(propertyIds)
        ]);
      } catch (error) {
        console.error("Error in initial data fetch:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  // Fetch data when dates change
  useEffect(() => {
    if (!isLoading && properties.length > 0) {
      const propertyIds = properties.map(prop => prop._id);
      fetchReservations(propertyIds);
      fetchActions(propertyIds);
    }
  }, [dates, properties]);

  // Socket event handlers
  const handleActionCreated = (newAction) => {
    if (!newAction || !newAction.planned_start_date) return;
    
    try {
      const actionDate = new Date(newAction.planned_start_date);
      const startDate = dates[0];
      const endDate = dates[dates.length - 1];
      
      // Get property ID safely
      const propertyId = typeof newAction.property === 'object' && newAction.property !== null
        ? newAction.property._id 
        : (newAction.property || null);
      
      // Only add actions for properties that the user can access
      const userHasAccess = properties.some(prop => prop._id === propertyId);
      
      if (actionDate >= startDate && actionDate <= endDate && userHasAccess) {
        // Extract action details safely with null checks
        let actionName = "Unknown Action";
        let actionOptionId = null;
        let actionColor = "#3498db"; // Default blue color
        
        if (newAction.action_option) {
          if (typeof newAction.action_option === 'object' && newAction.action_option !== null) {
            actionName = newAction.action_option.name || "Unknown Action";
            actionOptionId = newAction.action_option._id || null;
            actionColor = newAction.action_option.color || "#3498db";
          } else {
            const match = actionsOptions.find(opt => opt._id === newAction.action_option);
            actionName = match?.name || "Unknown Action";
            actionColor = match?.color || "#3498db";
            actionOptionId = newAction.action_option;
          }
        }
        
        // Get property ID safely
        const propertyId = typeof newAction.property === 'object' && newAction.property !== null
          ? newAction.property._id 
          : (newAction.property || null);
        
        if (!propertyId) return;
        
        // Safely extract group ID
        const groupId = typeof newAction.group === 'object' && newAction.group !== null
          ? newAction.group._id
          : (newAction.group || null);
        
        // Process assigned_to array safely
        let assignedToArray = [];
        if (Array.isArray(newAction.assigned_to)) {
          assignedToArray = newAction.assigned_to.map(user => 
            typeof user === 'object' && user !== null ? user._id : user
          ).filter(id => id !== null && id !== undefined);
        }
        
        const formattedAction = {
          _id: newAction._id,
          planned_start_date: new Date(newAction.planned_start_date),
          date: new Date(newAction.planned_start_date),
          action_option: actionOptionId,
          name: actionName,
          property: propertyId,
          group: groupId,
          assigned_to: assignedToArray,
          price: newAction.price || 0,
          notes: newAction.notes || "",
          estimated_hours: newAction.estimated_hours || 0,
          color: actionColor
        };
        
        setActions(prevActions => [...prevActions, formattedAction]);
      }
    } catch (error) {
      console.error("Error processing new action:", error, newAction);
    }
  };

  const handleActionUpdated = (updatedAction) => {
    if (!updatedAction) return;
    
    try {
      // Extract action details safely with null checks
      let actionName = "Unknown Action";
      let actionOptionId = null;
      let actionColor = "#3498db"; // Default blue color
      
      if (updatedAction.action_option) {
        if (typeof updatedAction.action_option === 'object' && updatedAction.action_option !== null) {
          actionName = updatedAction.action_option.name || "Unknown Action";
          actionOptionId = updatedAction.action_option._id || null;
          actionColor = updatedAction.action_option.color || "#3498db";
        } else {
          const match = actionsOptions.find(opt => opt._id === updatedAction.action_option);
          actionName = match?.name || "Unknown Action";
          actionColor = match?.color || "#3498db";
          actionOptionId = updatedAction.action_option;
        }
      }
      
      // Get property ID safely
      const propertyId = typeof updatedAction.property === 'object' && updatedAction.property !== null
        ? updatedAction.property._id 
        : (updatedAction.property || null);
      
      // Only update actions for properties that the user can access
      const userHasAccess = properties.some(prop => prop._id === propertyId);
      
      if (!userHasAccess) return;
      
      // Safely extract group ID
      const groupId = typeof updatedAction.group === 'object' && updatedAction.group !== null
        ? updatedAction.group._id
        : (updatedAction.group || null);
      
      // Process assigned_to array safely
      let assignedToArray = [];
      if (Array.isArray(updatedAction.assigned_to)) {
        assignedToArray = updatedAction.assigned_to.map(user => 
          typeof user === 'object' && user !== null ? user._id : user
        ).filter(id => id !== null && id !== undefined);
      }
      
      const formattedAction = {
        ...updatedAction,
        planned_start_date: new Date(updatedAction.planned_start_date || new Date()),
        date: new Date(updatedAction.planned_start_date || new Date()),
        action_option: actionOptionId,
        name: actionName,
        property: propertyId,
        group: groupId,
        assigned_to: assignedToArray,
        estimated_hours: updatedAction.estimated_hours || 0,
        color: actionColor
      };

      setActions(prevActions => 
        prevActions.map(action => 
          action._id === updatedAction._id ? formattedAction : action
        )
      );
    } catch (error) {
      console.error("Error processing updated action:", error, updatedAction);
    }
  };

  const handleActionDeleted = (actionId) => {
    if (!actionId) return;
    setActions(prevActions => prevActions.filter(action => action._id !== actionId));
  };

  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
  };

  // Socket handlers for real-time updates
  useEffect(() => {
    if (!isLoading) {
      socket.on("actionCreated", handleActionCreated);
      socket.on("actionUpdated", handleActionUpdated);
      socket.on("actionDeleted", handleActionDeleted);

      return () => {
        socket.off("actionCreated");
        socket.off("actionUpdated");
        socket.off("actionDeleted");
      };
    }
  }, [isLoading, dates, actionsOptions]);

  // Process data for UI
  useEffect(() => {
    if (!isLoading && properties.length > 0) {
      // Create a mapping of property IDs to property objects for faster lookup
      const propertyMap = {};
      properties.forEach(property => {
        propertyMap[property._id] = {
          ...property,
          actions: [],
          reservations: []
        };
      });

      // Process reservations and add them to the corresponding properties
      reservations.forEach(reservation => {
        if (!propertyMap[reservation.property]) return;

        const formattedReservation = {
          ...reservation,
          startDate: new Date(reservation.checkIn),
          endDate: new Date(reservation.checkOut)
        };

        propertyMap[reservation.property].reservations.push(formattedReservation);
      });

      // Process actions and add them to the corresponding properties
      actions.forEach(action => {
        if (!action || !action.property || !propertyMap[action.property]) return;
        
        // Determine the action color
        let actionColor = action.color;
        
        if (!actionColor && action.action_option) {
          const optionId = typeof action.action_option === 'object' ? 
            action.action_option._id : action.action_option;
            
          const matchingOption = actionsOptions.find(opt => opt._id === optionId);
          actionColor = matchingOption?.color || "#3498db";
        }
        
        // Format the action with consistent date fields, name, and color
        const formattedAction = {
          ...action,
          date: new Date(action.planned_start_date || new Date()),
          color: actionColor || "#3498db",
          name: action.action_option?.name || action.name || "Unknown Action"
        };
        
        // Apply both user and group filtering
        let shouldInclude = true;
        
        if (selectedUser) {
          if (selectedUser._id === 'unassigned') {
            shouldInclude = !action.assigned_to || action.assigned_to.length === 0;
          } else {
            shouldInclude = action.assigned_to && action.assigned_to.includes(selectedUser._id);
          }
        }
        
        // Apply group filtering if a group is selected
        if (shouldInclude && selectedGroup) {
          const actionGroupId = typeof action.group === 'object' ? action.group?._id : action.group;
          shouldInclude = actionGroupId === selectedGroup._id;
        }
        
        if (shouldInclude) {
          propertyMap[action.property].actions.push(formattedAction);
        }
      });
      
      // Convert the property map back to an array
      const processedData = Object.values(propertyMap);
      
      // Update the data state
      setData(processedData);
    }
  }, [actions, properties, reservations, actionsOptions, isLoading, selectedUser, selectedGroup, searchTerm]);

  // Calculate group action counts
  useEffect(() => {
    if (!isLoading && groups.length > 0 && actions.length > 0) {
      const isActionInRange = (action) => {
        const actionDate = new Date(action.planned_start_date);
        return actionDate >= dates[0] && actionDate <= dates[dates.length - 1];
      };

      const counts = groups.map(group => {
        const groupActions = actions.filter(action => {
          const actionDate = new Date(action.planned_start_date);
          const matchesDate = selectedDateFilter ? 
            actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
            actionDate.getMonth() === selectedDateFilter.getMonth() &&
            actionDate.getDate() === selectedDateFilter.getDate() : true;
          
          return action.group && 
                 (typeof action.group === 'object' ? action.group._id === group._id : action.group === group._id) && 
                 matchesDate &&
                 isActionInRange(action);
        });

        const totalEstimatedHours = groupActions.reduce((total, action) => {
          return total + (action.estimated_hours || 0);
        }, 0);

        return {
          ...group,
          actionCount: groupActions.length,
          estimatedHours: totalEstimatedHours,
          actions: groupActions
        };
      }).filter(group => group.actionCount > 0)
        .sort((a, b) => b.actionCount - a.actionCount);
      
      setGroupActionCounts(counts);
    }
  }, [actions, groups, isLoading, selectedDateFilter, dates]);

  // Calculate user action counts - updated to respect selected group
  useEffect(() => {
    if (!isLoading && users.length > 0 && actions.length > 0) {
      const isActionInRange = (action) => {
        const actionDate = new Date(action.planned_start_date);
        return actionDate >= dates[0] && actionDate <= dates[dates.length - 1];
      };

      const counts = users.map(user => {
        const userActions = actions.filter(action => {
          const actionDate = new Date(action.planned_start_date);
          const matchesDate = selectedDateFilter ? 
            actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
            actionDate.getMonth() === selectedDateFilter.getMonth() &&
            actionDate.getDate() === selectedDateFilter.getDate() : true;
          
          // Filter by user AND selected group if a group is selected
          const matchesUser = action.assigned_to && action.assigned_to.includes(user._id);
          
          let matchesGroup = true;
          if (selectedGroup) {
            const actionGroupId = typeof action.group === 'object' ? action.group?._id : action.group;
            matchesGroup = actionGroupId === selectedGroup._id;
          }
          
          return matchesUser && matchesGroup && matchesDate && isActionInRange(action);
        });

        const totalEstimatedHours = userActions.reduce((total, action) => {
          return total + (action.estimated_hours || 0);
        }, 0);

        return {
          ...user,
          actionCount: userActions.length,
          estimatedHours: totalEstimatedHours,
          actions: userActions
        };
      }).filter(user => user.actionCount > 0)
        .sort((a, b) => b.actionCount - a.actionCount);
      
      setUserActionCounts(counts);
    }
  }, [actions, users, isLoading, selectedDateFilter, dates, selectedGroup]);

  // Calculate unassigned actions count
  useEffect(() => {
    if (!isLoading && actions.length > 0) {
      const unassignedCount = actions.filter(action => {
        const actionDate = new Date(action.planned_start_date);
        return (!action.assigned_to || action.assigned_to.length === 0) &&
               actionDate >= dates[0] && 
               actionDate <= dates[dates.length - 1];
      }).length;
      setUnassignedActionsCount(unassignedCount);
    }
  }, [actions, dates, isLoading]);

  // Handle date changes
  const changeDate = (offset) => {
    setDates(
      dates.map(
        (date) =>
          new Date(date.getFullYear(), date.getMonth(), date.getDate() + offset)
      )
    );
  };

  // Handle date column click
  const handleDateColumnClick = (date) => {
    setSelectedDateFilter(selectedDateFilter?.getTime() === date.getTime() ? null : date);
  };

  // Handle date cell click
  const handleDateCellClick = async (date, property, actions, event, reservationsOnDate) => {
    if (reservationsOnDate && reservationsOnDate.length > 0) {
      setSelectedReservations(reservationsOnDate);
      
      const rect = event.target.getBoundingClientRect();
      setPopupPosition({
        x: rect.left + window.scrollX,
        y: rect.bottom + window.scrollY
      });
      
      setShowReservationDetails(true);
    } else {
      setShowReservationDetails(false);
      setSelectedReservations([]);
    }
    
    if (property && actions && actions.length > 0) {
      const actionIds = actions.map((action) => action._id);
      
      setViewActionsPopUpWindowIsOpen(true);
      setViewActionsSelectedDate(date);
      setCreateActionSelectedProperty({
        value: {
          _id: property._id,
          name: property.name,
        },
        label: property.name,
      });
      
      const formattedActions = actions.map((action) => {
        return {
          _id: action._id,
          name: action.action_option?.name || action.name || "Unknown Action",
          action_option: action.action_option?._id || action.action_option,
          group: action.group,
          assigned_to: action.assigned_to,
          planned_start_date: action.planned_start_date,
          price: action.price,
          notes: action.notes,
          estimated_hours: action.estimated_hours
        };
      });
      
      setViewActionsSelectedActions(formattedActions);
      
      const { actionReservations, propertyReservations } = await fetchReservationsForActions(actionIds, property._id);
    
      setPropertyReservationsOptions(propertyReservations);
    }
  };

  // Click outside handler to close the reservation popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showReservationDetails && !event.target.closest('.reservation-tooltip')) {
        setShowReservationDetails(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showReservationDetails]);

  // Handle drag and drop
  const handleDrop = async (sourceHotelId, targetHotelId, oldDate, newDate, draggedActions) => {
    draggedActions = draggedActions || [];
    
    try {
      const sourceProperty = data.find((hotel) => hotel._id === sourceHotelId);
      if (!sourceProperty) return;
      
      const prevActions = sourceProperty.actions.filter((action) => 
        action.date.getDate() === oldDate.getDate() && 
        action.date.getMonth() === oldDate.getMonth() &&
        action.date.getFullYear() === oldDate.getFullYear()
      );
      
      if (prevActions.length === 0) return;

      const targetProperty = data.find((hotel) => hotel._id === targetHotelId);
      if (!targetProperty) return;
      
      const targetCellReservations = targetProperty?.reservations.filter(reservation => {
        const cellDate = new Date(
          newDate.getFullYear(), 
          newDate.getMonth(), 
          newDate.getDate()
        ).setHours(0, 0, 0, 0);
        
        const startDate = new Date(
          reservation.startDate.getFullYear(),
          reservation.startDate.getMonth(), 
          reservation.startDate.getDate()
        ).setHours(0, 0, 0, 0);
        
        const endDate = new Date(
          reservation.endDate.getFullYear(), 
          reservation.endDate.getMonth(), 
          reservation.endDate.getDate()
        ).setHours(0, 0, 0, 0);

        return cellDate >= startDate && cellDate <= endDate;
      }) || [];

      if (sourceHotelId !== targetHotelId) {
        const actionsWithReservations = [];
        
        for (const action of prevActions) {
          const reservations = actionReservationsMap[action._id];
          
          if (reservations && reservations.length > 0) {
            actionsWithReservations.push(action);
            continue;
          }
          
          try {
            const response = await axios.get(
              `https://maliarakis-server.rhodesislandpass.com/action/getActionReservations/${action._id}`,
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            );
            
            if (response.data.reservations && response.data.reservations.length > 0) {
              setActionReservationsMap(prev => ({
                ...prev,
                [action._id]: response.data.reservations
              }));
              
              actionsWithReservations.push(action);
            }
          } catch (error) {
            console.error(`Failed to check reservations for action ${action._id}:`, error);
          }
        }
        
        setPendingDragAction({
          sourceHotelId,
          targetHotelId,
          oldDate,
          newDate,
          prevActions
        });
        
        if (targetCellReservations.length > 0) {
          const formattedReservations = targetCellReservations.map(res => ({
            value: res._id,
            label: `${res.reservationId || ''} - ${res.firstName || 'Guest'} ${res.lastName || ''} (${formatDate(res.checkIn || res.startDate)} to ${formatDate(res.checkOut || res.endDate)})`,
            reservation: res
          }));
          
          setTargetCellReservations(formattedReservations);
          setShowReservationOptions(true);
          setConfirmDialogOpen(true);
          return;
        }
        
        if (actionsWithReservations.length > 0) {
          setTargetCellReservations([]);
          setShowReservationOptions(false);
          setConfirmDialogOpen(true);
          return;
        }
      }
      
      await executeDragDrop(sourceHotelId, targetHotelId, oldDate, newDate, prevActions);
    } catch (error) {
      // Handle error silently
    }
  };

  // Execute drag and drop
  const executeDragDrop = async (sourceHotelId, targetHotelId, oldDate, newDate, prevActions, reservationOption = "keep") => {
    try {
      const actionIdsBeingMoved = prevActions.map(action => action._id);

      const normalizedOldDate = normalizeDateForComparison(oldDate);
      const normalizedNewDate = normalizeDateForComparison(newDate);
      
      const updatedData = data.map((hotel) => {
        if (hotel._id === sourceHotelId) {
          return {
            ...hotel,
            actions: hotel.actions.filter((action) => {
              const actionDateNormalized = normalizeDateForComparison(action.date);
              return actionDateNormalized !== normalizedOldDate || !actionIdsBeingMoved.includes(action._id);
            }),
          };
        } else if (hotel._id === targetHotelId) {
          const movedActions = prevActions.map((action) => {
            const newActionDate = new Date(newDate);
            
            return {
              ...action,
              date: newActionDate, 
              planned_start_date: newActionDate,
              property: targetHotelId,
            };
          });
          
          return {
            ...hotel,
            actions: [...hotel.actions, ...movedActions],
          };
        }
        return hotel;
      });

      setData(updatedData);

      for (const action of prevActions) {
        const updateData = {
          planned_start_date: newDate,
          property: targetHotelId,
        };

        if (sourceHotelId !== targetHotelId) {
          switch (reservationOption) {
            case "keep":
              break;
            case "add":
              const currentReservations = actionReservationsMap[action._id] || [];
              const currentIds = new Set(currentReservations.map(res => res._id));
              
              const newReservations = targetCellReservations
                .filter(res => !currentIds.has(res.value))
                .map(res => res.value);
                
              updateData.reservations = [
                ...currentReservations.map(res => res._id), 
                ...newReservations
              ];
              
              setActionReservationsMap(prev => ({
                ...prev,
                [action._id]: [
                  ...currentReservations,
                  ...targetCellReservations.map(res => res.reservation).filter(res => !currentIds.has(res._id))
                ]
              }));
              break;
            case "replace":
              updateData.reservations = targetCellReservations.map(res => res.value);
              
              setActionReservationsMap(prev => ({
                ...prev,
                [action._id]: targetCellReservations.map(res => res.reservation)
              }));
              break;
            case "remove":
              updateData.reservations = [];
              
              setActionReservationsMap(prev => ({
                ...prev,
                [action._id]: []
              }));
              break;
            default:
              break;
          }
        }

        try {
          await axios.put(
            `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${action._id}`,
            updateData,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          
        } catch (updateError) {
          // Handle error silently
        }
      }
      
    } catch (error) {
      fetchActions();
    }
  };

  // Handle dialog confirmation
  const handleReservationOption = (option) => {
    if (pendingDragAction) {
      const { sourceHotelId, targetHotelId, oldDate, newDate, prevActions } = pendingDragAction;
      executeDragDrop(sourceHotelId, targetHotelId, oldDate, newDate, prevActions, option);
    }
    
    setConfirmDialogOpen(false);
    setPendingDragAction(null);
    setTargetCellReservations([]);
    setShowReservationOptions(false);
  };

  // Filter properties based on location with improved error handling
  const filterByLocation = useCallback((propertiesArray) => {
    if (!propertiesArray) return [];
    
    // If no location filter is applied, return all properties
    if (!selectedLocation || selectedLocation.value === null) {
      return propertiesArray;
    }
    
    // Ensure we're comparing with correct types and handle null/undefined cases
    const locationId = selectedLocation.value.toString();
    
    return propertiesArray.filter(property => {
      if (!property || !property.location_id) return false;
      
      // Handle both string IDs and object references
      const propertyLocationId = 
        typeof property.location_id === 'object' && property.location_id !== null
          ? property.location_id._id
          : property.location_id;
      
      if (!propertyLocationId) return false;
      
      return propertyLocationId.toString() === locationId;
    });
  }, [selectedLocation]);

  // Combined filter function with improved error handling
  const filterPropertiesWithLocation = useCallback((propertiesArray) => {
    if (!propertiesArray) return [];
    
    // First apply location filter
    let filteredByLocation = filterByLocation(propertiesArray);
    
    // Then apply the standard property filters with proper parameters
    try {
      filteredByLocation = filterProperties(
        filteredByLocation || [], 
        propertyFilters || ["all"], 
        dates || [], 
        actions || []
      );
    } catch (error) {
      console.error("Error in filterProperties:", error);
      // Fallback to just the location filtered array if the other filter fails
    }
    
    // Finally filter by search term
    return filteredByLocation.filter((hotel) => {
      if (!hotel || !hotel.name) return false;
      return hotel.name.toLowerCase().includes((searchTerm || "").toLowerCase());
    });
  }, [filterByLocation, propertyFilters, dates, actions, searchTerm]);

  // Create a filtered data variable to use consistently across the component
  const locationFilteredData = useMemo(() => {
    return filterByLocation(data || []);
  }, [filterByLocation, data]);

  // Apply all filters to get the final data shown in the calendar
  const fullyFilteredData = useMemo(() => {
    return filterPropertiesWithLocation(data || []);
  }, [filterPropertiesWithLocation, data]);

  // Count getters for filter chips - all counts should be based on data after location filter
  const getPropertiesWithWarningsCount = useCallback(() => {
    return locationFilteredData.filter(p => {
      const status = getPropertyStatus(p, actions, dates);
      return status === 'warning' || status === 'critical';
    }).length;
  }, [locationFilteredData, actions, dates]);

  const getPropertiesWithPendingActionsCount = useCallback(() => {
    return locationFilteredData.filter(p => {
      return p.actions.some(action => {
        if (!action || !action.planned_start_date) return false;
        const actionDate = new Date(action.planned_start_date);
        return !action.action_option && 
               actionDate >= dates[0] && 
               actionDate <= dates[dates.length - 1];
      });
    }).length;
  }, [locationFilteredData, dates]);

  const getPropertiesWithReservationCount = useCallback(() => {
    return locationFilteredData.filter(p => {
      if (!p.reservations || !Array.isArray(p.reservations)) return false;
      const reservationsInRange = p.reservations.filter(res => {
        if (!res || !res.startDate || !res.endDate) return false;
        const resStartDate = new Date(res.startDate);
        const resEndDate = new Date(res.endDate);
        return resStartDate <= dates[dates.length - 1] && resEndDate >= dates[0];
      });
      return reservationsInRange.length > 0;
    }).length;
  }, [locationFilteredData, dates]);

  const getPropertiesWithoutReservationCount = useCallback(() => {
    return locationFilteredData.filter(p => {
      if (!p.reservations || !Array.isArray(p.reservations)) return false;
      const reservationsInRange = p.reservations.filter(res => {
        if (!res || !res.startDate || !res.endDate) return false;
        const resStartDate = new Date(res.startDate);
        const resEndDate = new Date(res.endDate);
        return resStartDate <= dates[dates.length - 1] && resEndDate >= dates[0];
      });
      return reservationsInRange.length === 0;
    }).length;
  }, [locationFilteredData, dates]);

  const getPropertiesWithActionCount = useCallback(() => {
    return locationFilteredData.filter(p => {
      if (!p.actions || !Array.isArray(p.actions)) return false;
      const actionsInRange = p.actions.filter(action => {
        if (!action || !action.planned_start_date) return false;
        const actionDate = new Date(action.planned_start_date);
        return actionDate >= dates[0] && actionDate <= dates[dates.length - 1];
      });
      return actionsInRange.length > 0;
    }).length;
  }, [locationFilteredData, dates]);

  const getPropertiesWithoutActionCount = useCallback(() => {
    return locationFilteredData.filter(p => {
      if (!p.actions || !Array.isArray(p.actions)) return false;
      const actionsInRange = p.actions.filter(action => {
        if (!action || !action.planned_start_date) return false;
        const actionDate = new Date(action.planned_start_date);
        return actionDate >= dates[0] && actionDate <= dates[dates.length - 1];
      });
      return actionsInRange.length === 0;
    }).length;
  }, [locationFilteredData, dates]);

  const getPropertiesWithUnassignedActionsCount = useCallback(() => {
    return locationFilteredData.filter(p => hasUnassignedActions(p)).length;
  }, [locationFilteredData]);

  return (
    <div className="calendar-wrapper">
      {/* Action Creation Popup */}
      {createActionPopUpWindowIsOpen && (
        <PopUpWindow
          onClose={() => setCreateActionPopUpWindowIsOpen(false)}
          unfocusClose={true}
        >
          <AddActionForm
            date={new Date(
              createActionSelectedDate.getFullYear(),
              createActionSelectedDate.getMonth(),
              createActionSelectedDate.getDate()
            )}
            property={{
              value: createActionSelectedProperty?.value,
              label: createActionSelectedProperty?.label,
              value: {
                ...createActionSelectedProperty?.value,
                actions_analytics: properties.find(p => p._id === createActionSelectedProperty?.value?._id)?.actions_analytics || [],
                actions_prices: properties.find(p => p._id === createActionSelectedProperty?.value?._id)?.actions_prices || []
              }
            }}
            reservationsOptions={
              createActionSelectedProperty?.value?._id
                ? reservations
                    .filter((res) => {
                      const resStart = new Date(res.checkIn);
                      const resEnd = new Date(res.checkOut);
                      
                      return res.property === createActionSelectedProperty.value._id && 
                             resStart <= dates[dates.length - 1] && 
                             resEnd >= dates[0];
                    })
                    .map(res => ({
                      value: res._id,
                      label: `${res.reservationId} - ${res.firstName} ${res.lastName} (${formatDate(res.checkIn)} to ${formatDate(res.checkOut)})`,
                      reservation: res
                    }))
                : []
            }
            initialReservations={createActionSelectedProperty?.selectedReservations || []}
          />
        </PopUpWindow>
      )}

      {/* Calendar Controls */}
      <div className="calendar-buttons-wrapper">
        <div className="calendar-buttons-days">
          <button className="calendar-button" onClick={() => changeDate(-15)}>
            Προηγούμενες 15
          </button>
          <button className="calendar-button" onClick={() => changeDate(15)}>
            Έπομενες 15
          </button>
        </div>

        <div className="calendar-buttons-search">
          <Tooltip title="Δίαλεξε Ημερομηνία">
            <input
              type="date"
              value={selectedDate.toISOString().split("T")[0]}
              onChange={(e) => {
                setSelectedDate(new Date(e.target.value));
                setDates(
                  Array.from(
                    { length: 30 },
                    (_, i) =>
                      new Date(
                        new Date(e.target.value).getFullYear(),
                        new Date(e.target.value).getMonth(),
                        new Date(e.target.value).getDate() + i
                      )
                  )
                );
              }}
              style={{
                height: "30px",
                width: "300px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                padding: "5px",
                margin: "0 10px",
                cursor: "pointer",
                backgroundColor: "#f8f8f8",
                color: "#333",
                outlineColor: "var(--primary-color)",
                fontFamily: "Arial",
              }}
            />
          </Tooltip>

          <div className="search-bar">
            <input
              type="text"
              placeholder="Αναζήτηση με όνομα..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
              style={{
                padding: "10px",
                margin: "10px 0",
                width: "300px",
                fontSize: "16px",
                outlineColor: "var(--primary-color)",
              }}
            />
          </div>

           {/* Add location filter dropdown */}
           <div style={{ minWidth: '250px', position: 'relative', zIndex: 1050, marginLeft: '10px' }}>
            <Select
              value={selectedLocation}
              onChange={handleLocationChange}
              options={locationOptions}
              placeholder="Φιλτράρισμα με τοποθεσία..."
              isClearable
              classNamePrefix="react-select"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--secondary-color)",
                  primary25: "var(--tertiary-color)",
                },
              })}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1050,
                }),
                control: (provided) => ({
                  ...provided,
                  zIndex: 1050,
                  minHeight: '38px',
                  backgroundColor: '#f8f8f8',
                }),
              }}
            />
          </div>
        </div>
      </div>
      
      {/* Property Filters */}
      {!isLoading && (
        <div className="properties-filter-row">
          <FilterChip
            label="All"
            count={locationFilteredData.length}
            onClick={() => setPropertyFilters(["all"])}
            isActive={propertyFilters.includes("all")}
          />
          <FilterChip
            label="With Warnings"
            count={getPropertiesWithWarningsCount()}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withWarnings"];
                if (prev.includes("withWarnings")) {
                  return prev.filter(f => f !== "withWarnings");
                }
                return [...prev, "withWarnings"];
              });
            }}
            isActive={propertyFilters.includes("withWarnings")}
          />
          {/* Add new filter chip for pending actions */}
          <FilterChip
            label="Pending Action"
            count={getPropertiesWithPendingActionsCount()}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withPendingActions"];
                if (prev.includes("withPendingActions")) {
                  return prev.filter(f => f !== "withPendingActions");
                }
                return [...prev, "withPendingActions"];
              });
            }}
            isActive={propertyFilters.includes("withPendingActions")}
          />
          <FilterChip
            label="With Reservation"
            count={getPropertiesWithReservationCount()}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withReservation"];
                if (prev.includes("withReservation")) {
                  return prev.filter(f => f !== "withReservation");
                }
                return [...prev, "withReservation"];
              });
            }}
            isActive={propertyFilters.includes("withReservation")}
          />
          <FilterChip
            label="Without Reservation"
            count={getPropertiesWithoutReservationCount()}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withoutReservation"];
                if (prev.includes("withoutReservation")) {
                  return prev.filter(f => f !== "withoutReservation");
                }
                return [...prev, "withoutReservation"];
              });
            }}
            isActive={propertyFilters.includes("withoutReservation")}
          />
          <FilterChip
            label="With Action"
            count={getPropertiesWithActionCount()}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withAction"];
                if (prev.includes("withAction")) {
                  return prev.filter(f => f !== "withAction");
                }
                return [...prev, "withAction"];
              });
            }}
            isActive={propertyFilters.includes("withAction")}
          />
          <FilterChip
            label="Without Action"
            count={getPropertiesWithoutActionCount()}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withoutAction"];
                if (prev.includes("withoutAction")) {
                  return prev.filter(f => f !== "withoutAction");
                }
                return [...prev, "withoutAction"];
              });
            }}
            isActive={propertyFilters.includes("withoutAction")}
          />
          <FilterChip
            label="Unassigned Actions"
            count={getPropertiesWithUnassignedActionsCount()}
            onClick={() => {
              setPropertyFilters(prev => {
                if (prev.includes("all")) return ["withUnassignedActions"];
                if (prev.includes("withUnassignedActions")) {
                  return prev.filter(f => f !== "withUnassignedActions");
                }
                return [...prev, "withUnassignedActions"];
              });
            }}
            isActive={propertyFilters.includes("withUnassignedActions")}
          />
        </div>
      )}
      
      {/* Calendar Table */}
      {!isLoading && (
        <CalendarTable
          data={fullyFilteredData} // Use the fully filtered data 
          dates={dates}
          actions={actions}
          handleDrop={handleDrop}
          setCreateActionPopUpWindowIsOpen={setCreateActionPopUpWindowIsOpen}
          setCreateActionSelectedDate={setCreateActionSelectedDate}
          setCreateActionSelectedProperty={setCreateActionSelectedProperty}
          setViewActionsPopUpWindowIsOpen={setViewActionsPopUpWindowIsOpen}
          setViewActionsSelectedDate={setViewActionsSelectedDate}
          setViewActionsSelectedActions={setViewActionsSelectedActions}
          handleDateCellClick={handleDateCellClick}
          searchTerm={searchTerm}
          filterProperties={(props) => props} // Pass a simple identity function since filtering is done above
          selectedDateFilter={selectedDateFilter}
          handleDateColumnClick={handleDateColumnClick}
        />
      )}
      
      {/* Groups Row - Add this before User Row */}
      {!isLoading && groupActionCounts.length > 0 && (
        <div className="calendar-groups-row">
          <GroupChip
            key="all-groups"
            group={{ 
              name: "All Groups", 
              _id: "all",
              actions: actions.filter(action => {
                const actionDate = new Date(action.planned_start_date);
                const matchesDate = selectedDateFilter ? 
                  actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                  actionDate.getMonth() === selectedDateFilter.getMonth() &&
                  actionDate.getDate() === selectedDateFilter.getDate() : true;
                return actionDate >= dates[0] && 
                      actionDate <= dates[dates.length - 1] && 
                      matchesDate && action.group;
              })
            }}
            tasks={actions.filter(action => {
              const actionDate = new Date(action.planned_start_date);
              const matchesDate = selectedDateFilter ? 
                actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                actionDate.getMonth() === selectedDateFilter.getMonth() &&
                actionDate.getDate() === selectedDateFilter.getDate() : true;
              return actionDate >= dates[0] && 
                    actionDate <= dates[dates.length - 1] && 
                    matchesDate && action.group;
            }).length}
            estimatedHours={actions.filter(action => {
              const actionDate = new Date(action.planned_start_date);
              const matchesDate = selectedDateFilter ? 
                actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                actionDate.getMonth() === selectedDateFilter.getMonth() &&
                actionDate.getDate() === selectedDateFilter.getDate() : true;
              return actionDate >= dates[0] && 
                    actionDate <= dates[dates.length - 1] && 
                    matchesDate && action.group;
            }).reduce((total, action) => total + (action.estimated_hours || 0), 0)}
            onClick={() => {
              setSelectedGroup(null);
              // If user is filtered by a specific group member and we're clearing group filter,
              // also clear user filter to show consistent results
              if (selectedGroup && selectedUser && 
                  selectedUser._id !== 'unassigned' && 
                  userActionCounts.findIndex(u => u._id === selectedUser._id) === -1) {
                setSelectedUser(null);
              }
            }}
            isActive={selectedGroup === null}
          />
          {groupActionCounts.map((group) => (
            <GroupChip
              key={group._id}
              group={group}
              tasks={group.actionCount}
              estimatedHours={group.estimatedHours}
              onClick={() => {
                // Toggle selection
                setSelectedGroup(selectedGroup?._id === group._id ? null : group);
                // Clear user selection when changing groups for a cleaner filter experience
                if (selectedUser && selectedUser._id !== 'unassigned') {
                  setSelectedUser(null);
                }
              }}
              isActive={selectedGroup?._id === group._id}
            />
          ))}
        </div>
      )}
      
      {/* User Filters */}
      {!isLoading && userActionCounts.length > 0 && (
        <div className="calendar-users-row">
          <UserChip
            key="all"
            user={{ 
              name: "All Users", 
              _id: "all",
              actions: actions.filter(action => {
                const actionDate = new Date(action.planned_start_date);
                const matchesDate = selectedDateFilter ? 
                  actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                  actionDate.getMonth() === selectedDateFilter.getMonth() &&
                  actionDate.getDate() === selectedDateFilter.getDate() : true;
                
                // If group is selected, only include actions for that group
                let matchesGroup = true;
                if (selectedGroup) {
                  const actionGroupId = typeof action.group === 'object' ? action.group?._id : action.group;
                  matchesGroup = actionGroupId === selectedGroup._id;
                }
                
                return actionDate >= dates[0] && 
                      actionDate <= dates[dates.length - 1] && 
                      matchesDate && matchesGroup;
              })
            }}
            tasks={actions.filter(action => {
              const actionDate = new Date(action.planned_start_date);
              const matchesDate = selectedDateFilter ? 
                actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                actionDate.getMonth() === selectedDateFilter.getMonth() &&
                actionDate.getDate() === selectedDateFilter.getDate() : true;
              
              // If group is selected, only include actions for that group
              let matchesGroup = true;
              if (selectedGroup) {
                const actionGroupId = typeof action.group === 'object' ? action.group?._id : action.group;
                matchesGroup = actionGroupId === selectedGroup._id;
              }
              
              return actionDate >= dates[0] && 
                    actionDate <= dates[dates.length - 1] && 
                    matchesDate && matchesGroup;
            }).length}
            estimatedHours={actions.filter(action => {
              const actionDate = new Date(action.planned_start_date);
              const matchesDate = selectedDateFilter ? 
                actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                actionDate.getMonth() === selectedDateFilter.getMonth() &&
                actionDate.getDate() === selectedDateFilter.getDate() : true;
              
              // If group is selected, only include actions for that group
              let matchesGroup = true;
              if (selectedGroup) {
                const actionGroupId = typeof action.group === 'object' ? action.group?._id : action.group;
                matchesGroup = actionGroupId === selectedGroup._id;
              }
              
              return actionDate >= dates[0] && 
                    actionDate <= dates[dates.length - 1] && 
                    matchesDate && matchesGroup;
            }).reduce((total, action) => total + (action.estimated_hours || 0), 0)}
            onClick={() => setSelectedUser(null)}
            isActive={selectedUser === null}
          />
          {userActionCounts.map((user) => (
            <UserChip
              key={user._id}
              user={user}
              tasks={user.actionCount}
              estimatedHours={user.estimatedHours}
              onClick={() => setSelectedUser(selectedUser?._id === user._id ? null : user)}
              isActive={selectedUser?._id === user._id}
            />
          ))}
          {unassignedActionsCount > 0 && (
            <UserChip
              key="unassigned"
              user={{ 
                name: "Unassigned", 
                _id: "unassigned",
                actions: actions.filter(action => {
                  const actionDate = new Date(action.planned_start_date);
                  const matchesDate = selectedDateFilter ? 
                    actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                    actionDate.getMonth() === selectedDateFilter.getMonth() &&
                    actionDate.getDate() === selectedDateFilter.getDate() : true;
                  
                  // If group is selected, only include unassigned actions for that group
                  let matchesGroup = true;
                  if (selectedGroup) {
                    const actionGroupId = typeof action.group === 'object' ? action.group?._id : action.group;
                    matchesGroup = actionGroupId === selectedGroup._id;
                  }
                  
                  return (!action.assigned_to || action.assigned_to.length === 0) &&
                         actionDate >= dates[0] && 
                         actionDate <= dates[dates.length - 1] && 
                         matchesDate && matchesGroup;
                })
              }}
              tasks={actions.filter(action => {
                const actionDate = new Date(action.planned_start_date);
                const matchesDate = selectedDateFilter ? 
                  actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                  actionDate.getMonth() === selectedDateFilter.getMonth() &&
                  actionDate.getDate() === selectedDateFilter.getDate() : true;
                
                // If group is selected, only include unassigned actions for that group
                let matchesGroup = true;
                if (selectedGroup) {
                  const actionGroupId = typeof action.group === 'object' ? action.group?._id : action.group;
                  matchesGroup = actionGroupId === selectedGroup._id;
                }
                
                return (!action.assigned_to || action.assigned_to.length === 0) &&
                       actionDate >= dates[0] && 
                       actionDate <= dates[dates.length - 1] && 
                       matchesDate && matchesGroup;
              }).length}
              estimatedHours={actions.filter(action => {
                const actionDate = new Date(action.planned_start_date);
                const matchesDate = selectedDateFilter ? 
                  actionDate.getFullYear() === selectedDateFilter.getFullYear() &&
                  actionDate.getMonth() === selectedDateFilter.getMonth() &&
                  actionDate.getDate() === selectedDateFilter.getDate() : true;
                
                // If group is selected, only include unassigned actions for that group  
                let matchesGroup = true;
                if (selectedGroup) {
                  const actionGroupId = typeof action.group === 'object' ? action.group?._id : action.group;
                  matchesGroup = actionGroupId === selectedGroup._id;
                }
                
                return (!action.assigned_to || action.assigned_to.length === 0) &&
                       actionDate >= dates[0] && 
                       actionDate <= dates[dates.length - 1] && 
                       matchesDate && matchesGroup;
              }).reduce((total, action) => total + (action.estimated_hours || 0), 0)}
              onClick={() => {
                setSelectedUser(selectedUser?.name === "Unassigned" ? null : { 
                  name: "Unassigned", 
                  _id: "unassigned" 
                });
              }}
              isActive={selectedUser?.name === "Unassigned"}
            />
          )}
        </div>
      )}

      {/* View Actions Popup */}
      {viewActionsPopUpWindowIsOpen && (
        <PopUpWindow
          onClose={() => {
            setViewActionsPopUpWindowIsOpen(false);
          }}
          unfocusClose={true}
          title={`Δραστηριότητες - ${formatDateString(
            viewActionsSelectedDate
          )} - ${createActionSelectedProperty.label}`}
        >
          <ViewActionsForm
            users={users}
            groups={groups}
            selectedActions={viewActionsSelectedActions}
            date={
              new Date(
                viewActionsSelectedDate.getFullYear(),
                viewActionsSelectedDate.getMonth(),
                viewActionsSelectedDate.getDate(),
                viewActionsSelectedDate.getHours(),
                viewActionsSelectedDate.getMinutes(),
                viewActionsSelectedDate.getSeconds()
              )
            }
            property={createActionSelectedProperty}
            actionReservations={actionReservationsMap}
            propertyReservations={createActionSelectedProperty?.value?._id
              ? reservations.filter(
                  (res) => {
                    const resStart = new Date(res.checkIn);
                    const resEnd = new Date(res.checkOut);
                    
                    return res.property === createActionSelectedProperty.value._id && 
                           resStart <= dates[dates.length - 1] && 
                           resEnd >= dates[0];
                  }
                ).map(res => ({
                  value: res._id,
                  label: `${res.reservationId} - ${res.firstName} ${res.lastName} (${formatDate(res.checkIn)} to ${formatDate(res.checkOut)})`,
                  reservation: res
                }))
              : []}
          />
        </PopUpWindow>
      )}

      {/* Reservation Details Popup */}
      {showReservationDetails && selectedReservations.length > 0 && (
        <div 
          className="reservation-tooltip-wrapper" 
          style={{ 
            position: 'absolute',
            left: `${popupPosition.x}px`,
            top: `${popupPosition.y}px`,
            zIndex: 1000
          }}
        >
          <ReservationTooltip reservations={selectedReservations} />
        </div>
      )}

      {/* Reservation Options Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: '12px',
            padding: '8px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ 
          borderBottom: '1px solid #eee',
          fontSize: '1.4rem',
          fontWeight: '600',
          color: 'var(--secondary-color)'
        }}>
          {"Διαχείριση Κρατήσεων"}
        </DialogTitle>
        <DialogContent sx={{ padding: '24px 24px 16px' }}>
          {showReservationOptions && targetCellReservations.length > 0 ? (
            <DialogContentText id="alert-dialog-description">
              <p>Υπάρχουν κρατήσεις στο κατάλυμα προορισμού για αυτή την ημερομηνία. Τι θα θέλατε να κάνετε;</p>
              <ul className="reservation-list-dialog">
                {targetCellReservations.map((res, idx) => (
                  <li key={idx}>{res.label}</li>
                ))}
              </ul>
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Αυτές οι δραστηριότητες έχουν συνδεδεμένες κρατήσεις που ανήκουν στο τρέχον κατάλυμα. Τι θα θέλατε να κάνετε με τις κρατήσεις;
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'stretch', 
          padding: '0 24px 24px',
          gap: '10px'
        }}>
          <Button 
            onClick={() => handleReservationOption("keep")} 
            variant="contained"
            fullWidth
            startIcon={<CheckCircleOutlineIcon />}
            sx={{ 
              backgroundColor: 'var(--primary-color)',
              color: 'white',
              padding: '10px 16px',
              justifyContent: 'flex-start',
              boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: 'var(--secondary-color)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              }
            }}
          >
            Διατήρηση υπαρχουσών κρατήσεων
          </Button>
          
          {showReservationOptions && targetCellReservations.length > 0 && (
            <Button 
              onClick={() => handleReservationOption("add")} 
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              fullWidth
              sx={{ 
                borderColor: 'var(--primary-color)',
                color: 'var(--primary-color)',
                padding: '10px 16px',
                justifyContent: 'flex-start',
                '&:hover': {
                  borderColor: 'var(--secondary-color)',
                  color: 'var(--secondary-color)',
                  backgroundColor: 'rgba(var(--primary-color-rgb), 0.05)'
                }
              }}
            >
              Προσθήκη νέων κρατήσεων στις υπάρχουσες
            </Button>
          )}
          
          {showReservationOptions && targetCellReservations.length > 0 && (
            <Button 
              onClick={() => handleReservationOption("replace")} 
              variant="outlined"
              startIcon={<SwapHorizontalCircleIcon />}
              fullWidth
              sx={{ 
                borderColor: 'var(--primary-color)',
                color: 'var(--primary-color)',
                padding: '10px 16px',
                justifyContent: 'flex-start',
                '&:hover': {
                  borderColor: 'var(--secondary-color)',
                  color: 'var(--secondary-color)',
                  backgroundColor: 'rgba(var(--primary-color-rgb), 0.05)'
                }
              }}
            >
              Αντικατάσταση υπαρχουσών κρατήσεων με τις νέες
            </Button>
          )}
          
          <Button 
            onClick={() => handleReservationOption("remove")} 
            variant="outlined" 
            startIcon={<RemoveCircleOutlineIcon />}
            fullWidth
            sx={{ 
              borderColor: '#ff4444',
              color: '#ff4444',
              padding: '10px 16px',
              justifyContent: 'flex-start',
              '&:hover': {
                borderColor: '#cc0000',
                color: '#cc0000',
                backgroundColor: 'rgba(255, 68, 68, 0.05)'
              }
            }}
          >
            Αφαίρεση όλων των κρατήσεων
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CalendarView;