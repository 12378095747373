import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Box,
  Tooltip,
  Chip,
  TableSortLabel,
  Button,
  Pagination,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import Select from "react-select";
import {
  RemoveRedEye,
  Edit,
  Search,
  Clear,
  Delete,
  Archive,
  Restore,
  ArrowBack,
  FileDownload
} from "@mui/icons-material/";
import { Loader, PopUpWindow, AddActionForm } from "../../components";
import io from "socket.io-client";
import { json } from "react-router-dom";
import axios from "axios";

// Function to generate a color based on the username
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

const findDueDateColor = (dueDate) => {
  const date = new Date(dueDate);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  if (date <= today) {
    return "#FF0000";
  } else if (date <= dayAfterTomorrow) {
    return "#FF8400";
  } else {
    return "#00FF00";
  }
};

const stringOutArray = (array) => {
  console.log("stringOutArray received:", array);
  let string = "";
  
  // Return empty string for null, undefined, or empty string
  if (array === null || array === undefined || array === "") {
    console.log("stringOutArray returning empty string for null/undefined input");
    return string;
  }
  
  // Check if array is actually an array and has items
  if (!Array.isArray(array) || array.length === 0) {
    console.log("stringOutArray returning empty string for empty/non-array");
    return string;
  }

  try {
    // Map through and extract IDs
    const ids = array.map(element => {
      if (!element || !element.value || !element.value._id) {
        console.log("Skipping element without value._id:", element);
        return null;
      }
      return element.value._id;
    }).filter(id => id !== null);
    
    string = ids.join(',');
    console.log("stringOutArray result:", string);
    return string;
  } catch (error) {
    console.error("Error in stringOutArray:", error);
    return "";
  }
};

const AssignedToCell = ({ user }) => {
  // Placeholder for fetching user names based on IDs
  // In a real application, replace this with actual logic to fetch user details

  return (
    <Box display="flex" gap={1} justifyContent={"center"}>
      <Tooltip key={user._id} title={user.name}>
        <Avatar style={{ backgroundColor: getColor(user.name) }}>
          {capitalizeFirstLetter(user.name[0])}
        </Avatar>
      </Tooltip>
    </Box>
  );
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const socket = io("https://maliarakis-server.rhodesislandpass.com/");

const ActionsTable = (
  props = {
    searchUsers: [],
    searchStatus: "",
    searchPriority: "",
    searchCategory: "",
    searchProperty: "",
    mini: false,
    // Default the showTotalPrice to true
    showTotalPrice: true,
  }
) => {
  // Set default value for showTotalPrice if it's undefined
  props = { ...props, showTotalPrice: props.showTotalPrice !== undefined ? props.showTotalPrice : true };
  
  const [deleteActionOpen, setDeleteActionOpen] = useState(false);
  const [actionIsOpen, setActionisOpen] = useState(false);
  const [editActionOpen, setEditActionOpen] = useState(false); // Add this line
  const [groups, setGroups] = useState([]); // Fix: Define missing state variables
  const [actionOptions, setActionOptions] = useState([]); // Fix: Define missing state variables
  const [openAction, setOpenAction] = useState();
  const [actions, setActions] = useState([]);
  const [totalActions, setTotalActions] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [actionsPerPage, setActionsPerPage] = useState(10);
  const [totalPrice, setTotalPrice] = useState(0);
  const [completedActionsCount, setCompletedActionsCount] = useState(0); // Add state for completed actions count
  const [showTotalSummary, setShowTotalSummary] = useState(false);

  const [sortField, setSortField] = useState("updated_at");
  const [sortOrder, setSortOrder] = useState(-1); // -1 for descending, 1 for ascending
  const [isLoading, setIsLoading] = useState(true);

  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);

  const [properties, setProperties] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchUsers, setSearchUsers] = useState(
    props.searchUsers ? props.searchUsers : []
  );

  const [searchProperty, setSearchProperty] = useState(
    props.searchProperty ? props.searchProperty : ""
  );

  const [searchStartDate, setSearchStartDate] = useState(
    props.searchStartDate
      ? props.searchStartDate.toISOString().split("T")[0]
      : ""
  );
  const [searchEndDate, setSearchEndDate] = useState(
    props.searchEndDate ? props.searchEndDate.toISOString().split("T")[0] : ""
  );
  const [searchActionOption, setSearchActionOption] = useState(
    props.searchActionOption ? props.searchActionOption : ""
  );
  const [searchGroup, setSearchGroup] = useState(
    props.searchGroup ? props.searchGroup : ""
  );

  const [error, setError] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  const urlIncludesArchived = window.location.href.includes("archived");

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://maliarakis-server.rhodesislandpass.com/auth/getUsers", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/property/getPropertiesNames",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.propertiesNames);
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  const fetchActionOptions = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/action-option/getActionOptions",

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setActionOptions(response.data.actionOptions);
    } catch (error) {
      console.error("Failed to fetch action options: ", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        "https://maliarakis-server.rhodesislandpass.com/group/getGroups",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGroups(response.data.groups);
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const fetchActions = async (
    property = "",
    users = "",
    groups = "",
    action = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      // Format dates for proper search if they exist
      const formattedStartDate = startDate ? startDate : "";
      const formattedEndDate = endDate ? endDate : "";
      
      const response = await fetch(
        `https://maliarakis-server.rhodesislandpass.com/action/getActions?property=${stringOutArray(
          property
        )}&users=${stringOutArray(users)}&sortField=${sortField}&sortOrder=${
          sortOrder === 1 ? "asc" : "desc"
        }&page=${currentPage}&limit=${actionsPerPage}&groups=${stringOutArray(
          groups
        )}&action_option=${stringOutArray(
          action
        )}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      const data = await response.json();
      console.log("Fetched actions:", data);
      setActions(data.actions);
      setTotalActions(data.totalItems);
      setPageCount(Math.ceil(data.totalItems / actionsPerPage));
      
      // Only calculate total price if property is selected and both dates are provided
      const shouldShowPrice = props.showTotalPrice !== false;
      const hasProperty = property && property.length > 0;
      
      console.log("Price calculation conditions:", { 
        shouldShowPrice, 
        hasProperty, 
        startDate: !!startDate, 
        endDate: !!endDate 
      });
      
      if (hasProperty && startDate && endDate && shouldShowPrice) {
        console.log("Conditions met for price calculation, calling fetchTotalPrice");
        // If we have property and dates, fetch the total price for all actions in the date range
        fetchTotalPrice(
          property,
          users,
          groups,
          action,
          formattedStartDate,
          formattedEndDate
        );
        setShowTotalSummary(true);
      } else {
        console.log("Not fetching total price, conditions not met");
        setTotalPrice(0);
        setShowTotalSummary(false);
      }
      
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching actions:", err);
      setIsLoading(false);
    }
  };
  
  // Add a new function to fetch the total price for all actions in the date range
  const fetchTotalPrice = async (
    property = "",
    users = "",
    groups = "",
    action = "",
    startDate = "",
    endDate = ""
  ) => {
    console.log("fetchTotalPrice called with:", {
      property,
      users,
      groups,
      action,
      startDate,
      endDate
    });
    
    // Debug check to validate property param
    if (!property || (Array.isArray(property) && property.length === 0)) {
      console.log("Property is empty or missing, not fetching price");
      setTotalPrice(0);
      return;
    }
    
    const propertyIds = stringOutArray(property);
    if (!propertyIds) {
      console.log("No property IDs extracted, not fetching price");
      setTotalPrice(0);
      return;
    }
    
    try {
      const url = `https://maliarakis-server.rhodesislandpass.com/action/getTotalPrice?property=${propertyIds}&users=${stringOutArray(
        users
      )}&groups=${stringOutArray(
        groups
      )}&action_option=${stringOutArray(
        action
      )}&startDate=${startDate}&endDate=${endDate}`;
      
      console.log("Total price request URL:", url);
      
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      
      const data = await response.json();
      console.log("Total price response:", data);
      setTotalPrice(data.totalPrice || 0);
      
      // Store the completed actions count
      setCompletedActionsCount(data.completedActionsCount || 0);
    } catch (err) {
      console.error("Error fetching total price:", err);
      setTotalPrice(0);
      setCompletedActionsCount(0);
    }
  };

  // Add a function to handle the CSV export
  const handleExportCSV = async (
    property = "",
    users = "",
    groups = "",
    action = "",
    startDate = "",
    endDate = ""
  ) => {
    console.log("Exporting to CSV with params:", {
      property,
      users,
      groups,
      action,
      startDate,
      endDate
    });
    
    try {
      // Build query parameters
      const queryParams = new URLSearchParams();
      if (property) queryParams.append('property', stringOutArray(property));
      if (users) queryParams.append('users', stringOutArray(users));
      if (groups) queryParams.append('groups', stringOutArray(groups));
      if (action) queryParams.append('action_option', stringOutArray(action));
      if (startDate) queryParams.append('startDate', startDate);
      if (endDate) queryParams.append('endDate', endDate);
      
      // Create URL for CSV export
      const url = `https://maliarakis-server.rhodesislandpass.com/action/exportActionsCSV?${queryParams.toString()}`;
      
      // Create a temporary link element to trigger download
      const link = document.createElement('a');
      link.href = url;
      
      // Set headers for auth
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${user.token}`);
      
      // Fetch the CSV file
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      // Get the blob from response
      const blob = await response.blob();
      
      // Create download link
      const downloadUrl = window.URL.createObjectURL(blob);
      link.href = downloadUrl;
      
      // Set filename from Content-Disposition header or use default
      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = 'actions-export.csv';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1];
        }
      }
      
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      console.error("Error exporting to CSV:", err);
      // Optionally show an error message to the user
    }
  };

  const handleOpenAction = (action) => {
    setOpenAction(action);
    setActionisOpen(true);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent the form from causing a page reload
    console.log("Search params:", {
      property: searchProperty,
      users: searchUsers,
      group: searchGroup,
      action: searchActionOption,
      startDate: searchStartDate,
      endDate: searchEndDate
    });
    
    fetchActions(
      searchProperty,
      searchUsers,
      searchGroup,
      searchActionOption,
      searchStartDate,
      searchEndDate
    );
  };

  const handleClearSearch = (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    event.stopPropagation(); // Stop the event from propagating to the form's onSubmit handler

    setSearchStartDate("");
    setSearchEndDate("");
    setSortField("updated_at");
    setSortOrder(-1);
    setCurrentPage(1);
    setSearchGroup("");
    setSearchActionOption("");

    setSearchProperty("");
    if (user.permissions.canViewAllTickets) {
      setSearchUsers("");
      fetchActions();
    } else {
      fetchActions("", "", searchUsers);
    }
    
    // Clear total price when search is cleared
    setTotalPrice(0);
    setShowTotalSummary(false);
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order if the same field was clicked
      setSortOrder(sortOrder * -1);
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortOrder(1);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchActions(
      searchProperty,
      searchUsers,
      searchGroup,
      searchActionOption,
      searchStartDate,
      searchEndDate
    );
    setIsLoading(false);
  }, [currentPage, sortField, sortOrder]);

  useEffect(() => {
    const handleNewAction = (newAction) => {
      // if (
      //   (searchProperty.includes(newAction.property) ||
      //     searchProperty === "") &&
      //   (searchUsers.length === 0 ||
      //     searchUsers
      //       .map((user) => user.value._id)
      //       .filter((userId) => newAction.assignedTo.includes(userId)).length >
      //       0)
      // ) {
      //   setTotalActions((prevTotalActions) => {
      //     const newTotalActions = prevTotalActions + 1;
      //     setPageCount(Math.ceil(newTotalActions / actionsPerPage));
      //     return newTotalActions;
      //   });
      //   if (currentPage === 1) {
      //     setActions((prevActions) => {
      //       if (prevActions.length === actionsPerPage) {
      //         return [newAction, ...prevActions.slice(0, -1)];
      //       } else {
      //         return [newAction, ...prevActions];
      //       }
      //     });
      //   }
      // }
    };

    const handleUpdatedAction = async (updatedAction) => {
      if (updatedAction.archived && urlIncludesArchived) {
        setActions((prevActions) =>
          prevActions.filter((action) => action._id !== updatedAction._id)
        );
        setTotalActions((prevTotalActions) => {
          const newTotalActions = prevTotalActions - 1;
          setPageCount(Math.ceil(newTotalActions / actionsPerPage));
          return newTotalActions;
        });

        return;
      }

      if (updatedAction.archived) {
        setActions((prevActions) =>
          prevActions.filter((action) => action._id !== updatedAction._id)
        );
        setTotalActions((prevTotalActions) => {
          const newTotalActions = prevTotalActions - 1;
          setPageCount(Math.ceil(newTotalActions / actionsPerPage));
          return newTotalActions;
        });
        return;
      }

      if (
        actions.find((action) => action._id === updatedAction._id) &&
        !(
          (searchGroup.includes(updatedAction.group) || searchGroup === "") &&
          (searchActionOption.includes(updatedAction.actionId) ||
            searchActionOption === "") &&
          (searchStartDate === "" ||
            updatedAction.planned_start_date >= searchStartDate) &&
          (searchEndDate === "" ||
            updatedAction.planned_start_date <= searchEndDate) &&
          (searchProperty.includes(updatedAction.property) ||
            searchProperty === "") &&
          (searchUsers.length === 0 ||
            searchUsers
              .map((user) => user.value._id)
              .filter((userId) => updatedAction.assignedTo.includes(userId))
              .length > 0) &&
          (props.createdBy === true || updatedAction.createdBy === user._id)
        )
      ) {
        setActions((prevActions) =>
          prevActions.filter((action) => action._id !== updatedAction._id)
        );
        setTotalActions((prevTotalActions) => {
          const newTotalActions = prevTotalActions - 1;
          setPageCount(Math.ceil(newTotalActions / actionsPerPage));
          return newTotalActions;
        });
        return;
      }

      if (
        (searchGroup.includes(updatedAction.group) || searchGroup === "") &&
        (searchActionOption.includes(updatedAction.actionId) ||
          searchActionOption === "") &&
        (searchStartDate === "" ||
          updatedAction.planned_start_date >= searchStartDate) &&
        (searchEndDate === "" ||
          updatedAction.planned_start_date <= searchEndDate) &&
        (searchProperty.includes(updatedAction.property) ||
          searchProperty === "") &&
        (searchUsers.length === 0 ||
          searchUsers
            .map((user) => user.value._id)
            .filter((userId) => updatedAction.assignedTo.includes(userId))
            .length > 0) &&
        ((props.createdBy === true && updatedAction.createdBy === user._id) ||
          props.createdBy === false)
      ) {
        if (!actions.find((action) => action._id === updatedAction._id)) {
          setActions((prevActions) => [...prevActions, updatedAction]);
          setTotalActions((prevTotalActions) => {
            const newTotalActions = prevTotalActions + 1;
            setPageCount(Math.ceil(newTotalActions / actionsPerPage));
            return newTotalActions;
          });
          return;
        }
        setActions((prevActions) =>
          prevActions.map((action) =>
            action._id === updatedAction._id ? updatedAction : action
          )
        );

        return;
      }

      // if ( await openAction?._id === updatedAction._id) {
      //   setOpenAction(updatedAction);
      // }
    };

    const handleDeletedAction = (deletedActionId) => {
      setActions((prevActions) =>
        prevActions.filter((action) => action._id !== deletedActionId)
      );

      setTotalActions((prevTotalActions) => {
        const newTotalActions = prevTotalActions - 1;
        setPageCount(Math.ceil(newTotalActions / actionsPerPage));
        return newTotalActions;
      });
    };

    socket.on("actionCreated", handleNewAction);
    socket.on("actionUpdated", handleUpdatedAction);
    socket.on("actionDeleted", (deletedActionId) => {
      handleDeletedAction(deletedActionId);
    });

    return () => {
      socket.off("actionCreated", handleNewAction);
      socket.off("actionUpdated", handleUpdatedAction);
      socket.off("actionDeleted", handleDeletedAction);
    };
  }, [actions]);

  useEffect(() => {
    fetchActions(
      searchProperty,
      searchUsers,
      searchGroup,
      searchActionOption,
      searchStartDate,
      searchEndDate
    );

    fetchProperties();
    fetchUsers();
    fetchGroups();
    fetchActionOptions();
  }, []);

  return (
    <>
      {!isLoading && editActionOpen && openAction && (
        <PopUpWindow
          onClose={() => {
            setEditActionOpen(false);
          }}
          width={"65%"}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              gap: "10px",
            }}
          >
            <AddActionForm
              edit={edit}
              view={view}
              actionId={openAction._id}
              action_option={
                actionOptions
                  .filter((actionOption) => {
                    return actionOption._id === openAction.action_option._id;
                  })
                  .map((a) => {
                    return {
                      label: a.name,
                      value: a,
                    };
                  })[0]
              }
              property={
                properties
                  .filter((property) => {
                    return property._id === openAction.property;
                  })
                  .map((p) => {
                    return {
                      label: p.name,
                      value: p,
                    };
                  })[0]
              }
              group={
                groups && openAction.group ?
                {
                label: openAction.group.name,
                value: {
                  _id: openAction.group._id,
                  name: openAction.group.name,
                },
              }
              : ""
            }
              assigned_to={openAction.assigned_to.map((user) => {
                return {
                  label: user.username,
                  value: {
                    _id: user._id,
                    username: user.username,
                  },
                };
              })}
              date={new Date(openAction.planned_start_date)}
              start_date={openAction.start_date}
              end_date={openAction.end_date}
              price={openAction.price}
              notes={openAction.notes}
            />

            <div className="start-time">
              <Typography variant="h6">
                Έναρξη:{" "}
                {openAction.start_date
                  ? new Date(openAction.start_date).toLocaleTimeString()
                  : "Δεν έχει ξεκινήσει"}
              </Typography>
            </div>
            <div className="end-time">
              <Typography variant="h6">
                Λήξη:{" "}
                {openAction.end_date
                  ? new Date(openAction.end_date).toLocaleTimeString()
                  : "Δεν έχει λήξει"}
              </Typography>
            </div>

            <div className="timer-total">
                  <Typography
                    variant="h3"
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      gap: "10px",
                      alignSelf: "center",
                      justifySelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "var(--primary-color)",
                    }}
                  >
                    {
                      openAction.end_date && openAction.start_date
                        ? `${new Date(new Date(openAction.end_date) - new Date(openAction.start_date)).toISOString().substr(11, 8)}`
                        : !openAction.end_date && openAction.start_date
                        ? "Δεν έχει λήξει"
                        : "Δεν έχει ξεκινήσει"
                    }
                  </Typography>
                </div>
          </div>
        </PopUpWindow>
      )}

      {!isLoading && deleteActionOpen && (
        <PopUpWindow
          onClose={() => {
            setDeleteActionOpen(false);
          }}
          width={"65%"}
        >
          <div className="delete-action">
            <h3>Είστε σίγουροι ότι θέλετε να διαγράψετε την δραστηριότητα;</h3>
            <div className="delete-action-buttons">
              <div
                className="add-ticket-button"
                style={{
                  display: JSON.parse(localStorage.getItem("user")).permissions
                    .canCreateTicket
                    ? "flex"
                    : "none",
                }}
              >
                <button
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: props.color || "#D40101",
                    color: props.textColor || "var(--primary-text-color)",
                    fontSize: props.fontSize || "15px",
                    fontWeight: props.fontWeight || "600",
                    width: props.width || "100%",
                  }}
                  onClick={() => {
                    axios
                      .delete(
                        `https://maliarakis-server.rhodesislandpass.com/action/deleteAction/${openAction._id}`,
                        {
                          headers: {
                            Authorization: `Bearer ${user.token}`,
                          },
                        }
                      )
                      .then((res) => {})
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  <Delete />
                  <span>Διαγραφή</span>
                </button>
              </div>

              <div
                className="add-ticket-button"
                style={{
                  display: JSON.parse(localStorage.getItem("user")).permissions
                    .canCreateTicket
                    ? "flex"
                    : "none",
                }}
              >
                <button
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: props.color || "var(--primary-color)",
                    color: props.textColor || "var(--primary-text-color)",
                    fontSize: props.fontSize || "15px",
                    fontWeight: props.fontWeight || "600",
                    width: props.width || "100%",
                  }}
                  onClick={() => {
                    setDeleteActionOpen(false);
                    setOpenAction(null);
                  }}
                >
                  <ArrowBack />
                  <span>Πίσω</span>
                </button>
              </div>
            </div>
          </div>
        </PopUpWindow>
      )}
      {isLoading && <Loader />}

      {!isLoading && (
        <>
          {!props.mini && (
            <>
              <form
                onSubmit={handleSearchSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "95%",
                  padding: "10px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    label="Μετά από"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={searchStartDate}
                    onChange={(e) => setSearchStartDate(e.target.value)}
                    sx={{ marginLeft: "10px", width: "100%" }}
                  />
                  <TextField
                    label="Πριν από"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={searchEndDate}
                    onChange={(e) => setSearchEndDate(e.target.value)}
                    sx={{ marginLeft: "10px", width: "100%" }}
                  />
                </div>

                <div
                  className="form-group"
                  style={{
                    zIndex: 1001,
                  }}
                >
                  <div className="form-field">
                    <label htmlFor="type" className="form-field-label">
                      Δραστηριότητα:
                    </label>
                    <Select
                      id="type"
                      name="type"
                      value={searchActionOption}
                      onChange={(selectedOption) =>
                        setSearchActionOption(selectedOption)
                      }
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "var(--primary-color)",
                          primary25: "var(--secondary-color)",
                        },
                      })}
                      options={
                        actionOptions &&
                        actionOptions.map((actionOption) => ({
                          value: actionOption,
                          label: actionOption.name,
                        }))
                      } // Add more options as needed
                      isClearable
                      isMulti
                    />
                  </div>{" "}
                  <div className="form-field">
                    <label htmlFor="status" className="form-field-label">
                      Ομάδα:
                    </label>

                    <Select
                      id="status"
                      name="status"
                      value={searchGroup}
                      options={
                        groups &&
                        groups.map((group) => ({
                          value: group,
                          label: group.name,
                        }))
                      }
                      onChange={(selectedOption) =>
                        setSearchGroup(selectedOption)
                      }
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "var(--primary-color)",
                          primary25: "var(--secondary-color)",
                        },
                      })}
                      isClearable
                      isMulti
                    />
                  </div>
                </div>
                <div
                  className="form-group"
                  style={{
                    zIndex: 1000,
                  }}
                >
                  <div className="form-field">
                    <label htmlFor="property" className="form-field-label">
                      Κατάλυμα:
                    </label>
                    <Select
                      id="property"
                      name="property"
                      value={searchProperty}
                      onChange={(selectedOption) =>
                        setSearchProperty(selectedOption)
                      }
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "var(--primary-color)",
                          primary25: "var(--secondary-color)",
                        },
                      })}
                      options={
                        properties &&
                        properties.map((property) => ({
                          value: property,
                          label: property.name,
                        }))
                      } // Add more options as needed
                      isClearable
                      isMulti
                    />
                  </div>
                  <div
                    className="form-field"
                    style={{
                      visibility: user.permissions.canViewAllTickets
                        ? "visible"
                        : "hidden",
                      width: user.permissions.canViewAllTickets ? "100%" : "0%",
                    }}
                  >
                    <label htmlFor="assignedTo" className="form-field-label">
                      Ανατεθειμένο σε:
                    </label>
                    <Select
                      id="assignedTo"
                      name="assignedTo"
                      value={searchUsers}
                      onChange={(selectedOption) =>
                        setSearchUsers(selectedOption)
                      }
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "var(--primary-color)",
                          primary25: "var(--secondary-color)",
                        },
                        zIndex: 1000,
                      })}
                      options={
                        users &&
                        users.map((user) => ({
                          value: user,
                          label: user.username,
                        }))
                      } // Add more options as needed
                      isMulti
                      isClearable
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      padding: "10px",
                      width: "20%",
                      backgroundColor: "var(--primary-color)",
                      color: "var(--primary-text-color)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    onClick={handleSearchSubmit}
                  >
                    ΑΝΑΖΗΤΗΣΗ
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      marginLeft: "10px",
                      padding: "10px",
                      width: "20%",
                      backgroundColor: "var(--secondary-color)",
                      color: "var(--primary-text-color)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    onClick={handleClearSearch}
                  >
                    ΕΚΑΘΑΡΙΣΗ
                  </Button>
                </div>
              </form>
              
              {/* Summary Box - Only show when both dates are provided */}
              {(() => {
                const shouldShowPrice = props.showTotalPrice !== false;
                console.log("Rendering summary box - showTotalSummary:", showTotalSummary);
                console.log("Rendering summary box - shouldShowPrice:", shouldShowPrice);
                
                if (showTotalSummary && shouldShowPrice) {
                  return (
                    <Box
                      sx={{
                        width: '95%',
                        margin: '20px 0',
                        padding: '15px',
                        backgroundColor: 'var(--primary-color)',
                        color: 'white',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <Typography variant="h6" fontWeight="bold">
                          Συνολικές δραστηριότητες: {totalActions}
                        </Typography>
                        <Typography variant="h6" fontWeight="bold">
                          Ολοκληρωμένες δραστηριότητες: {completedActionsCount}
                        </Typography>
                        <Typography variant="h6" fontWeight="bold">
                          Συνολικό κόστος ολοκληρωμένων: {totalPrice.toFixed(2)}€
                        </Typography>
                      </div>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<FileDownload />}
                        onClick={() => handleExportCSV(
                          searchProperty,
                          searchUsers,
                          searchGroup,
                          searchActionOption,
                          searchStartDate,
                          searchEndDate
                        )}
                        sx={{ 
                          backgroundColor: 'white', 
                          color: 'var(--primary-color)', 
                          '&:hover': { 
                            backgroundColor: '#f0f0f0',
                          }
                        }}
                      >
                        Εξαγωγή Ολοκληρωμένων CSV
                      </Button>
                    </Box>
                  );
                }
                return null;
              })()}
            </>
          )}
          
          <TableContainer
            component={Paper}
            sx={{
              maxheight: 540,
              overflow: "auto",
              overflowX: "scroll",
              width: "100%",
              paddingRight: "20px",
            }}
          >
            <Table
              aria-label="simple table"
              stickyHeader
              sx={{
                padding: "10px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortField === "name"}
                      direction={
                        sortField === "name" && sortOrder === 1 ? "asc" : "desc"
                      }
                      onClick={() => handleSortChange("name")}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Όνομα
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="left">Κατάλυμα</TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortField === "planned_start_date"}
                      direction={
                        sortField === "planned_start_date" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("planned_start_date")}
                    >
                      Ημερομηνία
                    </TableSortLabel>
                  </TableCell>

                  {/* Add new Status column */}
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortField === "end_date"}
                      direction={
                        sortField === "end_date" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("end_date")}
                    >
                      Κατάσταση
                    </TableSortLabel>
                  </TableCell>

                  {!props.mini && (
                    <TableCell align="left">
                      <TableSortLabel
                        active={sortField === "price"}
                        direction={
                          sortField === "price" && sortOrder === 1
                            ? "asc"
                            : "desc"
                        }
                        onClick={() => handleSortChange("price")}
                      >
                        Τιμή
                      </TableSortLabel>
                    </TableCell>
                  )}

                  <TableCell align="center">
                    <TableSortLabel
                      active={sortField === "assignedTo"}
                      direction={
                        sortField === "assignedTo" && sortOrder === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortChange("assignedTo")}
                    >
                      Ανατεθειμένο σε
                    </TableSortLabel>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actions.map((action, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    maxheight="100px"
                  >
                    <TableCell sx={{ padding: "0px" }}>
                      <Tooltip title="Προβολή" placement="right">
                        <Button
                          onClick={() => {
                            setView(true);
                            setEdit(false);
                            setOpenAction(action);
                            setEditActionOpen(true);
                          }}
                        >
                          <RemoveRedEye
                            sx={{ color: "var(--primary-color)" }}
                          />
                        </Button>
                      </Tooltip>
                      {user.permissions.canEditTicket && (
                        <Tooltip title="Επεξεργασία" placement="right">
                          <Button
                            onClick={() => {
                              setEdit(true);
                              setView(false);
                              setOpenAction(action);
                              setEditActionOpen(true);
                            }}
                          >
                            <Edit sx={{ color: "var(--primary-color)" }} />
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {action.action_option.name}
                    </TableCell>

                    <TableCell align="left">
                      <div
                        style={{
                          maxHeight: "150px",
                          textOverflow: "ellipsis",
                          textWrap: "wrap",
                          overflow: "auto",
                        }}
                      >
                        {
                          properties.find(
                            (property) => property._id === action.property
                          )?.name
                        }
                      </div>
                    </TableCell>

                    <TableCell>
                      {formatDate(action.planned_start_date)}
                    </TableCell>

                    {/* New Status cell */}
                    <TableCell align="left">
                      <Chip
                        label={action.end_date ? "Ολοκληρώθηκε" : "Εκκρεμεί"}
                        color={action.end_date ? "success" : "warning"}
                        sx={{
                          fontWeight: "bold",
                          color: "white",
                        }}
                      />
                    </TableCell>

                    <TableCell align="left">
                      {`${action.price ? action.price : "-"}€`}
                    </TableCell>

                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          maxWidth: "100px",
                          overflowX: "auto",
                        }}
                      >
                        {action.assigned_to.map((user) => {
                          return <AssignedToCell user={user} />;
                        })}
                      </div>
                    </TableCell>

                    <TableCell align="left">
                      {urlIncludesArchived &&
                        user.permissions.canRestoreTickets && (
                          <Tooltip title="Restore" placement="right">
                            <Button
                              onClick={() => {
                                axios
                                  .put(
                                    `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${action._id}`,
                                    {
                                      archived: false,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${user.token}`,
                                      },
                                    }
                                  )
                                  .catch((error) => {
                                    console.error(
                                      "Failed to restore action: ",
                                      error
                                    );
                                  });
                              }}
                            >
                              <Restore sx={{ color: "var(--primary-color)" }} />
                            </Button>
                          </Tooltip>
                        )}

                      {!urlIncludesArchived &&
                        user.permissions.canArchiveActions && (
                          <Tooltip title="Αρχειοθέτηση" placement="right">
                            <Button
                              onClick={() => {
                                axios
                                  .put(
                                    `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${action._id}`,
                                    {
                                      archived: true,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${user.token}`,
                                      },
                                    }
                                  )

                                  .catch((error) => {
                                    console.error(
                                      "Failed to archive action: ",
                                      error
                                    );
                                  });
                              }}
                            >
                              <Archive sx={{ color: "var(--primary-color)" }} />
                            </Button>
                          </Tooltip>
                        )}

                      {user.permissions.canDeleteTicket && (
                        <Tooltip title="Διαγραφή" placement="right">
                          <Button
                            onClick={() => {
                              setOpenAction(action);
                              setDeleteActionOpen(true);
                            }}
                          >
                            <Delete sx={{ color: "var(--primary-color)" }} />
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!props.mini && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              m={2}
              sx={{ width: "95%" }}
            >
              <Typography>
                Showing{" "}
                {Math.min((currentPage - 1) * actionsPerPage + 1, totalActions)}
                -{Math.min(currentPage * actionsPerPage, totalActions)} of{" "}
                {totalActions} items
              </Typography>
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={(event, page) => {
                  setCurrentPage(page);
                }}
                color="primary"
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ActionsTable;
