import { useState, useCallback } from 'react';
import axios from 'axios';
import { 
  formatDateData, 
  prepareDragData, 
  resolveReservationConflicts 
} from '../components/Calendar/CalendarHelpers';

/**
 * Custom hook for handling drag and drop of actions in calendar
 * @param {Object} options Optional configuration
 * @returns {Object} Drag and drop handlers and state
 */
export function useDragDrop({ onSuccess, onError, onDragStart: customDragStart } = {}) {
  const [draggingAction, setDraggingAction] = useState(null);
  const [targetInfo, setTargetInfo] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // Prepare drag data and handle dragStart event
  const handleDragStart = useCallback((event, date, propertyId, action, isPending) => {
    // Store dragging action in state
    setDraggingAction(action);
    
    // Ensure we preserve all action properties including color
    const dragData = {
      sourceHotelId: propertyId,
      date: date.toISOString(),
      action: action ? {
        ...action,
        // Convert dates to ISO strings for JSON serialization
        date: action.date ? new Date(action.date).toISOString() : new Date().toISOString(),
        planned_start_date: action.planned_start_date ? 
          new Date(action.planned_start_date).toISOString() : new Date().toISOString()
      } : null
    };

    // Set drag data on the event
    event.dataTransfer.setData('application/json', JSON.stringify(dragData));
    
    // Add CSS classes for visual feedback
    event.currentTarget.classList.add('dragging');
    if (isPending) {
      event.currentTarget.classList.add('dragging-pending');
    }
    
    // Call custom handler if provided
    if (customDragStart) {
      customDragStart(event, date, propertyId, action);
    }
  }, [customDragStart]);
  
  // Handle dragOver event
  const handleDragOver = useCallback((event) => {
    event.preventDefault(); // Allow drop
    event.currentTarget.classList.add('drag-over');
    
    // Get target cell info for visual feedback
    const targetElement = event.currentTarget;
    const hasConflicts = targetElement.classList.contains('colliding');
    
    setTargetInfo({
      element: targetElement,
      hasConflicts
    });
  }, []);
  
  // Handle dragLeave event
  const handleDragLeave = useCallback((event) => {
    event.currentTarget.classList.remove('drag-over');
    setTargetInfo(null);
  }, []);
  
  // Handle drop event
  const handleDrop = useCallback(async (event, targetDate, targetPropertyId) => {
    event.preventDefault();
    
    try {
      // Clean up drag over classes
      const draggingElements = document.querySelectorAll('.dragging');
      draggingElements.forEach(el => {
        el.classList.remove('dragging');
        el.classList.remove('dragging-pending');
      });
      
      event.currentTarget.classList.remove('drag-over');
      
      // Get the transfer data
      const dragData = JSON.parse(event.dataTransfer.getData('application/json'));
      if (!dragData || !dragData.action) return;
      
      const sourcePropertyId = dragData.sourceHotelId;
      const oldDate = new Date(dragData.date);
      const action = dragData.action;
      
      // Convert date strings back to Date objects if needed
      if (action) {
        if (action.date && typeof action.date === 'string') {
          action.date = new Date(action.date);
        }
        if (action.planned_start_date && typeof action.planned_start_date === 'string') {
          action.planned_start_date = new Date(action.planned_start_date);
        }
      }
      
      // Start the update
      setIsUpdating(true);
      
      // Get user token
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.token) {
        throw new Error('Authentication required');
      }
      
      // Format date for API
      const formattedNewDate = formatDateData(targetDate);
      
      // Create update data
      const updateData = {
        planned_start_date: formattedNewDate,
      };
      
      // Add property if different from source
      if (sourcePropertyId !== targetPropertyId) {
        updateData.property = targetPropertyId;
      }
      
      // Make the API call
      const response = await axios.put(
        `https://maliarakis-server.rhodesislandpass.com/action/updateAction/${action._id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      // Handle success
      if (onSuccess) {
        onSuccess({
          sourcePropertyId,
          targetPropertyId,
          oldDate,
          newDate: targetDate,
          action,
          response: response.data
        });
      }
      
      setDraggingAction(null);
      setTargetInfo(null);
      
    } catch (error) {
      console.error('Error in handleDrop:', error);
      
      if (onError) {
        onError(error);
      }
    } finally {
      setIsUpdating(false);
    }
  }, [onSuccess, onError]);
  
  // Handle reservation conflicts
  const handleReservationConflict = useCallback((sourceReservations, targetReservations, option) => {
    return resolveReservationConflicts(sourceReservations, targetReservations, option);
  }, []);

  return {
    draggingAction,
    targetInfo,
    isUpdating,
    handleDragStart,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleReservationConflict
  };
}
