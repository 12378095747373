import React from 'react';
import { Box, Typography, Paper, Breadcrumbs, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Home, CalendarMonth } from '@mui/icons-material';
import { GroupDailyActions } from '../../components';
import './GroupDailyActionsPage.css';
import PageStructure from '../page-structure/PageStructure';

const GroupDailyActionsPage = () => {
  return (
    <PageStructure>
    <div className="group-daily-actions-page">
      {/* <Paper className="page-header" elevation={1}>
        <Typography variant="h5" component="h1">
          Group Daily Actions
        </Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            component={RouterLink}
            to="/dashboard"
            color="inherit"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Home sx={{ mr: 0.5 }} fontSize="inherit" />
            Dashboard
          </Link>
          <Link
            component={RouterLink}
            to="/group-daily-actions"
            color="inherit"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <CalendarMonth sx={{ mr: 0.5 }} fontSize="inherit" />
            Group Daily Actions
          </Link>
        </Breadcrumbs>
      </Paper> */}
      
      <Box mt={3}>
        <GroupDailyActions />
      </Box>
    </div>
    </PageStructure>
  );
};

export default GroupDailyActionsPage;
