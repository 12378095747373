import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
  Inbox,
  CalendarMonth,
  Feed,
  Dashboard,
  Receipt,
  Group,
  MapsHomeWork,
  Person,
  CleaningServices,
  Euro,
  StackedLineChart,
  ListAlt,
  Hail,
  DateRange,
} from "@mui/icons-material";
import Collapse from "@material-ui/core/Collapse";
import logo from "../../images/logo-white.svg";
import { useNavigate } from "react-router-dom";
import "./Sidebar.css";

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "0.5s",
    }),
  },
  drawerPaper: {
    backgroundColor: "var(--primary-color)", // Ensure this CSS variable is defined
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "0.5s",
    }),
  },
  drawerPaperExpanded: {
    width: "250px", // Set your expanded width
  },
  drawerPaperCollapsed: {
    width: "90px", // Set your collapsed width
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    backgroundColor: "var(--tertiary-color)", // Ensure this CSS variable is defined
    ...theme.mixins.toolbar,
  },

  toggleButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 100,
  },
  ListItemButton: {
    color: "var(--tertiary-color)", // Ensure this CSS variable is defined
    backgroundColor: "var(--primary-color)", // Ensure this CSS variable is defined
  },
  ListItemButtonActive: {
    color: "white", // Ensure this CSS variable is defined
    backgroundColor: "var(--secondary-color)", // Ensure this CSS variable is defined
  },
  ListItemIcon: {
    color: "var(--tertiary-color)", // Ensure this CSS variable is defined
  },
  ListItemIconActive: {
    color: "white", // Ensure this CSS variable is defined
  },
}));
const Sidebar = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem("isCollapsed") === "true"
  );
  const [cleaningsSubMenuOpen, setCleaningsSubMenuOpen] = useState(false); // State to manage the tickets submenu visibility
  const [ticketsSubMenuOpen, setTicketsSubMenuOpen] = useState(false); // State to manage the tickets submenu visibility
  const [checklistsSubMenuOpen, setChecklistsSubMenuOpen] = useState(false); // State to manage the tickets submenu visibility
  const [ownerSubMenuOpen, setOwnerSubMenuOpen] = useState(false); // State to manage the tickets submenu visibility
  const [propertiesSubMenuOpen, setPropertiesSubMenuOpen] = useState(false); // New state for Properties submenu
  const classes = useStyles({ isCollapsed });
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 768) {
      props.setWidth(0);
    } else {
      props.setWidth(isCollapsed ? "90px" : "250px");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    // Set initial sidebar width based on initial window size
    handleWindowSizeChange();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [isCollapsed]);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
    localStorage.setItem("isCollapsed", !isCollapsed);
    props.setWidth(isCollapsed ? "250px" : "90px");
  };

  const toggleTicketsSubMenu = () => {
    setTicketsSubMenuOpen(!ticketsSubMenuOpen);
  };

  const toggleCleaningsSubMenu = () => {
    // setTicketsSubMenuOpen(!ticketsSubMenuOpen);

    setCleaningsSubMenuOpen(!cleaningsSubMenuOpen);
  };

  const toggleOwnerSubMenu = () => {
    setOwnerSubMenuOpen(!ownerSubMenuOpen);
  };

  const togglePropertiesSubMenu = () => {
    setPropertiesSubMenuOpen(!propertiesSubMenuOpen);
  };

  let menuItems = [
    {
      text: "Ταμπλό",
      href: "/dashboard",
      icon: <Dashboard />,
      onClick: () => navigate("/dashboard"),
    },

    {
      text: "Ημερολόγιο",
      href: "/calendar",
      icon: <CalendarMonth />,
      onClick: () => navigate("/calendar"),
    },
    {
      text: "Δραστηριότητες",
      href: "/actions",
      icon: <CleaningServices />,
      onClick: () => {
        if (isCollapsed && !cleaningsSubMenuOpen) {
          toggleCleaningsSubMenu();
          handleToggle();
        } else if (isCollapsed) {
          handleToggle();
        } else {
          toggleCleaningsSubMenu();
        }
      },
      submenu: [
        {
          text: "Όλες οι Δραστηριότητες",
          href: "/actions/all",
          onClick: () => navigate("/actions/all"),
        },
        {
          text: "Ημερήσιες Δραστηριότητες",
          href: "/group-daily-actions",
          onClick: () => navigate("/group-daily-actions"),
        },
        {
          text: "Εκκρεμείς",
          href: "/actions/pending",
          onClick: () => {
            navigate("/actions/pending");
          },
        },
        {
          text: "Ολοκληρωμένες",
          href: "/actions/completed",
          onClick: () => {
            navigate("/actions/completed");
          },
        },
        {
          text: "Ρυθμίσεις",
          href: "/actions/settings",
          onClick: () => navigate("/actions/settings"),
        },
      ],
    },
    {
      text: "Tickets",
      href: "/tickets",
      icon: <Feed />,
      onClick: () => {
        if (isCollapsed && !ticketsSubMenuOpen) {
          toggleTicketsSubMenu();
          handleToggle();
        } else if (isCollapsed) {
          handleToggle();
        } else {
          toggleTicketsSubMenu();
        }
      },
      submenu: [
        {
          text: "Όλα τα Tickets",
          href: "/tickets/all",
          onClick: () => navigate("/tickets/all"),
        },
        {
          text: "Ανατεθημένα σε μένα",
          href: "/tickets/assigned-to-me",
          onClick: () => navigate("/tickets/assigned-to-me"),
        },
        {
          text: "Δημιουργημένα από εμένα",
          href: "/tickets/created-by-me",
          onClick: () => navigate("/tickets/created-by-me"),
        },
        // {
        //   text: "On Progress",
        //   href: "/tickets/on-progress",
        //   onClick: () => navigate("/tickets/on-progress"),

        // },
        // {
        //   text: "Open Tickets",
        //   href: "/tickets/open",
        //   onClick: () => navigate("/tickets/open"),
        // },
        // {
        //   text: "Completed Tickets",
        //   href: "/tickets/closed",
        //   onClick: () => navigate("/tickets/completed"),
        // },

        {
          text: "Αρχειοθετημένα Tickets",
          href: "/tickets/archived",
          onClick: () => navigate("/tickets/archived"),
        },
        // {
        //   text: "Open Tickets",
        //   href: "/tickets/open",
        //   onClick: () => navigate("/tickets/open"),
        // },
        // {
        //   text: "Closed Tickets",
        //   href: "/tickets/closed",
        //   onClick: () => navigate("/tickets/done"),
        // },

        {
          text: "Ρυθμίσεις",
          href: "/tickets/settings",
          onClick: () => navigate("/tickets/settings"),
        },
      ],
    },
    {
      text: "Συζητήσεις",
      href: "/inbox",
      icon: <Inbox />,
      onClick: () => navigate("/inbox"),
    },
    // {
    //   text: "Calendar",
    //   href: "/calendar",
    //   icon: <CalendarMonth />,
    //   onClick: () => {},
    // },
    {
      text: "Κρατήσεις",
      href: "/reservations",
      icon: <Receipt />,
      onClick: () => navigate("/reservations"),
    },
    {
      text: "Καταλύματα",
      href: "/properties",
      icon: <MapsHomeWork />,
      onClick: () => {
        if (isCollapsed && !propertiesSubMenuOpen) {
          togglePropertiesSubMenu();
          handleToggle();
        } else if (isCollapsed) {
          handleToggle();
        } else {
          togglePropertiesSubMenu();
        }
      },
      submenu: [
        {
          text: "Όλα τα Καταλύματα",
          href: "/properties",
          onClick: () => navigate("/properties"),
        },
        {
          text: "Τοποθεσίες",
          href: "/properties/locations",
          onClick: () => navigate("/properties/locations"),
        }
      ],
    },
    {
      text: "Λίστες Ελέγχου",
      href: "/checklists",
      icon: <ListAlt />,
      onClick: () => {
        if (isCollapsed && !checklistsSubMenuOpen) {
          setChecklistsSubMenuOpen(!checklistsSubMenuOpen);
          handleToggle();
        } else if (isCollapsed) {
          handleToggle();
        } else {
          setChecklistsSubMenuOpen(!checklistsSubMenuOpen);
        }
      },
      submenu: [
        {
          text: "Όλες οι Λίστες",
          href: "/checklists/all",
          onClick: () => navigate("/checklists/all"),
        },
        {
          text: "Ρυθμίσεις",
          href: "/checklists/settings",
          onClick: () => navigate("/checklists/settings"),
        },
      ],
    },
    {
      text: "Ομάδες",
      href: "/groups",
      icon: <Group />,
      onClick: () => {
        navigate("/groups");
      },
    },
    // {
    //   text: "Στατιστικά",
    //   href: "/statistics",
    //   icon: <StackedLineChart />,
    //   onClick: () => {
    //     // navigate("/statistics");
    //   },
    // },

    // {
    //   text: "Μισθοδοσία",
    //   href: "/salaries",
    //   icon: <Euro />,
    //   onClick: () => {
    //     // navigate("/salaries");
    //   },
    // },
    {
      text: "Χρήστες",
      href: "/users",
      icon: <Person />,
      onClick: () => {
        navigate("/users");
      },
    },
    {
      text: "Ιδιοκτήτες",
      href: "/owners",
      icon: <Hail />,
      onClick: () => {
        if (isCollapsed && !ownerSubMenuOpen && !ticketsSubMenuOpen) {
          toggleOwnerSubMenu(true);
          handleToggle();
        } else if (isCollapsed) {
          handleToggle();
        } else {
          toggleOwnerSubMenu(!ownerSubMenuOpen);
        }
      },
      submenu: [
        {
          text: "Προσθήκη Ιδιοκτήτη",
          href: "/owner-add",
          onClick: () => navigate("/owner-add"),
        },
        {
          text: "Επεξεργασία Ιδιοκτήτη",
          href: "/owner-edit",
          onClick: () => navigate("/owner-edit"),
        },
      ],
    },
  ];

  if (!user.permissions.canViewAllTickets) {
    menuItems.find((item) => item.text === "Tickets").submenu = menuItems
      .find((item) => item.text === "Tickets")
      .submenu.filter((subItem) => subItem.text !== "Όλα τα Tickets");
  }

  if (!user.permissions.canEditTicketSettings) {
    menuItems.find((item) => item.text === "Tickets").submenu = menuItems
      .find((item) => item.text === "Tickets")
      .submenu.filter((subItem) => subItem.text !== "Ρυθμίσεις");
  }
  if (!user.permissions.canCreateTicket) {
    menuItems.find((item) => item.text === "Tickets").submenu = menuItems
      .find((item) => item.text === "Tickets")
      .submenu.filter((subItem) => subItem.text !== "Δημιουργημένα από εμένα");
  }

  if (!user.permissions.canViewOpenTickets) {
    menuItems.find((item) => item.text === "Tickets").submenu = menuItems
      .find((item) => item.text === "Tickets")
      .submenu.filter((subItem) => subItem.text !== "Open Tickets");
  }

  if (!user.permissions.canViewCompletedTickets) {
    menuItems.find((item) => item.text === "Tickets").submenu = menuItems
      .find((item) => item.text === "Tickets")
      .submenu.filter((subItem) => subItem.text !== "Completed Tickets");
  }

  if (!user.permissions.canAddAction) {
    menuItems = menuItems.filter((item) => item.text !== "Δραστηριότητες");
    menuItems = menuItems.filter((item) => item.text !== "Ημερολόγιο");
  }

  if (!user.permissions.canViewChecklist) {
    menuItems = menuItems.filter((item) => item.text !== "Λίστες Ελέγχου");
  }

  if (!user.permissions.canAddAction) {
    menuItems = menuItems.filter((item) => item.text !== "Ημερολόγιο");
  }

  if (!user.permissions.canCreateGroup) {
    menuItems = menuItems.filter((item) => item.text !== "Ομάδες");
  }

  if (!user.permissions.canChangePermissions) {
    menuItems = menuItems.filter((item) => item.text !== "Χρήστες");
  }

  if (!user.permissions.canViewProperty) {
    menuItems = menuItems.filter((item) => item.text !== "Καταλύματα");
  }
  if (!user.permissions.canViewReservation) {
    menuItems = menuItems.filter((item) => item.text !== "Κρατήσεις");
  }

  return (
    <div className="sidebar">
      <Drawer
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: `${classes.drawerPaper} ${
            isCollapsed
              ? classes.drawerPaperCollapsed
              : classes.drawerPaperExpanded
          }`,
        }}
      >
        <div className={classes.toolbar}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: isCollapsed ? "0px" : "200px",
              height: isCollapsed ? "60px" : "100px",
              transition: "all 0.5s",
            }}
          />
          <IconButton onClick={handleToggle} className={classes.toggleButton}>
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <List>
          {menuItems.map((item, index) => (
            <div key={index}>
              <Tooltip
                key={item.text}
                title={item.text}
                placement="right"
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      fontSize: "14px",
                      backgroundColor: "var(--tertiary-color)",
                    },
                  },
                }}
              >
                <ListItem
                  button
                  key={item.text}
                  onClick={item.onClick}
                  className={
                    item.href === window.location.pathname ||
                    item.submenu?.some(
                      (subItem) => subItem.href === window.location.pathname
                    )
                      ? classes.ListItemButtonActive
                      : classes.ListItemButton
                  }
                  style={{
                    paddingLeft: isCollapsed ? "25px" : "10px",
                    transition: "all 0.5s",
                  }}
                >
                  <ListItemIcon
                    className={
                      item.href === window.location.pathname ||
                      item.submenu?.some(
                        (subItem) => subItem.href === window.location.pathname
                      )
                        ? classes.ListItemIconActive
                        : classes.ListItemIcon
                    }
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    style={{
                      visibility: isCollapsed ? "hidden" : "visible",
                      opacity: isCollapsed ? "0" : "1",
                      transition: "all 0.5s",
                    }}
                  />
                  {item.submenu &&
                    !isCollapsed &&
                    (item.text === "Tickets" ? (
                      ticketsSubMenuOpen ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : item.text === "Δραστηριότητες" ? (
                      cleaningsSubMenuOpen ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : item.text === "Λίστες Ελέγχου" ? (
                      checklistsSubMenuOpen ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : item.text === "Ιδιοκτήτες" ? (
                      ownerSubMenuOpen ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : item.text === "Καταλύματα" ? (
                      propertiesSubMenuOpen ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null)}
                </ListItem>
              </Tooltip>
              {item.submenu && !isCollapsed && (
                <Collapse
                  in={
                    item.text === "Tickets"
                      ? ticketsSubMenuOpen
                      : item.text === "Δραστηριότητες"
                      ? cleaningsSubMenuOpen
                      : item.text === "Λίστες Ελέγχου"
                      ? checklistsSubMenuOpen
                      : item.text === "Ιδιοκτήτες"
                      ? ownerSubMenuOpen
                      : item.text === "Καταλύματα"
                      ? propertiesSubMenuOpen
                      : false
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.submenu.map((subItem) => (
                      <Tooltip
                        title={subItem.text}
                        placement="right"
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "14px",
                              backgroundColor: "var(--tertiary-color)",
                            },
                          },
                        }}
                      >
                        <ListItem
                          button
                          key={subItem.text}
                          className={
                            subItem.href === window.location.pathname
                              ? classes.ListItemButtonActive
                              : classes.ListItemButton
                          }
                          onClick={subItem.onClick}
                        >
                          <ListItemText inset primary={subItem.text} />
                        </ListItem>
                      </Tooltip>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
