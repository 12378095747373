import React from "react";
import { PageStructure } from "../../pages";
import { AddLocationOption, UpdateLocationOption } from "../../components";
import "./LocationSettings.css";

const LocationSettings = () => {
  return (
    <PageStructure>
      <h1>Ρυθμίσεις Τοποθεσιών</h1>
      <hr />
      <AddLocationOption />
      <UpdateLocationOption />
    </PageStructure>
  );
};

export default LocationSettings;
